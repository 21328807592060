@font-face {
	font-weight: normal;
	font-style: normal;
	font-family: 'codropsicons';
	src:url('../fonts/codropsicons/codropsicons.eot');
	src:url('../fonts/codropsicons/codropsicons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/codropsicons/codropsicons.woff') format('woff'),
		url('../fonts/codropsicons/codropsicons.ttf') format('truetype'),
		url('../fonts/codropsicons/codropsicons.svg#codropsicons') format('svg');
}

*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { display: table; content: ''; }
.clearfix:after { clear: both; }






.content > h2 {
	clear: both;
	margin: 0;
	padding: 4em 1% 0;
	color: #484B54;
	font-weight: 800;
	font-size: 1.5em;
}

.content > h2:first-child {
	padding-top: 0em;
}

/* Header */
.codrops-header {
	margin: 0 auto;
	padding: 4em 1em;
	text-align: center;
}

.codrops-header h1 {
	margin: 0;
	font-weight: 800;
	font-size: 4em;
	line-height: 1.3;
}

.codrops-header h1 span {
	display: block;
	padding: 0 0 0.6em 0.1em;
	color: #74777b;
	font-weight: 300;
	font-size: 45%;
}

/* Demo links */
.codrops-demos {
	clear: both;
	padding: 1em 0 0;
	text-align: center;
}

.content + .codrops-demos {
	padding-top: 5em;
}

.codrops-demos a {
	display: inline-block;
	margin: 0 5px;
	padding: 1em 1.5em;
	text-transform: uppercase;
	font-weight: bold;
}

.codrops-demos a:hover,
.codrops-demos a:focus,
.codrops-demos a.current-demo {
	background: #3c414a;
	color: #fff;
}

/* To Navigation Style */
.codrops-top {
	width: 100%;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 0.69em;
	line-height: 2.2;
}

.codrops-top a {
	display: inline-block;
	padding: 1em 2em;
	text-decoration: none;
	letter-spacing: 1px;
}

.codrops-top span.right {
	float: right;
}

.codrops-top span.right a {
	display: block;
	float: left;
}

.codrops-icon:before {
	margin: 0 4px;
	text-transform: none;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	font-family: 'codropsicons';
	line-height: 1;
	speak: none;
	-webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
	content: "\e001";
}

.codrops-icon-prev:before {
	content: "\e004";
}

/* Related demos */
.related {
	clear: both;
	padding: 6em 1em;
	font-size: 120%;
}

.related > a {
	display: inline-block;
	margin: 20px 10px;
	padding: 25px;
	border: 1px solid #4f7f90;
	text-align: center;
}

.related a:hover {
	border-color: #39545e;
}

.related a img {
	max-width: 100%;
	opacity: 0.8;
}

.related a:hover img,
.related a:active img {
	opacity: 1;
}

.related a h3 {
	margin: 0;
	padding: 0.5em 0 0.3em;
	max-width: 300px;
	text-align: left;
}

@media screen and (max-width: 25em) {
	.codrops-header {
		font-size: 75%;
	}
	.codrops-icon span {
		display: none;
	}
}