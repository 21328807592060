.modal {
    &-header {
        background: #f7f7f7;

    }

    &-dialog {
        width: 600px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: fixed;
        display: block;
        height: fit-content;
    }

    &-body {
        max-height: 500px;
        overflow: auto;
    }

    &.full_modal {
        .modal-dialog {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

        }

        .modal-content {
            background-color: transparent;
            padding: 0;

            .modal-body {
                background: transparent;
                min-height: 200px;
                padding: 0;

            }
        }

    }
}