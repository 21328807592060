// - HTML, BODY
// - TYPOGRAPHY
// - ANCHORES AND LISTS
// - FONTS
// - ATRIBUTES SHORTCODES
//   > line heights
//   > letter spacing
//   > variables
//   > separators
//   > space
//   > spacing
//   > custom sizes
//   > top for relative positions
//   > height
//   > width
//   > colors
//   > bg colors
//   > bg images
//   > cover images
//   > breadcrumbs
//   > pagination
//   > shadow
//   > borders
//   > percentages
//   > margins
//   > paddings
// - LOGO
// - CUSTOM POSITIONS
// - LEFTMENU
// - RIGHTMENU
// - SIDEMENU LIST
// - FIX BUTTONS POSITION FOR MOBILES
// - FIX CAPTION POSITION FOR MOBILES
// - BUTTONS
// - NAVIGATION MENU 
//   > white menu
//   > dark menu
//   > dark-chealk menu
//   > dropdown white
//   > dropdown dark
//   > Shopping Basket White
//   > Shopping Basket Dark
// - NAVIGATION 2
// - SECTIONS - DIVS - CONTAINERS
// - SLIDER CAPTIONS
// - TEAM PARALLAX
// - TEAM MOUSE OVER ANIMATIONS
// - FACTS SECTION
// - FOOTER
// - FOOTER TYPE 2 WHITE
// - FOOTER TYPE 2 DARK
// - SOCIAL ICONS
// - ABOUT PAGE
// - COLLAPSE STYLE
// - COLLAPSE STYLE DARK
// - INPUTS
// - FULL WIDTH SEARCH
// - FAQ'S SEARCH
// - CAROUSEL BOOLETS
// - PRICELIST TABLE
// - TABS
// - THEMES PREVIEW
// - BACK TO TOP
// - PORTFOLIO GALLERY
// - SERVICES HOVER
// - ANIMATE BORDER CSS
// - THEME THUMBS
// - PORTFOLIO PAGE
// - SHOP PAGES
// - BLOG PAGES
// - MAINTENANCE MODE
// - SEARCH PAGE

/*BODY*/
html {
  height: 100%;
}

body {
  //overflow:hidden;
  overflow-y: scroll;
  // overflow-x: none;
  font-family: $mainfont, sans-serif, Georgia, Tahoma;
  font-size: $fontsize;
  color: $fontcolor;
  width: 100%;
  height: 100%;
}

/*TYPOGRAPHY*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/*ANCHORES and LISTS*/
a {
  color: $linkcolor;
  @include transition(.2s);
}

a:hover {
  color: $linkhovercolor;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.list {
  margin: 0px;
  padding: 0px;

  li {
    list-style-type: none;
    width: 100%;
    float: left;
    padding: 10px;
    font-size: 13px;
    border-bottom: 1px solid #efefef;
    color: #666;
  }

  li:before {
    font-family: 'fontello';
    content: "\e8b3";
    margin-right: 10px;
  }
}

ul.doublelist {
  margin: 0px;
  padding: 0px;

  li {
    list-style-type: none;
    width: 100%;
    float: left;
    padding: 10px;
    border-bottom: 1px solid #efefef;
    color: #666;
  }

  @media (min-width: 992px) {
    li {
      width: 50%;
    }
  }

  li:before {
    font-family: 'fontello';
    content: "\e8b3";
    margin-right: 10px;
  }
}

ul.fqlist {
  margin: 0px;
  padding: 0px;

  li {
    list-style-type: none;
    width: 100%;
    float: left;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 0px solid #efefef;
    color: #666;

    a {
      color: $maincolor;
      @include transition(.2s);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  li:before {
    font-family: 'fontello';
    content: "\e8b3";
    margin-right: 10px;
    font-weight: normal;
  }
}

ul.fqlist.archive {
  li {
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
    font-family: proxima_nova_rgregular;
  }
}

/*FONTS*/
@font-face {
  font-family: 'proxima_novaextrabold';
  src: url('../fonts/proximanova-extrabold-webfont.eot');
  src: url('../fonts/proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-extrabold-webfont.woff') format('woff'),
    url('../fonts/proximanova-extrabold-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novalight';
  src: url('../fonts/proximanova-light-webfont.eot');
  src: url('../fonts/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-light-webfont.woff') format('woff'),
    url('../fonts/proximanova-light-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-light-webfont.svg#proxima_novalight') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_nova_rgbold';
  src: url('../fonts/proximanova-bold-webfont.eot');
  src: url('../fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-bold-webfont.woff') format('woff'),
    url('../fonts/proximanova-bold-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_rgregular';
  src: url('../fonts/proximanova-regular-webfont.eot');
  src: url('../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-regular-webfont.woff') format('woff'),
    url('../fonts/proximanova-regular-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

.fontproximaextrabold {
  font-family: 'proxima_novaextrabold';
}

.fontproximabold {
  font-family: 'proxima_nova_rgbold';
}

.fontproxima {
  font-family: 'proxima_nova_rgregular';
}

.fontproximalight {
  font-family: 'proxima_novalight';
}

.raleway {
  font-family: Raleway;
}

.opensans {
  font-family: 'Open Sans';
}

.georgia {
  font-family: 'Georgia';
}

.titlefont {
  font-family: $titlefont;
}

/*PRELOADER*/
#preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
  position: fixed;
}

#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../images/preloaders/square_4.gif);
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
}


/*FONT SIZE*/
.size5 {
  font-size: 5px;
}

.size6 {
  font-size: 6px;
}

.size7 {
  font-size: 7px;
}

.size8 {
  font-size: 8px;
}

.size9 {
  font-size: 9px;
}

.size10 {
  font-size: 10px;
}

.size11 {
  font-size: 11px;
}

.size12 {
  font-size: 12px;
}

.size13 {
  font-size: 13px;
}

.size14 {
  font-size: 14px;
}

.size16 {
  font-size: 16px;
}

.size18 {
  font-size: 18px;
}

.size20 {
  font-size: 20px;
}

.size22 {
  font-size: 22px;
}

.size24 {
  font-size: 24px;
}

.size26 {
  font-size: 26px;
}

.size28 {
  font-size: 28px;
}

.size30 {
  font-size: 30px;
}

.size32 {
  font-size: 32px;
}

.size34 {
  font-size: 34px;
}

.size36 {
  font-size: 36px;
}

.size38 {
  font-size: 38px;
}

.size40 {
  font-size: 40px;
}

.size42 {
  font-size: 42px;
}

.size44 {
  font-size: 44px;
}

.size46 {
  font-size: 46px;
}

.size48 {
  font-size: 48px;
}

.size50 {
  font-size: 50px;
}

.size55 {
  font-size: 55px;
}

.size56 {
  font-size: 56px;
}

.size60 {
  font-size: 60px;
}

.size70 {
  font-size: 70px;
}

.size80 {
  font-size: 80px;
}

.size90 {
  font-size: 90px;
}

.size100 {
  font-size: 100px;
}

.size120 {
  font-size: 120px;
}

.size130 {
  font-size: 130px;
}

.size140 {
  font-size: 140px;
}

.size150 {
  font-size: 150px;
}

/*Atributes shortcodes*/
.bold {
  font-weight: bold;
}

.xslim {
  font-weight: 100;
}

.slim {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.big {
  font-weight: 700;
}

.heavy {
  font-weight: 800;
}

.caps {
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.center {
  margin: 0 auto;
  display: block;
}

.center-absolute {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.highlight {
  background: $maincolor;
  color: #fff;
}

.valign {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
}

.valign2 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.valign2-remove {
  top: auto;
  transform: none;

  @media (min-width: $screen-md-min) {
    top: 50%;
    transform: translateY(-50%);
  }
}

/*Line heights*/
.lh10 {
  line-height: 10px !important;
}

.lh11 {
  line-height: 11px !important;
}

.lh12 {
  line-height: 12px !important;
}

.lh13 {
  line-height: 13px !important;
}

.lh14 {
  line-height: 14px !important;
}

.lh15 {
  line-height: 15px !important;
}

.lh20 {
  line-height: 20px !important;
}

.lh21 {
  line-height: 21px !important;
}

.lh22 {
  line-height: 22px !important;
}

.lh23 {
  line-height: 23px !important;
}

.lh24 {
  line-height: 24px !important;
}

.lh26 {
  line-height: 26px !important;
}

.lh27 {
  line-height: 27px !important;
}

.lh28 {
  line-height: 28px !important;
}

.lh29 {
  line-height: 29px !important;
}

.lh30 {
  line-height: 30px !important;
}

.lh32 {
  line-height: 32px !important;
}

.lh34 {
  line-height: 34px !important;
}

.lh36 {
  line-height: 36px !important;
}

.lh38 {
  line-height: 38px !important;
}

.lh40 {
  line-height: 40px !important;
}

.lh50 {
  line-height: 50px !important;
}

.lh60 {
  line-height: 60px !important;
}

.lh70 {
  line-height: 70px !important;
}

.lh80 {
  line-height: 80px !important;
}

.lh90 {
  line-height: 90px !important;
}

.lh100 {
  line-height: 100px !important;
}

.lh150 {
  line-height: 150px !important;
}

// LETTER SPACING
.ls1 {
  letter-spacing: 1px;
}

.ls2 {
  letter-spacing: 2px;
}

.ls3 {
  letter-spacing: 3px;
}

.ls4 {
  letter-spacing: 4px;
}

.ls5 {
  letter-spacing: 5px;
}

.ls6 {
  letter-spacing: 6px;
}

.ls7 {
  letter-spacing: 7px;
}

.ls8 {
  letter-spacing: 8px;
}

.ls9 {
  letter-spacing: 9px;
}

.ls10 {
  letter-spacing: 10px;
}

.ls11 {
  letter-spacing: 11px;
}

.ls12 {
  letter-spacing: 12px;
}

.ls13 {
  letter-spacing: 13px;
}

.ls14 {
  letter-spacing: 14px;
}

.ls15 {
  letter-spacing: 15px;
}

.ls16 {
  letter-spacing: 16px;
}

.ls17 {
  letter-spacing: 17px;
}

.ls18 {
  letter-spacing: 18px;
}

.ls19 {
  letter-spacing: 19px;
}

.ls20 {
  letter-spacing: 20px;
}

.ls30 {
  letter-spacing: 30px;
}

.ls40 {
  letter-spacing: 40px;
}

.ls50 {
  letter-spacing: 50px;
}

// LETTER SPACING negative
.ls-1 {
  letter-spacing: -1px;
}

.ls-2 {
  letter-spacing: -2px;
}

.ls-3 {
  letter-spacing: -3px;
}

.ls-4 {
  letter-spacing: -4px;
}

.ls-5 {
  letter-spacing: -5px;
}

.ls-6 {
  letter-spacing: -6px;
}

.ls-7 {
  letter-spacing: -7px;
}

.ls-8 {
  letter-spacing: -8px;
}

.ls-9 {
  letter-spacing: -9px;
}

.ls-10 {
  letter-spacing: -10px;
}

.ls-11 {
  letter-spacing: -11px;
}

.ls-12 {
  letter-spacing: -12px;
}

.ls-13 {
  letter-spacing: -13px;
}

.ls-14 {
  letter-spacing: -14px;
}

.ls-15 {
  letter-spacing: -15px;
}

.ls-16 {
  letter-spacing: -16px;
}

.ls-17 {
  letter-spacing: -17px;
}

.ls-18 {
  letter-spacing: -18px;
}

.ls-19 {
  letter-spacing: -19px;
}

.ls-20 {
  letter-spacing: -20px;
}

.ls-30 {
  letter-spacing: -30px;
}

.ls-40 {
  letter-spacing: -40px;
}

.ls-50 {
  letter-spacing: -50px;
}

/*VARIABLES*/
.block {
  display: block !important;
}

.none {
  display: none !important;
}

.flex {
  display: flex !important;
}

.disable {
  display: none !important;
}

.disabled {
  display: none !important;
}

.notvisible {
  opacity: 0 !important;
}

.inline {
  display: inline-block;
}

.clearafter {
  content: '';
  display: block;
  clear: both;
}

.clearbefore {
  content: '';
  display: block;
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.owhidden {
  overflow: hidden !important;
}

.owxhidden {
  overflow-x: hidden;
}

.owyhidden {
  overflow-y: hidden;
}

.owxscroll {
  overflow-x: scroll;
}

.owyscroll {
  overflow-y: scroll;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.static {
  position: static !important;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.offset-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.nospace {
  padding: 0px !important;
  margin: 0px !important;
}

.nolrspace {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.notbspace {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.z10 {
  z-index: 10;
}

.z15 {
  z-index: 15;
}

.z20 {
  z-index: 20;
}

.z100 {
  z-index: 100 !important;
}

.z800 {
  z-index: 800 !important;
}

.z900 {
  z-index: 900 !important;
}

.z1000 {
  z-index: 1000 !important;
}

.z10000 {
  z-index: 10000 !important;
}


.opacity1 {
  opacity: .1 !important;
}

.opacity2 {
  opacity: .2 !important;
}

.opacity3 {
  opacity: .3 !important;
}

.opacity4 {
  opacity: .4 !important;
}

.opacity5 {
  opacity: .5 !important;
}

.opacity5 {
  opacity: .5 !important;
}

.opacity6 {
  opacity: .6 !important;
}

.opacity7 {
  opacity: .7 !important;
}

.opacity8 {
  opacity: .8 !important;
}

.opacity9 {
  opacity: .9 !important;
}


/* FULL WIDTH IMAGE */
img.fwi {
  width: 100%;
}

img.fhi {
  height: 100%;
}

img.fwimage.width {
  width: 100%;
}

img.fwimage.height {
  height: 100%;
}


.transparent {
  background: transparent !important;
}

.nobg {
  background: transparent;
}

.flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.rotate90 {
  display: block;
  @include rotate(90deg);
}

/* SEPARATORS */
.separator33 {
  width: 33%;
  height: 1px;
  background: #e2e2e2;
  margin: 50px auto;
}

.separator33.dark {
  width: 33%;
  height: 1px;
  background: #666;
  margin: 50px auto;
}

.separator33.dark2 {
  width: 33%;
  height: 1px;
  background: #333;
  margin: 50px auto;
}

.separator100 {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
}

.separator100dark {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.05);
}

.separator100.dark {
  width: 100%;
  height: 1px;
  background: #333;
}

.separatorsmall {
  width: 25px;
  height: 3px;
  background: #fff;
  margin: 0 auto;
}

.separatorsmallcealk {
  width: 25px;
  height: 3px;
  background: #c1bf94;
  margin: 0 auto;
}

.separator-10 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 10px auto;
}

.separator-20 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 20px auto;
}

.separator-30 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 30px auto;
}

.separator-40 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 40px auto;
}

.separator-50 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 50px auto;
}

.separator-60 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 60px auto;
}

.separator-70 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 70px auto;
}

.separator-80 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 80px auto;
}

.separator-90 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 90px auto;
}

.separator-100 {
  width: 100%;
  height: 1px;
  background: #f5f5f5;
  margin: 100px auto;
}

.separator-right {
  border-right: 1px solid #efefef;
}

.separator-left {
  border-left: 1px solid #efefef;
}

.divider {
  width: 100%;
  height: 1px;
  background: #efefef;
}

.divider.dark {
  width: 100%;
  height: 1px;
  background: #222;
}

/*SPACE*/
.space10 {
  display: block;
  margin: 0;
  padding: 0;
  height: 10px;
}

.space15 {
  display: block;
  margin: 0;
  padding: 0;
  height: 15px;
}

.space20 {
  display: block;
  margin: 0;
  padding: 0;
  height: 20px;
}

.space30 {
  display: block;
  margin: 0;
  padding: 0;
  height: 30px;
}

.space40 {
  display: block;
  margin: 0;
  padding: 0;
  height: 40px;
}

.space50 {
  display: block;
  margin: 0;
  padding: 0;
  height: 50px;
}

.space60 {
  display: block;
  margin: 0;
  padding: 0;
  height: 60px;
}

.space70 {
  display: block;
  margin: 0;
  padding: 0;
  height: 70px;
}

.space80 {
  display: block;
  margin: 0;
  padding: 0;
  height: 80px;
}

.space90 {
  display: block;
  margin: 0;
  padding: 0;
  height: 90px;
}

.space100 {
  display: block;
  margin: 0;
  padding: 0;
  height: 100px;
}

.space110 {
  display: block;
  margin: 0;
  padding: 0;
  height: 110px;
}

.space120 {
  display: block;
  margin: 0;
  padding: 0;
  height: 120px;
}

.space130 {
  display: block;
  margin: 0;
  padding: 0;
  height: 130px;
}

.space140 {
  display: block;
  margin: 0;
  padding: 0;
  height: 140px;
}

.space150 {
  display: block;
  margin: 0;
  padding: 0;
  height: 150px;
}

.space160 {
  display: block;
  margin: 0;
  padding: 0;
  height: 160px;
}

.space170 {
  display: block;
  margin: 0;
  padding: 0;
  height: 170px;
}

.space180 {
  display: block;
  margin: 0;
  padding: 0;
  height: 180px;
}

.space190 {
  display: block;
  margin: 0;
  padding: 0;
  height: 190px;
}

.space200 {
  display: block;
  margin: 0;
  padding: 0;
  height: 200px;
}

/*SPACING*/
.sspacing {
  padding: $sectionspacing 0px;
}

.sspacing-top {
  padding-top: $sectionspacing;
}

.sspacing-title {
  padding: ($sectionspacing - 0px) 0px $sectionspacing 0px;
}

.sspacing-title-button {
  padding: ($sectionspacing - 0px) 0px ($sectionspacing - 0px) 0px;
}


/*CUSTOM SIZES*/
.hcustom1 {
  height: 500px;
}

@media (min-width: $screen-md-min) {
  .hcustom1 {
    height: 100%;
  }
}

.chfull {
  height: auto;
}

@media (min-width: $screen-md-min) {
  .chfull {
    height: 100%;
  }
}

.ch500 {
  height: 2000px;
}

@media (min-width: $screen-sm-min) {
  .ch500 {
    height: 1000px
  }
}

@media (min-width: $screen-lg-min) {
  .ch500 {
    height: 500px
  }
}

.ch2 {
  height: 1000px;
}

@media (min-width: $screen-sm-min) {
  .ch2 {
    height: 500px
  }
}

.cpercent80 {
  width: 95%;
}

@media (min-width: 750px) {
  .cpercent80 {
    width: 100%;
  }
}

@media (min-width: 850px) {
  .cpercent80 {
    width: 90%;
  }
}

@media (min-width: 992px) {
  .cpercent80 {
    width: 100%;
  }
}

@media (min-width: 1050px) {
  .cpercent80 {
    width: 95%;
  }
}

@media (min-width: 1200px) {
  .cpercent80 {
    width: 100%;
  }
}

@media (min-width: 1350px) {
  .cpercent80 {
    width: 90%;
  }
}

@media (min-width: 1500px) {
  .cpercent80 {
    width: 80%;
  }
}

/*TOP FOR RELATIVE POSITIONS*/
.t1 {
  top: 1px;
}

.t2 {
  top: 1px;
}

.t3 {
  top: 3px;
}

.t4 {
  top: 4px;
}

.t5 {
  top: 5px;
}

.t6 {
  top: 6px;
}

.t7 {
  top: 7px;
}

.t8 {
  top: 8px;
}

.t9 {
  top: 9px;
}

.t10 {
  top: 10px;
}

.t15 {
  top: 15px;
}

.t20 {
  top: 20px;
}

.t30 {
  top: 30px;
}

.t40 {
  top: 40px;
}

.t50 {
  top: 50px;
}

.t-1 {
  top: -1px;
}

.t-2 {
  top: -1px;
}

.t-3 {
  top: -3px;
}

.t-4 {
  top: -4px;
}

.t-5 {
  top: -5px;
}

.t-6 {
  top: -6px;
}

.t-7 {
  top: -7px;
}

.t-8 {
  top: -8px;
}

.t-9 {
  top: -9px;
}

.t-10 {
  top: -10px;
}

.t-15 {
  top: -15px;
}

.t-20 {
  top: -20px;
}

.t-30 {
  top: -30px;
}

.t-40 {
  top: -40px;
}

.t-50 {
  top: -50px;
}

/*HEIGHT*/
.h01 {
  height: 1px;
}

.h02 {
  height: 2px;
}

.h03 {
  height: 3px;
}

.h04 {
  height: 4px;
}

.h05 {
  height: 5px;
}

.h06 {
  height: 6px;
}

.h07 {
  height: 7px;
}

.h08 {
  height: 8px;
}

.h09 {
  height: 9px;
}

.h10 {
  height: 10px;
}

.h20 {
  height: 20px;
}

.h30 {
  height: 30px;
}

.h40 {
  height: 40px;
}

.h50 {
  height: 50px;
}

.h60 {
  height: 60px;
}

.h70 {
  height: 70px;
}

.h80 {
  height: 80px;
}

.h90 {
  height: 90px;
}

.h100 {
  height: 100px;
}

.h110 {
  height: 110px;
}

.h120 {
  height: 120px;
}

.h130 {
  height: 130px;
}

.h140 {
  height: 140px;
}

.h150 {
  height: 150px;
}

.h160 {
  height: 160px;
}

.h170 {
  height: 170px;
}

.h180 {
  height: 180px;
}

.h190 {
  height: 190px;
}

.h200 {
  height: 200px;
}

.h210 {
  height: 210px;
}

.h220 {
  height: 220px;
}

.h230 {
  height: 230px;
}

.h240 {
  height: 240px;
}

.h250 {
  height: 250px;
}

.h260 {
  height: 260px;
}

.h270 {
  height: 270px;
}

.h280 {
  height: 280px;
}

.h290 {
  height: 290px;
}

.h300 {
  height: 300px;
}

.h310 {
  height: 310px;
}

.h320 {
  height: 320px;
}

.h330 {
  height: 330px;
}

.h340 {
  height: 340px;
}

.h350 {
  height: 350px;
}

.h360 {
  height: 360px;
}

.h370 {
  height: 370px;
}

.h380 {
  height: 380px;
}

.h390 {
  height: 390px;
}

.h400 {
  height: 400px;
}

.h410 {
  height: 410px;
}

.h420 {
  height: 420px;
}

.h430 {
  height: 430px;
}

.h440 {
  height: 440px;
}

.h450 {
  height: 450px;
}

.h460 {
  height: 460px;
}

.h470 {
  height: 470px;
}

.h480 {
  height: 480px;
}

.h490 {
  height: 490px;
}

.h500 {
  height: 500px;
}

.h510 {
  height: 510px;
}

.h520 {
  height: 520px;
}

.h530 {
  height: 530px;
}

.h540 {
  height: 540px;
}

.h550 {
  height: 550px;
}

.h560 {
  height: 560px;
}

.h570 {
  height: 570px;
}

.h580 {
  height: 580px;
}

.h590 {
  height: 590px;
}

.h600 {
  height: 600px;
}

.h610 {
  height: 610px;
}

.h620 {
  height: 620px;
}

.h630 {
  height: 630px;
}

.h640 {
  height: 640px;
}

.h650 {
  height: 650px;
}

.h660 {
  height: 660px;
}

.h670 {
  height: 670px;
}

.h680 {
  height: 680px;
}

.h690 {
  height: 690px;
}

.h700 {
  height: 700px;
}

.h710 {
  height: 710px;
}

.h720 {
  height: 720px;
}

.h730 {
  height: 730px;
}

.h740 {
  height: 740px;
}

.h750 {
  height: 750px;
}

.h760 {
  height: 760px;
}

.h770 {
  height: 770px;
}

.h780 {
  height: 780px;
}

.h790 {
  height: 790px;
}

.h800 {
  height: 800px;
}

.h900 {
  height: 900px;
}

.h1000 {
  height: 1000px;
}

.h1500 {
  height: 1500px;
}

.h2000 {
  height: 2000px;
}

.h3000 {
  height: 3000px;
}

.h4000 {
  height: 4000px;
}

.h5000 {
  height: 5000px;
}

.hauto {
  height: auto !important;
}

.hfull {
  height: 100%;
}

.hhalf {
  height: 50%;
}

/*WIDTH*/
.w10 {
  width: 10px;
}

.w20 {
  width: 20px;
}

.w30 {
  width: 30px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}

.w60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.w80 {
  width: 80px;
}

.w90 {
  width: 90px;
}

.w100 {
  width: 100px;
}

.w120 {
  width: 120px;
}

.w150 {
  width: 150px;
}

.w200 {
  width: 200px;
}

.w250 {
  width: 250px;
}

.w300 {
  width: 300px;
}

.w350 {
  width: 350px;
}

.w400 {
  width: 400px;
}

.w450 {
  width: 450px;
}

.w500 {
  width: 500px;
}

.w550 {
  width: 550px;
}

.w600 {
  width: 600px;
}

.w650 {
  width: 650px;
}

.w700 {
  width: 700px;
}

.w750 {
  width: 750px;
}

.w800 {
  width: 800px;
}

.w850 {
  width: 850px;
}

.w900 {
  width: 900px;
}

.w950 {
  width: 950px;
}

.w1000 {
  width: 1000px;
}

.w1500 {
  width: 1500px;
}

.w2000 {
  width: 2000px;
}

.wauto {
  width: auto;
}

.wfull {
  width: 100%;
}

.whalf {
  width: 50%;
}

.whfull {
  width: 100%;
  height: 100%;
}

/*SCALE*/
.scale9 {
  display: block;
  -ms-transform: scale(0.9, 0.9);
  -webkit-transform: scale(0.9, 0.9);
  transform: scale(0.9, 0.9);
}

.addscale {
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  @include transition(.2s);
}

.addscale.active {
  -ms-transform: scale(0.9, 0.9);
  -webkit-transform: scale(0.9, 0.9);
  transform: scale(0.9, 0.9);
}

/*COLORS*/
.cmaincolor {
  color: $maincolor !important;
}

.csecondcolor {
  color: $secondcolor !important;
}

.cwhite {
  color: #fff !important;
}

.cblack {
  color: #000 !important;
}

.clight {
  color: #ccc !important;
}

.clightgrey {
  color: #999 !important;
}

.cgrey {
  color: #666 !important;
}

.cdark {
  color: #222 !important;
}

.cdarkgrey {
  color: #333 !important;
}

.ccealk {
  color: #c1bf94 !important;
}

.cblue {
  color: #13acd8 !important;
}

.cgreen {
  color: #00cc66 !important;
}

.cgreen2 {
  color: #93c359 !important;
}

.cgreen3 {
  color: #95ce92 !important;
}

.cred {
  color: #cc6666 !important;
}

.corange {
  color: #ff9966 !important;
}

.cyellow {
  color: #ffffcc !important;
}

.c111 {
  color: #111 !important
}

.c222 {
  color: #222 !important
}

.c333 {
  color: #333 !important
}

.c444 {
  color: #444 !important
}

.c555 {
  color: #555 !important
}

.c666 {
  color: #666 !important
}

.c777 {
  color: #777 !important
}

.c888 {
  color: #888 !important
}

.c999 {
  color: #999 !important
}

/*BG COLORS*/
.bgmaincolor {
  background: $maincolor !important;
}

.bgmaincolordarken {
  background: darken($maincolor, 2%);
}

.bgmaincolorlighten {
  background: lighten($maincolor, 2%);
}

.bgsecondcolor {
  background: $secondcolor !important;
}

.bgsecondcolordarken {
  background: darken($secondcolor, 5%);
}

.bgsecondcolorlighten {
  background: lighten($secondcolor, 5%);
}

.bgwhite {
  background: #fff !important;
}

.bgblack {
  background: #000 !important;
}

.bglightgrey {
  background: #999 !important;
}

.bggrey {
  background: #666 !important;
}

.bgdarkgrey {
  background: #333 !important;
}

.bgdark {
  background: #222 !important;
}

.bgdark2 {
  background: #263842 !important;
}

.bgcealk {
  background: #c1bf94 !important;
}

.lightbg {
  background: #f6f6f6 !important;
}

.bgsoftlight {
  background: #fcfcfc !important;
}

.bgxlight {
  background: #fcfcfc !important;
}

.bglight {
  background: #f6f6f6 !important;
}

.bglight2 {
  background: #eee !important;
}

.bglight3 {
  background: #ddd !important;
}

.bglight4 {
  background: #ccc !important;
}

.bglime {
  background: #c1d88a !important;
}

.bglightturquoise {
  background: #1fc4d3 !important;
}

.bgturquoise {
  background: #669999 !important;
}

.bgturquoise2 {
  background: #99cccc !important;
}

.bglightgreen {
  background: #76f099 !important;
}

.bggreen {
  background: #00cc66 !important;
}

.bggreen2 {
  background: #93c359 !important;
}

.bgblue {
  background: #13acd8 !important;
}

.bgblue2 {
  background: #0099cc !important;
}

.bgblue3 {
  background: #55c7f6 !important;
}

.bgdarkblue {
  background: #233e83 !important;
}

.bgdarkblue2 {
  background: #1c1c38 !important;
}

.bgred {
  background: #cc3333 !important;
}

.bgpurple {
  background: #704b8c !important;
}

.bgorange {
  background: #ff9933 !important;
}

.bgorange2 {
  background: #ff9966 !important;
}

.bgorange3 {
  background: #ff6633 !important;
}

.bgpalred {
  background: #f4efe9 !important;
}

.bgpalred2 {
  background: #e4dcd3 !important;
}

.bgpalblue {
  background: #f5f6f7 !important;
}

.bgpalgreen {
  background: #e0ead9 !important;
}

.bgpalgreen2 {
  background: #e8ebe5 !important;
}

.bg000 {
  background: #000 !important
}

.bg111 {
  background: #111 !important
}

.bg222 {
  background: #222 !important
}

.bg333 {
  background: #333 !important
}

.bg444 {
  background: #444 !important
}

.bg555 {
  background: #555 !important
}

.bg666 {
  background: #666 !important
}

.bg777 {
  background: #777 !important
}

.bg888 {
  background: #888 !important
}

.bg999 {
  background: #999 !important
}

.bgtransparentblack {
  background: #333 !important;
  background: rgba(black, 0.3) !important;
}

/* GRAYSCALE */
.filtergray {
  @include filtergray(100%);
  @include transition(.2s);

  &:hover {
    @include filtergray(0%);
  }
}

/*BG images*/
.bgimage1 {
  background: url('../images/tm10/bg.jpg') #12171d;
}

.bgimage2 {
  background: url('../images/section_bg_1.jpg') #666 50% 0px repeat-y fixed;
}

.bgimage3 {
  background: url('../images/section_bg_2.jpg') #666 50% 0px repeat-y fixed;
}

/*COVER IMAGES*/
.cover1 {
  background: url('../images/test/test.jpg') center center;
  background-size: cover;
}

.cover1b {
  background: url('../images/test/test3.jpg') center center;
  background-size: cover;
}

.cover2 {
  background: url('../images/covers/cover2.jpg') center center;
  background-size: cover;
  z-index: 100;
  @include transition(.5s);
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);

  &:hover {
    -ms-transform: scale(0.9, 0.9);
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
}

.cover2b {
  background: url('../images/covers/cover2.jpg') center center;
  background-size: cover;
  z-index: 100;
}

.cover3 {
  background: url('../images/test/pic4.jpg') center center;
  background-size: cover;
  // @include transition(.5s);
  // -ms-transform: scale(1,1); 
  // -webkit-transform: scale(1,1); 
  // transform: scale(1,1); 
  // &:hover{
  //   -ms-transform: scale(0.9,0.9); 
  //   -webkit-transform: scale(0.9,0.9); 
  //   transform: scale(0.9,0.9); 
  // }
}

.cover4 {
  background: url('../images/bg1b.jpg') fixed;
  background-size: cover;
}

.cover5 {
  background: url('../images/section_bg_2.jpg') fixed;
  background-size: cover;
}

.cover5b {
  background: url('../images/tm17/section_bg_2.jpg') fixed;
  background-size: cover;
}

.cover6 {
  background: url('../images/tm7/cover.jpg') center center;
  background-size: cover;
}

.cover6b {
  background: url('../images/tm7/cover2.jpg') center center;
  background-size: cover;
}

.cover-career {
  background: url('../images/secondpages/pic1.jpg') center center;
  background-size: cover;
}

.maintenance {
  background: url('../images/secondpages/maintenancemode.jpg') center center;
  background-size: cover;
}

.comingsoon {
  background: url('../images/secondpages/agency.jpg') center center;
  background-size: cover;
}

/*Cover right text*/
.cover-right-text {
  padding-left: 10px !important;
}

@media (min-width:$screen-md-min) {
  .cover-right-text {
    padding-left: 50px !important;
  }
}

.bgcover {
  background: url('../images/tm15/slide.jpg') center center;
  background-size: cover;
}

.bgcover2 {
  background: url('../images/tm15/slide2.jpg') center center;
  background-size: cover;
}



/*BREADCRUMBS*/
.breadcrumbs {
  font-size: 10px;
  color: #ccc;
  text-align: center;
  text-transform: uppercase;

  a {
    color: #ccc;
  }

  a:hover {
    color: #222;
  }

  a.inactive {
    color: #ddd;

    &:hover {
      color: #ddd;
    }
  }

  i {
    padding: 0 10px;
  }
}

div.toparrow {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #efefef;
  margin: 0 auto;
  position: relative;
  bottom: -40px;
}

/*PAGINATION*/
ul.pagination2 {
  li {
    float: left;
    margin-right: 3px;

    a {
      display: block;
      width: 25px;
      height: 25px;
      background: #ccc;
      color: #fff;
      padding: 2px 8px;
      border-radius: 2px;

      &:hover,
      &:focus {
        background: $maincolor;
      }
    }

    &.active {
      a {
        background: #fff;
        color: #444;
      }
    }
  }
}

ul.pagination2.white {
  li {
    &.active {
      a {
        background: $maincolor;
        color: #fff;
      }
    }
  }
}

ul.pagination2.round {
  li {
    a {
      background: #efefef;
      color: #999;

      border-radius: 50%;
    }

    &.active {
      a {
        background: $maincolor;
        color: #fff;
      }
    }
  }
}

/*SHADOW*/
.shadow {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.shadow2 {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}

.shadow3 {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
}

.shadowinsett {
  box-shadow: inset 20px 0px 20px -10px rgba(0, 0, 0, 0.05)
}

/*BORDERS*/
.round {
  border-radius: 100px !important;
}

.roundleft {
  border-radius: 100px 0 0 100px !important;
}

.roundright {
  border-radius: 0 100px 100px 0 !important;
}

.brdlight {
  border: 1px solid #e2e2e2;
}

.borderall {
  border: 1px solid #efefef;
}

.bordertop {
  border-top: 1px solid #efefef;
}

.borderbottom {
  border-bottom: 1px solid #efefef;
}

.borderleft {
  border-left: 1px solid #efefef;
}

.borderright {
  border-right: 1px solid #efefef;
}

.bordertopbottom {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.borderright-hide {
  border-right: 0px solid #efefef;

  @media (min-width: $screen-md-min) {
    border-right: 1px solid #efefef;
  }
}

$bcolor:#333; //variable

.borderall444 {
  border: 1px solid $bcolor;
}

.bordertop444 {
  border-top: 1px solid $bcolor;
}

.borderbottom444 {
  border-bottom: 1px solid $bcolor;
}

.borderleft444 {
  border-left: 1px solid $bcolor;
}

.borderright444 {
  border-right: 1px solid $bcolor;
}

.bordertopbottom444 {
  border-top: 1px solid $bcolor;
  border-bottom: 1px solid $bcolor;
}


.borderallmain {
  border: 1px solid desaturate(darken($maincolor, 5%), 10%);
}

.bordertopmain {
  border-top: 1px solid desaturate(darken($maincolor, 5%), 10%);
}

.borderbottommain {
  border-bottom: 1px solid desaturate(darken($maincolor, 5%), 10%);
}

.borderleftmain {
  border-left: 1px solid desaturate(darken($maincolor, 5%), 10%);
}

.borderrightmain {
  border-right: 1px solid desaturate(darken($maincolor, 5%), 10%);
}

.bordertopbottommain {
  border-top: 1px solid desaturate(darken($maincolor, 5%), 10%);
  border-bottom: 1px solid $bcolor;
}

.bordertopcolor {
  border-top: 5px solid $maincolor
}

.bordernone {
  border: 0px !important;
}

.noborder {
  border: 0px !important;
}

.noborders {
  border: 0px !important;
}

.removeborders {
  border: 0px !important;
}

/*CUSTOM BORDERS*/
.cborder {
  border-top: 1px solid #444;
  border-left: 0px;
}

@media (min-width: $screen-md-min) {
  .cborder {
    border-top: 0px;
    border-left: 1px solid #444;
  }
}

/*CUSTOM*/
.secpage {
  background: #ffffff;
  position: relative;
  height: 31px;
  z-index: 100;

  @media (min-width: $screen-sm-min) {
    height: 110px;
  }

  @media (min-width: $screen-md-min) {
    height: 110px;
  }
}

.alert {
  border-radius: 0px;
  margin-bottom: 20px;
  padding: 10px;
  border: 0px;
}

.spanspace span {
  display: inline-block;
  padding: 0px 5px;
  margin: 0px !important;
  font-size: 12px;
}

.spanspace2 span {
  display: inline-block;
  padding: 2px 10px;
  margin: 2px !important;
  font-size: 11px;
  color: #fff;
  border-radius: 2px
}


/*CUSTOM POSITIONS*/
.custpos01 {
  padding: 3%;
}

.cus-pos-abs {
  position: relative;
  margin: 0 auto;
  left: 0px;
  right: 0px;
}

@media (min-width: $screen-md-min) {
  .cus-pos-abs {
    position: absolute;
    margin: 0 auto;
    left: 0px;
    right: 0px;
  }
}

.cuspos {
  padding-top: 50px;
}

@media (min-width: $screen-md-min) {
  .cuspos {
    padding-top: 120px;
  }
}

.changezindex {
  z-index: 20;
  position: relative;
}

@media (min-width: $screen-md-min) {
  .changezindex {
    z-index: auto;
    position: static;
  }
}


/*LEFTMENU*/
.leftmenu {
  list-style: none;

  li {
    text-align: right;
  }

  li span {
    float: right;
    font-size: 10px;
    margin: 4px 0 0 15px;
  }

  li span:after {
    font-family: 'fontello';
    content: "\e8b3";
  }

  li.title {
    font-family: proxima_novaextrabold;
    text-transform: uppercase;

    padding: 0 18px 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }

  li a {
    color: #000;
    padding: 5px 0px;
    display: inline-block;
    width: 100%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    text-decoration: none;
  }

  li.active a {
    color: $maincolor;
  }

  li:hover a {
    color: $maincolor;
  }

}

.leftborder {
  border-left: 1px solid #efefef;
}

/*RIGHTMENU*/
.rightmenu {
  list-style: none;

  li {
    text-align: left;
  }

  li span {
    float: left;
    font-size: 10px;
    margin: 4px 15px 0 0;
  }

  li span:after {
    font-family: 'fontello';
    content: "\e8b2";
  }

  li.title {
    font-family: proxima_novaextrabold;
    text-transform: uppercase;

    padding: 0 18px 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }

  li a {
    color: #000;
    padding: 5px 0px;
    display: inline-block;
    width: 100%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    text-decoration: none;
  }

  li.active a {
    color: $maincolor;
  }

  li:hover a {
    color: $maincolor;
  }

}

// Sidemenu List
ul.sidemenu {
  list-style: none;

  li {
    text-indent: 0px;
    background: #fff;
    border: 1px solid darken(#efefef, 0%);
    margin-top: -1px;

    a {
      display: block;
      padding: 15px 20px;
      color: #999;

      &:hover,
      &:focus {
        text-decoration: none;
        margin-left: 5px;
        color: $maincolor;
      }

      &.select {
        color: #333;
      }
    }
  }

  li.title {
    font-weight: bold;

    a {
      color: #222;
    }
  }

  li:before {
    font-family: fontello;
    float: left;
    width: 30px;
    margin-top: 15px;
    margin-left: 20px;
    color: #ccc;
  }

  li.inbox:before {
    content: "\E867";
    font-size: 20px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 5px;
  }

  li.arrow:before {
    content: "\e830";
  }

  li.arrow2:before {
    content: "\e831";
  }

  li.arrow3:before {
    content: "\e80a";
  }
}

ul.sidemenu.dark {
  list-style: none;

  li {
    text-indent: 0px;
    background: #222;
    border: 1px solid darken(#222, 0%);
    margin-top: -1px;

    a {
      display: block;
      padding: 15px 20px;
      color: #999;

      &:hover,
      &:focus {
        text-decoration: none;
        margin-left: 5px;
        color: $maincolor;
      }

      &.select {
        color: #333;
      }
    }
  }

  li.title {
    font-weight: bold;

    a {
      color: #fff;
      border-bottom: 1px solid lighten(#222, 3%);
    }
  }

  li:before {
    font-family: fontello;
    float: left;
    width: 30px;
    margin-top: 15px;
    margin-left: 20px;
    color: #ccc;
  }

  li.inbox:before {
    content: "\E867";
    font-size: 20px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 5px;
  }

  li.arrow:before {
    content: "\e830";
  }

  li.arrow2:before {
    content: "\e831";
  }

  li.arrow3:before {
    content: "\e80a";
  }

  li.chart1:before {
    content: "\E840";
  }

  li.people:before {
    content: "\E84D";
  }

  li.seo:before {
    content: "\E8A9";
  }

  li.search:before {
    content: "\E839";
  }

  li.device:before {
    content: "\E83E";
  }

  li.feather:before {
    content: "\E829";
  }

  li.social:before {
    content: "\E84B";
  }
}

ul.sidemenu.transparent {
  list-style: none;

  li {
    text-indent: 0px;
    background: transparent;
    border: 0px solid darken(#222, 0%);
    margin-top: -1px;

    a {
      display: block;
      padding: 15px 20px;
      color: #999;

      &:hover,
      &:focus {
        text-decoration: none;
        margin-left: 5px;
        color: $maincolor;
      }

      &.select {
        color: #333;
      }
    }
  }

  li.title {
    font-weight: bold;

    a {
      color: #fff;
      border-bottom: 1px solid lighten(#222, 3%);
    }
  }

  li:before {
    font-family: fontello;
    float: left;
    width: 30px;
    margin-top: 15px;
    margin-left: 20px;
    color: #ccc;
  }

  li.inbox:before {
    content: "\E867";
    font-size: 20px;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 5px;
  }

  li.arrow:before {
    content: "\e830";
    margin-left: 0px;
  }

  li.arrow2:before {
    content: "\e831";
    margin-left: 0px;
  }

  li.arrow3:before {
    content: "\e80a";
    margin-left: 0px;
  }

  li.chart1:before {
    content: "\E840";
    margin-left: 0px;
  }

  li.people:before {
    content: "\E84D";
    margin-left: 0px;
  }

  li.seo:before {
    content: "\E8A9";
    margin-left: 0px;
  }

  li.search:before {
    content: "\E839";
    margin-left: 0px;
  }

  li.device:before {
    content: "\E83E";
    margin-left: 0px;
  }

  li.feather:before {
    content: "\E829";
    margin-left: 0px;
  }

  li.social:before {
    content: "\E84B";
    margin-left: 0px;
  }
}

ul.sidemenu.maincolor {
  list-style: none;

  li {
    text-align: left;
    text-indent: 0px;
    background: $maincolor;
    border: 1px solid darken($maincolor, 0%);
    margin-top: -1px;

    a {
      display: block;
      padding: 15px 20px;
      color: #fff;

      &:hover,
      &:focus {
        text-decoration: none;
        margin-left: 5px;
        color: darken($maincolor, 10%);
      }

      &.select {
        color: #333;
      }
    }
  }

  li.title {
    font-weight: bold;

    a {
      color: #fff;
      border-bottom: 1px solid lighten($maincolor, 3%);
    }
  }

  li:before {
    font-family: fontello;
    float: left;
    width: 30px;
    margin-top: 15px;
    margin-left: 20px;
    color: #ccc;
  }

  li.inbox:before {
    content: "\E867";
    font-size: 20px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 5px;
  }

  li.arrow:before {
    content: "\e830";
  }

  li.arrow2:before {
    content: "\e831";
  }

  li.arrow3:before {
    content: "\e80a";
  }

  li.chart1:before {
    content: "\E840";
  }

  li.people:before {
    content: "\E84D";
  }

  li.seo:before {
    content: "\E8A9";
  }

  li.search:before {
    content: "\E839";
  }

  li.device:before {
    content: "\E83E";
  }

  li.feather:before {
    content: "\E829";
  }

  li.social:before {
    content: "\E84B";
  }
}

// Custom lists colors
ul.cgray {
  a {
    color: #999;

    &:hover {
      color: $maincolor;
    }
  }
}

/*PERCENTAGES*/
.percent100 {
  width: 100%;
}

.percent95 {
  width: 95%;
}

.percent90 {
  width: 90%;
}

.percent85 {
  width: 85%;
}

.percent80 {
  width: 80%;
}

.percent75 {
  width: 75%;
}

.percent60 {
  width: 60%;
}

.percent50 {
  width: 50%;
}

.percent33 {
  width: 33.33%;
}

.percent66 {
  width: 66.66%;
}

.percent25 {
  width: 25%;
}

.percent20 {
  width: 20%;
}



/*FIX BUTTONS POSITION FOR MOBILES*/
.rev-l {
  margin: 0 auto;
  display: block;
}

.rev-r {
  margin: 0 auto;
  display: block;
  margin-top: 30px !important;
}

@media (min-width:$screen-md-min) {
  .rev-l {
    float: right;
  }

  .rev-r {
    float: left;
    margin-top: 0px !important;
  }
}

//Increase font size for mobile
.slidertitle {
  font-family: proxima_nova_rgbold;
  font-size: 30px !important;
  color: white;

  @media (min-width:$screen-sm-min) {
    font-size: 50px !important;
  }
}

.slidersubtitle {
  font-family: proxima_novalight;
  font-size: 14px !important;
  color: white;

  @media (min-width:$screen-sm-min) {
    font-size: 30px !important;
  }
}

//FIX CAPTION POSITION FOR MOBILES
.rev-p1 {
  margin-top: -140px !important;
  font-size: 60px !important;
  line-height: 60px !important;

  @media (min-width:$screen-sm-min) {
    margin-top: -70px !important;
    font-size: 50px !important;
  }

  @media (min-width:$screen-md-min) {
    margin-top: 0px !important;
    font-size: 50px !important;
  }
}

.rev-ct1 {
  margin-top: 250px !important;

  @media (min-width:$screen-sm-min) {
    margin-top: 150px !important;
  }

  @media (min-width:$screen-md-min) {
    margin-top: 0px !important;
  }
}

.rev-p2 {
  font-size: 60px !important;
  line-height: 60px !important;
  margin-bottom: 130px !important;

  @media (min-width:$screen-sm-min) {
    font-size: 50px !important;
    margin-bottom: 30px !important;
  }

  @media (min-width:$screen-md-min) {
    font-size: 50px !important;
    margin-bottom: 0px !important;
  }
}




/*BUTTONS with variables*/
// .btnmaincolor{ background:$maincolor; border-radius:0px; font-size:16px; color:#fff; padding:10px 20px; -webkit-transition:.5s;-moz-transition:.5s;transition:.5s; }
// .btnmaincolor:hover{ background:#222222; color:#fff; -webkit-transition:.5s;-moz-transition:.5s;transition:.5s; }

.btnmaincolor {
  background: $maincolor;
  border-radius: 0px;
  font-size: 14px;
  color: #fff !important;
  padding: 13px 20px 10px 20px;
  border: 2px solid $maincolor;
  @include transition(.5s);

  &:hover,
  &:focus {
    background: desaturate(darken($maincolor, 15), 25);
    border: 2px solid desaturate(darken($maincolor, 15), 25);
    color: #fff !important;
  }
}

.btnsecondcolor {
  background: $secondcolor;
  border-radius: 0px;
  font-size: 14px;
  color: #fff !important;
  padding: 13px 20px 10px 20px;
  border: 2px solid $secondcolor;
  @include transition(.5s);

  &:hover {
    background: desaturate(darken($secondcolor, 15), 25);
    border: 2px solid desaturate(darken($secondcolor, 15), 25);
    color: #fff !important;

  }
}

.btnmainline {
  background: transparent;
  border: 2px solid $maincolor;
  border-radius: 0px;
  font-size: 16px;
  color: $maincolor;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;

  &:hover {
    background: transparent;
    border: 2px solid lighten($maincolor, 10%);
    color: lighten($maincolor, 10%);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
  }
}

.btnsecondline {
  background: transparent;
  border: 2px solid $secondcolor;
  border-radius: 0px;
  font-size: 16px;
  color: $secondcolor;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;

  &:hover {
    background: transparent;
    border: 2px solid lighten($secondcolor, 10%);
    color: lighten($secondcolor, 10%);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
  }
}

/*BUTTONS*/

.btnwhite {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
}

.btnwhite:hover {
  background: #fff;
  color: #666;
  border: 1px solid #adadad;
}

.btnwhite2 {
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  background: transparent;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.0);
  border-radius: 0px;
}

.btnwhite2:hover {
  background: transparent;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 1);
}

.btnblack {
  border: 1px solid #222;
  background: transparent;
  color: #666;
  border-radius: 0px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnblack:hover {
  background: transparent;
  border: 1px solid #444;
  color: #fff;
}

.btnwhitebig {
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
  padding: 10px 25px;
  font-size: 16px;
}

.btnwhitebig:hover {
  background: #fff;
  border: 1px solid #adadad;
}

.btnwhitehuge {
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
  padding: 15px 60px;
  font-size: 16px;
}

.btnwhitehuge:hover {
  background: #fff;
  border: 1px solid #adadad;
}

.btndarkhuge {
  border: 1px solid #333;
  background: #222;
  color: #666;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
  padding: 15px 60px;
  font-size: 16px;
}

.btndarkhuge:hover {
  background: #222;
  border: 1px solid #444;
  color: #ff6633;
}

.btngreenhuge {
  background: #95ce92;
  color: #fff;
  border: 1px solid darken(#95ce92, 10%);
  border-radius: 0px;
  padding: 15px 60px;
  font-size: 16px;
}

.btngreenhuge:hover {
  background: darken(#95ce92, 10%);
  color: #fff;
  border: 1px solid #adadad;
}

.btndarkround {
  background: #222222;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkround:hover {
  background: #ff6633;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeround {
  background: #ff6633;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeround:hover {
  background: #222222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteround {
  background: #fff;
  border-radius: 3px;
  font-size: 16px;
  color: #222;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteround:hover {
  background: #222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}


/*Line buttons*/

.btndarkroundline {
  background: transparent;
  border: 1px solid #222222;
  border-radius: 3px;
  font-size: 16px;
  color: #222;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkroundline:hover {
  background: #222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeroundline {
  background: transparent;
  border: 1px solid #ff6633;
  border-radius: 3px;
  font-size: 16px;
  color: #ff6633;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeroundline:hover {
  background: #ff6633;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteroundline {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteroundline:hover {
  background: #fff;
  color: #222;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkroundline2 {
  background: transparent;
  border: 2px solid #222222;
  border-radius: 3px;
  font-size: 16px;
  color: #222;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkroundline2:hover {
  background: #222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeroundline2 {
  background: transparent;
  border: 2px solid #ff6633;
  border-radius: 3px;
  font-size: 16px;
  color: #ff6633;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeroundline2:hover {
  background: #ff6633;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteroundline2 {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteroundline2:hover {
  background: #fff;
  color: #222;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigwhiteroundline2 {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigwhiteroundline2:hover {
  background: #fff;
  color: #222;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkroundline2 {
  background: transparent;
  border: 2px solid #222;
  border-radius: 5px;
  font-size: 16px;
  color: #222;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkroundline2:hover {
  background: #222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkroundline2orange {
  background: transparent;
  border: 2px solid #222;
  border-radius: 5px;
  font-size: 16px;
  color: #222;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkroundline2orange:hover {
  background: #f63;
  border: 2px solid #f63;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkroundline2orangeline {
  background: transparent;
  border: 2px solid #222;
  border-radius: 5px;
  font-size: 16px;
  color: #222;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkroundline2orangeline:hover {
  background: transparent;
  border: 2px solid #f63;
  color: #f63;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}


/*Square buttons*/
.btndark {
  background: #333;
  border-radius: 0px;
  font-size: 16px;
  color: #999;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndark:hover {
  background: #444;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btncyan {
  background: #00cccc;
  border-radius: 0px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btncyan:hover {
  background: #00cccc;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btngreen {
  background: #93c359;
  border-radius: 0px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btngreen:hover,
.btngreen:focus {
  background: #99cc66;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

// .btnorange{ background:#ff6633; border-radius:0px; font-size:16px; color:#fff; padding:10px 20px; -webkit-transition:.5s;-moz-transition:.5s;transition:.5s; }
// .btnorange:hover{ background:#222222; color:#fff; -webkit-transition:.5s;-moz-transition:.5s;transition:.5s; }


.btnorange {
  background: #ff9966;
  border-radius: 0px;
  font-size: 16px;
  color: #fff !important;
  padding: 13px 20px 10px 20px;
  @include transition(.5s);

  &:hover,
  &:focus {
    background: darken(#ff9966, 20%);
    color: #fff !important;
  }
}

.btnorange2 {
  background: #ff6633;
  border-radius: 0px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorange2:hover {
  background: #222222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnblue {
  background: #13acd8;
  border-radius: 0px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnblue:hover {
  background: #0099cc;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnblue:focus {
  color: #fff;
}


.btncealk {
  background: #c1bf94;
  border-radius: 0px;
  border: 0px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btncealk:hover {
  background: #ff6633;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhitesquare {
  background: #fff;
  border-radius: 0px;
  font-size: 16px;
  color: #222;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhitesquare:hover {
  background: #222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkline {
  background: transparent;
  border: 1px solid #333;
  border-radius: 0px;
  font-size: 16px;
  color: #999;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkline:hover {
  background: $maincolor;
  border: 1px solid $maincolor;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeline {
  background: transparent;
  border: 1px solid #ff6633;
  border-radius: 0px;
  font-size: 16px;
  color: #ff6633;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeline:hover {
  background: #ff6633;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteline {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 16px;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteline:hover {
  background: $maincolor;
  border: 1px solid $maincolor;
  color: #fff !important;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhitelineblue {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 16px;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhitelineblue:hover {
  background: #55c7f6;
  border: 1px solid #55c7f6;
  color: #fff !important;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhitelinegreen {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 16px;
  color: #fff;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhitelinegreen:hover {
  background: #3FC1AA;
  border: 1px solid #3FC1AA;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkline2 {
  background: transparent;
  border: 2px solid #222222;
  border-radius: 0px;
  font-size: 16px;
  color: #222 !important;
  padding: 13px 20px 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btndarkline2:hover {
  background: #222;
  color: #fff !important;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeline2 {
  background: transparent;
  border: 2px solid #ff6633;
  border-radius: 0px;
  font-size: 16px;
  color: #ff6633;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnorangeline2:hover {
  background: #ff6633;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteline2 {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0px;
  font-size: 14px;
  color: #fff !important;
  padding: 13px 20px 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnwhiteline2:hover {
  background: white;
  border: 2px solid rgba(white, 1);
  color: rgba(black, 1) !important;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btngreywhiteline {
  background: transparent;
  border: 2px solid #999;
  color: #999;
  border-radius: 0px;
  font-size: 16px;
  padding: 10px 20px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btngreywhiteline:hover {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigwhiteline2 {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0px;
  font-size: 16px;
  color: #fff;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigwhiteline2:hover {
  background: #fff;
  color: #222;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkline2 {
  background: transparent;
  border: 2px solid #222;
  border-radius: 0px;
  font-size: 16px;
  color: #222;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkline2:hover {
  background: #222;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkline2orange {
  background: transparent;
  border: 2px solid #222;
  border-radius: 0px;
  font-size: 16px;
  color: #222;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkline2orange:hover {
  background: #f63;
  border: 2px solid #f63;
  color: #fff;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkline2orangeline {
  background: transparent;
  border: 2px solid #222;
  border-radius: 0px;
  font-size: 16px;
  color: #222;
  padding: 15px 40px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

.btnbigdarkline2orangeline:hover {
  background: transparent;
  border: 2px solid #f63;
  color: #f63;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}


/*BUTTONS ROUND*/
.roundbutton {
  border-radius: 100px;
  border: 3px solid #fff;
  background: transparent;
  color: white;
  padding: 10px;
  font-weight: bold;

  // @include transition(.2s);
  &:hover,
  &:focus {
    border: 3px solid #fff;
    color: #fff;
    opacity: 0.7;

  }
}

.roundbutton-green {
  border-radius: 100px;
  border: 3px solid #fff;
  background: transparent;
  color: white;
  padding: 10px;
  font-weight: bold;
  @include transition(.2s);

  &:hover,
  &:focus {
    border: 3px solid #33cc66;
    color: #33cc66;
  }
}

.roundbutton-dark {
  border-radius: 100px;
  border: 3px solid #222;
  background: transparent;
  color: #222;
  padding: 10px;
  font-weight: bold;

  // @include transition(.2s);
  &:hover,
  &:focus {
    border: 3px solid #222;
    color: #222;
    opacity: 0.7;

  }
}

.roundbutton-grey {
  border-radius: 100px;
  border: 3px solid #999;
  background: transparent;
  color: #999;
  padding: 10px;
  font-weight: bold;

  // @include transition(.2s);
  &:hover,
  &:focus {
    border: 3px solid #fff;
    color: #fff;
    opacity: 1;

  }
}

/*CUSTOM MARGIN*/
.cmt {
  margin-top: 20px;
}

@media (min-width:$screen-md-min) {
  .cmt {
    margin-top: 0px;
  }
}

/*MARGINS*/
.m1 {
  margin: 1px;
}

.m2 {
  margin: 2px;
}

.m3 {
  margin: 3px;
}

.m4 {
  margin: 4px;
}

.m5 {
  margin: 5px;
}

.m6 {
  margin: 6px;
}

.m7 {
  margin: 7px;
}

.m8 {
  margin: 8px;
}

.m9 {
  margin: 9px;
}

.m10 {
  margin: 10px;
}

.m11 {
  margin: 11px;
}

.m12 {
  margin: 12px;
}

.m13 {
  margin: 13px;
}

.m14 {
  margin: 14px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.m30 {
  margin: 30px;
}

.m40 {
  margin: 40px;
}

.m50 {
  margin: 50px;
}

.m60 {
  margin: 60px;
}

.m70 {
  margin: 70px;
}

.m80 {
  margin: 80px;
}

.m90 {
  margin: 90px;
}

.m100 {
  margin: 100px;
}

/*MARGIN TOP*/
.mt0 {
  margin-top: 0px;
}

.mt1 {
  margin-top: 1px;
}

.mt2 {
  margin-top: 2px;
}

.mt3 {
  margin-top: 3px;
}

.mt4 {
  margin-top: 4px;
}

.mt5 {
  margin-top: 5px;
}

.mt6 {
  margin-top: 6px;
}

.mt7 {
  margin-top: 7px;
}

.mt8 {
  margin-top: 8px;
}

.mt9 {
  margin-top: 9px;
}

.mt10 {
  margin-top: 10px;
}

.mt11 {
  margin-top: 11px;
}

.mt12 {
  margin-top: 12px;
}

.mt13 {
  margin-top: 13px;
}

.mt14 {
  margin-top: 14px;
}

.mt15 {
  margin-top: 15px;
}

.mt16 {
  margin-top: 16px;
}

.mt17 {
  margin-top: 17px;
}

.mt18 {
  margin-top: 18px;
}

.mt19 {
  margin-top: 19px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

.mt-1 {
  margin-top: -1px;
}

.mt-2 {
  margin-top: -2px;
}

.mt-3 {
  margin-top: -3px;
}

.mt-4 {
  margin-top: -4px;
}

.mt-5 {
  margin-top: -5px;
}

.mt-6 {
  margin-top: -6px;
}

.mt-7 {
  margin-top: -7px;
}

.mt-8 {
  margin-top: -8px;
}

.mt-9 {
  margin-top: -9px;
}

.mt-10 {
  margin-top: -10px;
}

.mt-15 {
  margin-top: -15px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-25 {
  margin-top: -25px;
}

.mt-30 {
  margin-top: -30px;
}

.mt-40 {
  margin-top: -40px;
}

.mt-50 {
  margin-top: -50px;
}

.mt-60 {
  margin-top: -60px;
}

.mt-70 {
  margin-top: -70px;
}

.mt-80 {
  margin-top: -80px;
}

.mt-90 {
  margin-top: -90px;
}

.mt-100 {
  margin-top: -100px;
}

/*MARGIN BOTTOM*/
.mb1 {
  margin-bottom: 1px;
}

.mb2 {
  margin-bottom: 2px;
}

.mb3 {
  margin-bottom: 3px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb6 {
  margin-bottom: 6px;
}

.mb7 {
  margin-bottom: 7px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb9 {
  margin-bottom: 9px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb11 {
  margin-bottom: 11px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb13 {
  margin-bottom: 13px;
}

.mb14 {
  margin-bottom: 14px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb16 {
  margin-bottom: 12px;
}

.mb17 {
  margin-bottom: 13px;
}

.mb18 {
  margin-bottom: 14px;
}

.mb19 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb-1 {
  margin-bottom: -1px;
}

.mb-2 {
  margin-bottom: -2px;
}

.mb-3 {
  margin-bottom: -3px;
}

.mb-4 {
  margin-bottom: -4px;
}

.mb-5 {
  margin-bottom: -5px;
}

.mb-6 {
  margin-bottom: -6px;
}

.mb-7 {
  margin-bottom: -7px;
}

.mb-8 {
  margin-bottom: -8px;
}

.mb-9 {
  margin-bottom: -9px;
}

.mb-10 {
  margin-bottom: -10px;
}

.mb-11 {
  margin-bottom: -11px;
}

.mb-12 {
  margin-bottom: -12px;
}

.mb-13 {
  margin-bottom: -13px;
}

.mb-14 {
  margin-bottom: -14px;
}

.mb-15 {
  margin-bottom: -15px;
}

.mb-20 {
  margin-bottom: -20px;
}

.mb-30 {
  margin-bottom: -30px;
}

.mb-40 {
  margin-bottom: -40px;
}

.mb-50 {
  margin-bottom: -50px;
}

.mb-60 {
  margin-bottom: -60px;
}

.mb-70 {
  margin-bottom: -70px;
}

.mb-80 {
  margin-bottom: -80px;
}

.mb-90 {
  margin-bottom: -90px;
}

.mb-100 {
  margin-bottom: -100px;
}

/*MARGIN TOP & BOTTOM*/
.mtb1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mtb2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mtb3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mtb4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mtb5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mtb6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mtb7 {
  margin-top: 7px;
  margin-bottom: 7px;
}

.mtb8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mtb9 {
  margin-top: 9px;
  margin-bottom: 9px;
}

.mtb15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mtb10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mtb20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mtb40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mtb50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mtb60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mtb70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mtb80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mtb90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mtb100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

/*MARGIN RIGHT*/
.mr1 {
  margin-right: 1px;
}

.mr2 {
  margin-right: 2px;
}

.mr3 {
  margin-right: 3px;
}

.mr4 {
  margin-right: 4px;
}

.mr5 {
  margin-right: 5px;
}

.mr6 {
  margin-right: 6px;
}

.mr7 {
  margin-right: 7px;
}

.mr8 {
  margin-right: 8px;
}

.mr9 {
  margin-right: 9px;
}

.mr10 {
  margin-right: 10px;
}

.mr11 {
  margin-right: 11px;
}

.mr12 {
  margin-right: 12px;
}

.mr13 {
  margin-right: 13px;
}

.mr14 {
  margin-right: 14px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.mr70 {
  margin-right: 70px;
}

.mr80 {
  margin-right: 80px;
}

.mr90 {
  margin-right: 90px;
}

.mr100 {
  margin-right: 100px;
}

.mr-1 {
  margin-right: -1px;
}

.mr-2 {
  margin-right: -2px;
}

.mr-3 {
  margin-right: -3px;
}

.mr-4 {
  margin-right: -4px;
}

.mr-5 {
  margin-right: -5px;
}

.mr-6 {
  margin-right: -6px;
}

.mr-7 {
  margin-right: -7px;
}

.mr-8 {
  margin-right: -8px;
}

.mr-9 {
  margin-right: -9px;
}

.mr-10 {
  margin-right: -10px;
}

.mr-11 {
  margin-right: -11px;
}

.mr-12 {
  margin-right: -12px;
}

.mr-13 {
  margin-right: -13px;
}

.mr-14 {
  margin-right: -14px;
}

.mr-15 {
  margin-right: -15px;
}

.mr-20 {
  margin-right: -20px;
}

.mr-30 {
  margin-right: -30px;
}

.mr-40 {
  margin-right: -40px;
}

.mr-50 {
  margin-right: -50px;
}

.mr-60 {
  margin-right: -60px;
}

.mr-70 {
  margin-right: -70px;
}

.mr-80 {
  margin-right: -80px;
}

.mr-90 {
  margin-right: -90px;
}

.mr-100 {
  margin-right: -100px;
}

/*MARGIN LEFT*/
.ml1 {
  margin-left: 1px;
}

.ml2 {
  margin-left: 2px;
}

.ml3 {
  margin-left: 3px;
}

.ml4 {
  margin-left: 4px;
}

.ml5 {
  margin-left: 5px;
}

.ml6 {
  margin-left: 6px;
}

.ml7 {
  margin-left: 7px;
}

.ml8 {
  margin-left: 8px;
}

.ml9 {
  margin-left: 9px;
}

.ml10 {
  margin-left: 10px;
}

.ml11 {
  margin-left: 11px;
}

.ml12 {
  margin-left: 12px;
}

.ml13 {
  margin-left: 13px;
}

.ml14 {
  margin-left: 14px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.ml90 {
  margin-left: 90px;
}

.ml100 {
  margin-left: 100px;
}

.ml120 {
  margin-left: 120px;
}

/*MARGIN LEFT NEGATIVE*/
.ml-1 {
  margin-left: -1px;
}

.ml-2 {
  margin-left: -2px;
}

.ml-3 {
  margin-left: -3px;
}

.ml-4 {
  margin-left: -4px;
}

.ml-5 {
  margin-left: -5px;
}

.ml-6 {
  margin-left: -6px;
}

.ml-7 {
  margin-left: -7px;
}

.ml-8 {
  margin-left: -8px;
}

.ml-9 {
  margin-left: -9px;
}

.ml-10 {
  margin-left: -10px;
}

.ml-11 {
  margin-left: -11px;
}

.ml-12 {
  margin-left: -12px;
}

.ml-13 {
  margin-left: -13px;
}

.ml-14 {
  margin-left: -14px;
}

.ml-15 {
  margin-left: -15px;
}

.ml-20 {
  margin-left: -20px;
}

.ml-30 {
  margin-left: -30px;
}

.ml-40 {
  margin-left: -40px;
}

.ml-50 {
  margin-left: -50px;
}

.ml-60 {
  margin-left: -60px;
}

.ml-70 {
  margin-left: -70px;
}

.ml-80 {
  margin-left: -80px;
}

.ml-90 {
  margin-left: -90px;
}

.ml-100 {
  margin-left: -100px;
}

/*MARGIN LEFT RIGHT*/
.mlr1 {
  margin-left: 1px;
  margin-right: 1px;
}

.mlr2 {
  margin-left: 2px;
  margin-right: 2px;
}

.mlr3 {
  margin-left: 3px;
  margin-right: 3px;
}

.mlr4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mlr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mlr6 {
  margin-left: 6px;
  margin-right: 6px;
}

.mlr7 {
  margin-left: 7px;
  margin-right: 7px;
}

.mlr8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mlr9 {
  margin-left: 9px;
  margin-right: 9px;
}

.mlr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mlr11 {
  margin-left: 11px;
  margin-right: 11px;
}

.mlr12 {
  margin-left: 12px;
  margin-right: 12px;
}

.mlr13 {
  margin-left: 13px;
  margin-right: 13px;
}

.mlr14 {
  margin-left: 14px;
  margin-right: 14px;
}

.mlr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mlr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mlr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mlr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mlr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mlr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mlr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mlr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mlr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mlr100 {
  margin-left: 100px;
  margin-right: 100px;
}

/*PADDINGS*/
.p1 {
  padding: 1px;
}

.p2 {
  padding: 2px;
}

.p3 {
  padding: 3px;
}

.p4 {
  padding: 4px;
}

.p5 {
  padding: 5px;
}

.p6 {
  padding: 6px;
}

.p7 {
  padding: 7px;
}

.p8 {
  padding: 8px;
}

.p9 {
  padding: 9px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p40 {
  padding: 40px;
}

.p50 {
  padding: 50px;
}

.p60 {
  padding: 60px;
}

.p70 {
  padding: 70px;
}

.p80 {
  padding: 80px;
}

.p90 {
  padding: 90px;
}

.p100 {
  padding: 100px;
}

/*PADDING LEFT*/
.pl1 {
  padding-left: 1px;
}

.pl2 {
  padding-left: 2px;
}

.pl3 {
  padding-left: 3px;
}

.pl4 {
  padding-left: 4px;
}

.pl5 {
  padding-left: 5px;
}

.pl6 {
  padding-left: 6px;
}

.pl7 {
  padding-left: 7px;
}

.pl8 {
  padding-left: 8px;
}

.pl9 {
  padding-left: 9px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pl50 {
  padding-left: 50px;
}

.pl60 {
  padding-left: 60px;
}

.pl70 {
  padding-left: 70px;
}

.pl80 {
  padding-left: 80px;
}

.pl90 {
  padding-left: 90px;
}

.pl100 {
  padding-left: 100px;
}

/*PADDING RIGHT*/
.pr1 {
  padding-right: 1px;
}

.pr2 {
  padding-right: 2px;
}

.pr3 {
  padding-right: 3px;
}

.pr4 {
  padding-right: 4px;
}

.pr5 {
  padding-right: 5px;
}

.pr6 {
  padding-right: 6px;
}

.pr7 {
  padding-right: 7px;
}

.pr8 {
  padding-right: 8px;
}

.pr9 {
  padding-right: 9px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.pr60 {
  padding-right: 60px;
}

.pr70 {
  padding-right: 70px;
}

.pr80 {
  padding-right: 80px;
}

.pr90 {
  padding-right: 90px;
}

.pr100 {
  padding-right: 100px;
}

/*PADDING LEFT RIGHT*/
.plr1 {
  padding-left: 1px;
  padding-right: 1px;
}

.plr2 {
  padding-left: 2px;
  padding-right: 2px;
}

.plr3 {
  padding-left: 3px;
  padding-right: 3px;
}

.plr4 {
  padding-left: 4px;
  padding-right: 4px;
}

.plr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.plr6 {
  padding-left: 6px;
  padding-right: 6px;
}

.plr7 {
  padding-left: 7px;
  padding-right: 7px;
}

.plr8 {
  padding-left: 8px;
  padding-right: 8px;
}

.plr9 {
  padding-left: 9px;
  padding-right: 9px;
}

.plr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.plr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.plr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.plr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.plr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.plr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.plr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.plr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.plr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.plr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.plr100 {
  padding-left: 100px;
  padding-right: 100px;
}

/*PADDING TOP*/
.pt1 {
  padding-top: 1px;
}

.pt2 {
  padding-top: 2px;
}

.pt3 {
  padding-top: 3px;
}

.pt4 {
  padding-top: 4px;
}

.pt5 {
  padding-top: 5px;
}

.pt6 {
  padding-top: 6px;
}

.pt7 {
  padding-top: 7px;
}

.pt8 {
  padding-top: 8px;
}

.pt9 {
  padding-top: 9px;
}

.pt10 {
  padding-top: 10px;
}

.pt11 {
  padding-top: 11px;
}

.pt12 {
  padding-top: 12px;
}

.pt13 {
  padding-top: 13px;
}

.pt14 {
  padding-top: 14px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt70 {
  padding-top: 70px;
}

.pt80 {
  padding-top: 80px;
}

.pt90 {
  padding-top: 90px;
}

.pt100 {
  padding-top: 100px;
}

.pt180 {
  padding-top: 180px;
}

.pt200 {
  padding-top: 200px;
}

.pt240 {
  padding-top: 240px;
}

/*PADDING BOTTOM*/
.pb1 {
  padding-bottom: 1px;
}

.pb2 {
  padding-bottom: 2px;
}

.pb3 {
  padding-bottom: 3px;
}

.pb4 {
  padding-bottom: 4px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb6 {
  padding-bottom: 6px;
}

.pb7 {
  padding-bottom: 7px;
}

.pb8 {
  padding-bottom: 8px;
}

.pb9 {
  padding-bottom: 9px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb11 {
  padding-bottom: 11px;
}

.pb12 {
  padding-bottom: 12px;
}

.pb13 {
  padding-bottom: 13px;
}

.pb14 {
  padding-bottom: 14px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb70 {
  padding-bottom: 70px;
}

.pb80 {
  padding-bottom: 80px;
}

.pb90 {
  padding-bottom: 90px;
}

.pb100 {
  padding-bottom: 100px;
}

/*PADDING TOP & BOTTOM*/
.ptb1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.ptb2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ptb3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.ptb4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ptb5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ptb6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.ptb7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.ptb8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ptb9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.ptb10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ptb15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.ptb20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ptb30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ptb40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ptb50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ptb60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ptb80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ptb90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.ptb100 {
  padding-top: 100px;
  padding-bottom: 100px;
}


/* 
###############################
            LOGO
###############################
*/

.logo {
  display: block;
  float: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .logo {
    display: block;
    position: static;
    float: left;
    margin-top: 0px;
  }
}

.logo img {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 0;
}

.logo img.dark {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 0;
}

.logo img.white {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 -40px;
}


.logo2 {
  display: block;
  float: left;
}

.logo2 img {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 0;
}

.logo2 img.dark {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 0;
}

.logo2 img.white {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 -40px;
}

.logo3 {
  display: block;
  float: left;
  left: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.logo3 img {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 0px;
}

.logo3 img.white {
  width: 150px;
  height: 40px;
  background: url('../images/logo_white_black.png') 0 -40px;
}

/* 
###############################
        NAVIGATION MENU 
###############################
*/
/*align menu to the right*/

.mainmenu {
  font-family: $fontmenu;
  font-size: $menusize;
  font-weight: $menuweight;
  text-transform: $textcase;
  float: none;
  border-top: 1px solid #efefef;
  max-height: auto;
  padding-right: 0px;
  overflow: hidden;
  z-index: 100;
  position: static;
  background: white;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mainmenu {
    float: left;
    border-top: 1px solid #efefef;
    background: transparent;
    padding-right: 0px;
    position: relative;
    width: 100%;
    top: 10px;
    margin-bottom: 10px;
  }

}

@media (min-width: 992px) {
  .mainmenu {
    float: right;
    border-top: 0px;
    background: transparent;
    padding-right: 145px;
  }
}

.navigation {
  position: absolute;
  width: 100%;
  margin-top: 0px;
  z-index: 800;
  background: white;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation {
    position: absolute;
    width: 100%;
    margin-top: 0px;
    z-index: 800;
    background: white;
  }
}

@media (min-width: 992px) {
  .navigation {
    position: absolute;
    width: 100%;
    margin-top: 60px;
    z-index: 800;
    background: transparent;
  }
}

.shopsearch {
  display: none;
  float: none;
  position: static;
  font-family: $fontmenu;
  font-size: $menusize;
  font-weight: $menuweight;
  text-transform: $textcase;
}

.shopsearch ul.nav {
  background: white;
}

@media (min-width: 768px) {
  .shopsearch {
    display: block;
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .shopsearch ul.nav {
    background: transparent;
  }
}

.topnavbar {
  background: #333;
  color: white;
  width: 100%;
  height: 40px;
  position: absolute;
  padding-top: 10px;
  z-index: 800;
  border-bottom: 1px solid #ebebeb;
}

ul.top-socials {
  li {
    color: #ccc;
    font-size: 16px;

    a i {
      color: #ccc;
      @include transition(.2s);

      &:hover {
        color: #333
      }
    }
  }
}

.top-links {
  color: #ccc;
  font-size: 12px;

  a {
    color: #ccc
  }

  a:hover {
    color: #fff
  }
}

.topnavbar.bgwhite {
  .top-links {
    color: #ccc;
    font-size: 12px;

    a {
      color: #ccc
    }

    a:hover {
      color: $maincolor
    }
  }
}

ul.top-socials.white {
  li {
    color: #ccc;
    font-size: 16px;

    a i {
      color: #ccc;
      @include transition(.2s);

      &:hover {
        color: #fff
      }
    }
  }
}

.top-links.white {
  color: #ccc;
  font-size: 12px;

  a {
    color: #ccc
  }

  a:hover {
    color: #fff
  }
}

/*Style menu for mobile*/
.nav>li {
  padding: 0 0px;
}

.navbar-nav {
  margin: 7.5px 0px;
}

@media (min-width: 992px) {
  .nav>li {
    padding: 0px;
  }

  .nav>li>a {
    padding: $Vpadding $Hpadding;
  }

  .navbar-nav {
    margin: 7.5px -15px;
  }
}

/*Change dropdown toggle*/
.navbar-toggle {
  float: left;
  left: 15px;
  background: white;
  position: relative;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  border: 0px;
  border-right: 1px solid #efefef !important;
  padding: 15px;
  margin: 0px;
}

.navbar-default .navbar-toggle:hover {
  background: white;
}

.navbar-default .navbar-toggle:focus {
  background: white;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #444;
  height: 4px;
}

.navbar-default .navbar-toggle .icon-bar:hover {
  background-color: #222;
}

/*HIDE SHOW BUTTON*/
.hide-show-button {
  position: relative;
  left: 0;
  top: 10px;
  display: block;
  background: transparent;
}

@media (min-width: 768px) {
  .hide-show-button {
    display: block;
  }
}

/*BurgerBAR icon*/
span.burgerbar {
  display: block;
  width: 20px;
  height: 4px;
  margin-bottom: 2px;
  padding: 0;
  background: #333;
}

span.burgerbar.darkblue {
  display: block;
  width: 20px;
  height: 4px;
  margin-bottom: 2px;
  padding: 0;
  background: #555d68;
}

span.burgerbar.white {
  display: block;
  width: 20px;
  height: 4px;
  margin-bottom: 2px;
  padding: 0;
  background: #fff;
}

/*Close Menu link*/
a.closemenu {
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

a.closemenu2 {
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

a.closemenu3 {
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 1);
  opacity: 0;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/*Dots*/
.dots {
  display: block;
  float: right;
  position: absolute;
  right: -15px;
  top: 0px;
  border-left: 1px solid #efefef;
}

.dots a {
  margin: 0px;
  padding: 13px;
  display: block;
}

.dots a span.icon-dots {
  width: 5px;
  height: 5px;
  background: #efefef;
  border-radius: 10px;
  margin: 3px 10px 0 10px;
  padding: 0;
  display: block;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.dots a:hover span.icon-dots {
  background: #ccc;
}

@media (min-width: 768px) {
  .dots {
    display: none;
  }
}


/*Other mobile changes*/
.mobpad {
  margin: 50px 0 60px 50px !important;
}

@media (min-width: 768px) {
  .mobpad {
    margin: 0px 0 0 0px !important;
  }
}

/*Remove navigation ul spacing*/
ul.nav {
  margin: 0px;
  padding: 0px;
}

/*Overwrite bootstrap default menu*/
.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
  background-color: transparent;
  border-color: #428BCA;
}

.navbar {
  margin-bottom: 0px;
  border: 0px;
}

/*hides navbar 20px margin*/
.navbar-default {
  background-color: transparent;
  border-color: none;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #ff6633;
}

/*Active button*/
.nav li a.active {
  background-color: transparent !important;
  color: #ff6633 !important;
  border-bottom: 0px solid #ff6633;
}


/*Megamenu dropdown offset positions and custom dimensions*/

.dropwidth01 {
  width: 100%;
  position: static;
  margin: 0 auto;
  padding: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .dropwidth01 {
    width: 720px;
    position: absolute;
    margin: 0 auto;
    padding: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .dropwidth01 {
    width: 940px;
    position: absolute;
    margin: 0 auto;
    float: left;
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .dropwidth01 {
    width: 1142px;
    position: absolute;
    margin: 0 auto;
    padding: 30px;
  }
}

.dropwidth02 {
  width: 400px;
}

.col4fix {
  width: 25%;
  float: left;
}

.col3fix {
  width: 33%;
  float: left;
}

.col2fix {
  width: 50%;
  float: left;
}


/*2nd dropdown position*/
ul.nav li.dropdown>ul.dropdown-menu li.dropdown>ul.dropdown-menu {
  margin-top: -31px;
  margin-left: calc(100% - 5px);
}

ul.nav li.dropdown>ul.dropdown-menu li.dropdown>ul.dropdown-menu.left {
  margin-top: -31px;
  margin-left: calc(-100% + 50px);
}

ul.nav li.dropdown>ul.dropdown-menu li.dropdown>ul.dropdown-menu.left.img {
  margin-top: -31px;
  margin-left: calc(-100% - 20px);
}

/*Dropdown Arrow*/
ul.nav li.dropdown>ul.dropdown-menu li.dropdown a b.caret2 {
  transform: rotate(0deg);
  position: absolute;
  right: 20px;
  top: 10px;
  display: block;
  width: 4px;
  height: 4px;
  background: url('../images/caret2.png') no-repeat;
}

ul.nav li.dropdown>ul.dropdown-menu li.dropdown:hover a b.caret2 {
  transform: rotate(-180deg);
  top: 11px;
}

/*Caret*/
b.caret {
  border: 0px;
}

/*Dropdown default style */
.navbar-nav>li>.dropdown-menu {
  border: 0px;
  box-shadow: none;
}


/*Makes bootstrap menu drop on hover*/
/*Cancelled because of the js animation*/

/*@media (min-width: 768px) {
    ul.nav li.dropdown > ul.dropdown-menu {
        display: none;  
        margin-top:0px;
        opacity:0;
        -webkit-transition:.5s;-moz-transition:.5s;transition:.5s;
        
    }

    ul.nav li.dropdown:hover > ul.dropdown-menu {
        display: block;  
        margin-top:0px; 
        opacity:1;
        max-width:1170px;
        max-height:500px;
        -webkit-transition:.5s;-moz-transition:.5s;transition:.5s;
    }
}
*/


/* 
#########################
      DEFAULT MENU
#########################
*/
/*Main navigation default colors*/
.navbar-collapse>.nav>li>a:hover,
.navbar-collapse>.nav>li>a {
  color: #666;
  font-size: 16px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navbar-collapse>.nav>li>a:hover,
.navbar-collapse>.nav>li>a:focus {
  color: $maincolor;
  background-color: transparent;
}


/*Navigation background when small*/
.navbg {
  background: #ffffff !important;
  border-bottom: 1px solid #ddd !important;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .navbg {
    background: #ffffff !important;
    border-bottom: 1px solid #ddd !important;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  }
}

/* 
#########################
       WHITE MENU
#########################
*/

/*Main navigation colors*/
.navigation.white>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
  color: #222;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.white>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
  color: $maincolor;
  background-color: transparent;
}

@media (min-width: 992px) {
  .navigation.white>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
    color: #fff;
    font-size: $menusize;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: .2s;
  }

  .navigation.white>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
    color: $maincolor;
    background-color: transparent;
  }
}

.navigation.navbg.white>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
  color: #222;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.navbg.white>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
  color: $maincolor;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.navbg.white {
  background: #fff !important;
  border-bottom: 1px solid #fff;
}

.navigation.white>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li.dropdown>ul.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176) !important;
}

li.basket-white>ul.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176) !important;
}

/* 
######################### 
       DARK MENU
#########################
*/
/*Main navigation colors*/
.navigation.dark>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
  color: #222;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.dark>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
  color: $maincolor;
  background-color: transparent;
}

@media (min-width: 992px) {
  .navigation.dark>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
    color: #555;
    font-size: $menusize;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: .2s;
  }

  .navigation.dark>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
    color: $maincolor;
    background-color: transparent;
  }
}

.navigation.navbg.dark>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
  color: #222;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.navbg.dark>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
  color: $maincolor;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.navbg.dark {
  background: #fff !important;
  border-bottom: 1px solid #fff;
}

/* 
#########################
    DARK-CHEALK MENU
#########################
*/
.navigation.darkchealk {
  background: #222;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation.darkchealk {
    background: #222;
  }
}

@media (min-width: 992px) {
  .navigation.darkchealk {
    background: transparent;
  }
}

.navigation.darkchealk {
  .navbar-toggle {
    background: #222;
    border-right: 1px solid #333 !important;
    @include transition(.2s);
  }

  .navbar-toggle:hover {
    background: #333;
  }

  .navbar-toggle:focus {
    background: #333;
  }

  .navbar-toggle .icon-bar {
    background-color: #666;
    height: 4px;
    @include transition(.2s);
  }

  .navbar-toggle:hover .icon-bar {
    background-color: #999;
  }

  .dots {
    border-left: 1px solid #333;
  }

  .dots a span.icon-dots {
    background: #999;
  }

  .dots a:hover span.icon-dots {
    background: #ccc;
  }

  .navbar-collapse {
    border-color: #333;
  }
}

/*Main navigation colors*/
.navigation.darkchealk>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
  color: #222;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.darkchealk>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
  color: #c1bf94;
  background-color: transparent;
}

@media (min-width: 768px) {
  .navigation.darkchealk>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
    color: #c1bf94;
    font-size: $menusize;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: .2s;
  }

  .navigation.darkchealk>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
    color: #fff;
    background-color: transparent;
  }
}

.navigation.navbg.darkchealk>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a {
  color: #c1bf94;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.navbg.darkchealk>.container>.navbar>.container-fluid>.navbar-collapse>ul.nav>li>a:hover {
  color: #fff;
  font-size: $menusize;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.navigation.navbg.darkchealk {
  background: #222 !important;
  border-bottom: 1px solid #333 !important;
}

/* 
#########################
    DROPDOWN WHITE
#########################
*/
/*Dropdown BG color*/
div.white-menu ul.nav li.dropdown ul.dropdown-menu {
  background: #fff;
}

/*Dropdown links color*/
div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li a {
  color: #222;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover a {
  color: #ff6633;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li {
  background: transparent;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover {
  background: transparent;
}

/*3rd level dropdown background color*/
div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row li.submenu ul.dropdown-menu {
  background: #fff;
}

/*3rd level submenu color*/
div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row li.submenu ul.dropdown-menu li a {
  color: #222;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row li.submenu ul.dropdown-menu li:hover a {
  color: #ff6633;
  background: transparent;
}

/*Dropdown column lines and boolets*/
div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist {
  list-style: square url("../images/sqgrey.png");
  list-style-position: inside;
  border-right: 1px solid #efefef;
}

/*Dropdown multiple columns styles*/
div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li {
  line-height: 30px;
  border-bottom: 0px solid #efefef;
  padding: 0px 20px;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist ul.dropdown-menu li {
  line-height: 30px;
  border-bottom: 0px solid #efefef;
  padding: 0px 20px;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist ul.dropdown-menu li.dropdown-header {
  padding: 0px 20px;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist ul.dropdown-menu li.gallery {
  padding: 0px 0px 5px 0px;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li.dropdown-header {
  padding: 0px 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown-header {
  text-transform: uppercase;
  font-size: 10px;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li.dropdown-title {
  font-family: "Open Sans";
  font-size: 14px;
  color: #cccccc;
  padding: 0px 20px;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover.dropdown-title {
  background: #fff;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li.inactive a {
  color: #999;
  cursor: default;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover.inactive {
  background: #fff;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.noline {
  border: 0px;
}

/*Dropdown single column styles*/
div.white-menu ul.nav li.dropdown ul.dropdown-menu li.divider {
  background-color: #efefef;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu li a:hover {
  background: transparent;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu li a {
  color: #222;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu li:hover a {
  color: #ff6633;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu {
  background: #fff;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu li a {
  color: #222;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.white-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu li:hover a {
  color: #ff6633;
}


/* 
#########################
    DROPDOWN DARK
#########################
*/

/*Dropdown BG color*/
div.dark-menu ul.nav li.dropdown ul.dropdown-menu {
  background: #222;
  border-radius: 2px;
}

/*Dropdown links color*/
div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li a {
  color: #666;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover a {
  color: #ffffff;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li {
  background: transparent;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover {
  background: transparent;
}

/*3rd level dropdown background color*/
div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row li.submenu ul.dropdown-menu {
  background: #333;
  border: 0;
}

/*3rd level submenu color*/
div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row li.submenu ul.dropdown-menu li a {
  color: #666;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row li.submenu ul.dropdown-menu li:hover a {
  color: #ffffff;
  background: transparent;
}

/*Dropdown column lines and boolets*/
div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist {
  list-style: square url("../images/sqgrey.png");
  list-style-position: inside;
  border-right: 1px solid #333;
}

/*Dropdown multiple columns styles*/
div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li {
  line-height: 30px;
  border-bottom: 0px solid #efefef;
  padding: 0px 20px;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist ul.dropdown-menu li {
  line-height: 30px;
  border-bottom: 0px solid #efefef;
  padding: 0px 20px;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist ul.dropdown-menu li.dropdown-header {
  padding: 0px 20px;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist ul.dropdown-menu li.gallery {
  padding: 0px 0px 5px 0px;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li.dropdown-header {
  padding: 0px 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown-header {
  text-transform: uppercase;
  font-size: 10px;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li.dropdown-title {
  font-family: "Open Sans";
  font-size: 14px;
  color: #cccccc;
  padding: 0px 20px;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover.dropdown-title {
  background: #fff;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li.inactive a {
  color: #999;
  cursor: default;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.droplist li:hover.inactive {
  background: #fff;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu div.row ul.noline {
  border: 0px;
}

/*Dropdown single column styles*/
div.dark-menu ul.nav li.dropdown ul.dropdown-menu li.divider {
  background-color: #333;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu li a:hover {
  background: transparent;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu li a {
  color: #666;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu li:hover a {
  color: #ffffff;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu {
  background: #333;
  border: 0;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu li a {
  color: #666;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

div.dark-menu ul.nav li.dropdown ul.dropdown-menu li.dropdown ul.dropdown-menu li:hover a {
  color: #ffffff;
}



/* 
#########################
  Shopping Basket White
#########################
*/
li.basket-white ul.dropdown-menu {
  width: 250px;
  background: white;
  padding: 0px 20px 20px 20px;
  border-radius: 0px;
  color: #666;
}

li.basket-white a span.active {
  background: $maincolor;
  color: #fff;
  border: 0px solid #efefef;
}

li.basket-white a span {
  background: #FFFFFF;
  width: 20px;
  height: 20px;
  color: #222;
  display: block;
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid #ddd;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

li.basket-white span.cartip {
  width: 27px;
  height: 14px;
  background: url('../images/cartip.png') no-repeat;
  display: block;
  float: right;
  margin-top: -14px;
}

li.basket-white div.cartimage {
  width: 60px;
  height: 60px;
  background: #e2e2e2;
  margin-right: 10px;
}

li.basket-white div.cartimage img {
  width: 60px;
}

li.basket-white p.price {
  float: right;
  font-size: 11px;
  font-weight: bold;
}

li.basket-white p.quantity {
  color: #999;
}

li.basket-white a.title {
  display: inline;
  color: #222;
  white-space: normal;
  padding: 0px;
  background: transparent;
  padding: 2px;
}

li.basket-white a.title:hover {
  color: #222;
  text-decoration: underline;
}

li.basket-white .totalprice {
  font-weight: bold;
  color: #f63;
  font-size: 15px;
}

li.basket-white ul li {
  border-bottom: 1px dotted #efefef;
  padding-top: 20px;
  padding-bottom: 10px;
}

li.basket-white .cartbtn {
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
  padding: 10px 10px;
  font-size: 14px;
}

li.basket-white .cartbtn:hover {
  background: #fff;
  border: 1px solid #adadad;
}


/* 
#########################
  Shopping Basket Dark
#########################
*/
li.basket-dark ul.dropdown-menu {
  width: 250px;
  background: #222;
  padding: 0px 20px 20px 20px;
  border-radius: 0px;
  color: #666;
}

li.basket-dark a span.active {
  background: $maincolor;
  color: #fff;
  border: 0px solid #efefef;
}

li.basket-dark a span {
  background: #FFFFFF;
  width: 20px;
  height: 20px;
  color: #222;
  display: block;
  border-radius: 100%;
  text-align: center;
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid #ddd;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

li.basket-dark span.cartip {
  width: 27px;
  height: 14px;
  background: url('../images/cartip2.png') no-repeat;
  display: block;
  float: right;
  margin-top: -14px;
}

li.basket-dark div.cartimage {
  width: 60px;
  height: 60px;
  background: #e2e2e2;
  margin-right: 10px;
}

li.basket-dark div.cartimage img {
  width: 60px;
}

li.basket-dark p.price {
  float: right;
  font-size: 11px;
  font-weight: bold;
}

li.basket-dark p.quantity {
  color: #999;
}

li.basket-dark a.title {
  display: inline;
  color: #ccc;
  white-space: normal;
  padding: 0px;
  background: transparent;
  padding: 2px;
}

li.basket-dark a.title:hover {
  color: #fff;
  text-decoration: none;
}

li.basket-dark .totalprice {
  font-weight: bold;
  color: #fff;
  font-size: 15px;
}

li.basket-dark ul li {
  border-bottom: 1px dotted #444;
  padding-top: 20px;
  padding-bottom: 10px;
}

li.basket-dark .cartbtn {
  background: #222;
  color: #666;
  border: 1px solid #333;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
  padding: 10px 10px;
  font-size: 14px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

li.basket-dark .cartbtn:hover {
  background: #222;
  color: #fff;
  border: 1px solid #fff;
}

li.basket-dark button.close {
  color: #666;
  text-shadow: none;
  opacity: 1;
}

li.basket-dark button.close:hover {
  color: #fff;
  text-shadow: none;
  opacity: 1;
}




/* 
#########################
  NAVIGATION 2
#########################
*/
ul.navbar-nav2 {
  li.panel {
    background: transparent;
  }

  li {
    margin-bottom: 20px;

    a {
      color: #999;
      font-size: 26px;
      text-transform: uppercase;
      @include transition(.2s);

      &:hover {
        color: #f96;
      }

      &:focus {
        color: #f96;
        text-decoration: none;

        p.rotate {
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-left: 20px;
          padding-left: 4px;
          line-height: 20x;
          @include rotate(45deg);
        }
      }

      p.rotate {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-left: 20px;
        padding-left: 4px;
        line-height: 20px;
        @include rotate(0deg);
        @include transition(.2s);
      }

    }

    ul li a {
      color: #666;
      font-size: 20px !important;
      text-transform: lowercase;
    }
  }

}



/* SECTIONS - DIVS - CONTAINERS */
.slider {
  width: 100%;
  max-height: 900px;
  background: lightgrey;
  overflow: hidden;
  padding-top: 0px;
}

/* SLIDER CAPTIONS */
.caption-grey {
  font-family: "Open Sans";
  font-size: 60px;
  color: #999;
  font-weight: 300;
}

.caption-white {
  font-family: "Open Sans";
  font-size: 60px;
  color: #fff;
  font-weight: 300;
}

.caption-smallgrey {
  font-family: "Open Sans";
  font-size: 36px;
  color: #999;
  font-weight: 300;
}

.caption-light {
  font-family: "Open Sans";
  font-size: 60px;
  color: #fff;
  font-weight: 300;
}

.caption-dark {
  font-family: "Open Sans";
  font-size: 60px;
  color: #333;
  font-weight: 300;
}

.caption-dark-14 {
  font-family: "Open Sans";
  font-size: 14px;
  color: #333;
  font-weight: 300;
}

.caption-dark-14bold {
  font-family: "Open Sans";
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.caption-dark-16 {
  font-family: "Open Sans";
  font-size: 16px;
  color: #333;
  font-weight: 300;
}

.caption-dark-16bold {
  font-family: "Open Sans";
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.caption-dark-18 {
  font-family: "Open Sans";
  font-size: 18px;
  color: #333;
  font-weight: 300;
}

.caption-dark-18bold {
  font-family: "Open Sans";
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.caption-dark-24 {
  font-family: "Open Sans";
  font-size: 24px;
  color: #333;
  font-weight: 300;
}

.caption-dark-24bold {
  font-family: "Open Sans";
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.caption-dark-30 {
  font-family: "Open Sans";
  font-size: 30px;
  color: #333;
  font-weight: 300;
}

.caption-dark-30bold {
  font-family: "Open Sans";
  font-size: 30px;
  color: #333;
  font-weight: bold;
}

.caption-dark-32 {
  font-family: "Open Sans";
  font-size: 32px;
  color: #333;
  font-weight: 300;
}

.caption-dark-32bold {
  font-family: "Open Sans";
  font-size: 32px;
  color: #333;
  font-weight: bold;
}

.caption-dark-34 {
  font-family: "Open Sans";
  font-size: 34px;
  color: #333;
  font-weight: 300;
}

.caption-dark-34bold {
  font-family: "Open Sans";
  font-size: 34px;
  color: #333;
  font-weight: bold;
}

.caption-dark-36 {
  font-family: "Open Sans";
  font-size: 36px;
  color: #333;
  font-weight: 300;
}

.caption-dark-36bold {
  font-family: "Open Sans";
  font-size: 36px;
  color: #333;
  font-weight: bold;
}

.caption-dark-38 {
  font-family: "Open Sans";
  font-size: 38px;
  color: #333;
  font-weight: 300;
}

.caption-dark-38bold {
  font-family: "Open Sans";
  font-size: 38px;
  color: #333;
  font-weight: bold;
}

.caption-dark-40 {
  font-family: "Open Sans";
  font-size: 40px;
  color: #333;
  font-weight: 300;
}

.caption-dark-40bold {
  font-family: "Open Sans";
  font-size: 40px;
  color: #333;
  font-weight: bold;
}

.caption-dark-50 {
  font-family: "Open Sans";
  font-size: 50px;
  color: #333;
  font-weight: 300;
}

.caption-dark-50bold {
  font-family: "Open Sans";
  font-size: 50px;
  color: #333;
  font-weight: bold;
}

.caption-dark-56 {
  font-family: "Open Sans";
  font-size: 56px;
  color: #333;
  font-weight: 300;
}

.caption-dark-56bold {
  font-family: "Open Sans";
  font-size: 56px;
  color: #333;
  font-weight: bold;
}

.caption-white-14 {
  font-family: "Open Sans";
  font-size: 14px;
  color: #fff;
  font-weight: 300;
}

.caption-white-14bold {
  font-family: "Open Sans";
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}

.caption-white-16 {
  font-family: "Open Sans";
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}

.caption-white-16bold {
  font-family: "Open Sans";
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.caption-white-18 {
  font-family: "Open Sans";
  font-size: 18px;
  color: #fff;
  font-weight: 300;
}

.caption-white-18bold {
  font-family: "Open Sans";
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.caption-white-24 {
  font-family: "Open Sans";
  font-size: 24px;
  color: #fff;
  font-weight: 300;
}

.caption-white-24bold {
  font-family: "Open Sans";
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

.caption-white-30 {
  font-family: "Open Sans";
  font-size: 30px;
  color: #fff;
  font-weight: 300;
}

.caption-white-30bold {
  font-family: "Open Sans";
  font-size: 30px;
  color: #fff;
  font-weight: bold;
}

.caption-white-32 {
  font-family: "Open Sans";
  font-size: 32px;
  color: #fff;
  font-weight: 300;
}

.caption-white-32bold {
  font-family: "Open Sans";
  font-size: 32px;
  color: #fff;
  font-weight: bold;
}

.caption-white-34 {
  font-family: "Open Sans";
  font-size: 34px;
  color: #fff;
  font-weight: 300;
}

.caption-white-34bold {
  font-family: "Open Sans";
  font-size: 34px;
  color: #fff;
  font-weight: bold;
}

.caption-white-36 {
  font-family: "Open Sans";
  font-size: 36px;
  color: #fff;
  font-weight: 300;
}

.caption-white-36bold {
  font-family: "Open Sans";
  font-size: 36px;
  color: #fff;
  font-weight: bold;
}

.caption-white-38 {
  font-family: "Open Sans";
  font-size: 38px;
  color: #fff;
  font-weight: 300;
}

.caption-white-38bold {
  font-family: "Open Sans";
  font-size: 38px;
  color: #fff;
  font-weight: bold;
}

.caption-white-40 {
  font-family: "Open Sans";
  font-size: 40px;
  color: #fff;
  font-weight: 300;
}

.caption-white-40bold {
  font-family: "Open Sans";
  font-size: 40px;
  color: #fff;
  font-weight: bold;
}

.caption-white-50 {
  font-family: "Open Sans";
  font-size: 50px;
  color: #fff;
  font-weight: 300;
}

.caption-white-50bold {
  font-family: "Open Sans";
  font-size: 50px;
  color: #fff;
  font-weight: bold;
}

.caption-white-56 {
  font-family: "Open Sans";
  font-size: 56px;
  color: #fff;
  font-weight: 300;
}

.caption-white-56bold {
  font-family: "Open Sans";
  font-size: 56px;
  color: #fff;
  font-weight: bold;
}


.caption-white-bigbold {
  font-family: "proxima_novaextrabold";
  font-size: 80px;
  color: #fff;
  font-weight: 800;
}

.caption-white-hugebold {
  font-family: "proxima_novaextrabold";
  font-size: 110px;
  color: #fff;
  font-weight: 800;
}

.caption-dark-bigbold {
  font-family: "proxima_novaextrabold";
  font-size: 80px;
  color: #333;
  font-weight: 800;
}

.caption-dark-hugebold {
  font-family: "proxima_novaextrabold";
  font-size: 110px;
  color: #333;
  font-weight: 800;
}

.caption-black-bigbold {
  font-family: "proxima_novaextrabold";
  font-size: 80px;
  color: #000;
  font-weight: 800;
}

.caption-black-hugebold {
  font-family: "proxima_novaextrabold";
  font-size: 110px;
  color: #000;
  font-weight: 800;
}

.caption-white-bigbold-caps {
  font-family: "proxima_novaextrabold";
  font-size: 80px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
}

.caption-white-hugebold-caps {
  font-family: "proxima_novaextrabold";
  font-size: 110px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
}

.caption-dark-bigbold-caps {
  font-family: "proxima_novaextrabold";
  font-size: 80px;
  color: #333;
  font-weight: 800;
  text-transform: uppercase;
}

.caption-dark-hugebold-caps {
  font-family: "proxima_novaextrabold";
  font-size: 110px;
  color: #333;
  font-weight: 800;
  text-transform: uppercase;
}

.caption-orange-medium {
  font-family: "Open Sans";
  font-size: 30px;
  color: #ff6633;
  font-weight: 300;
}

.caption-grey-medium {
  font-family: "Open Sans";
  font-size: 30px;
  color: #666;
  font-weight: 300;
}

.caption-lightgrey-medium {
  font-family: "Open Sans";
  font-size: 30px;
  color: #999;
  font-weight: 300;
}

.caption-lightyellow-medium {
  font-family: "Open Sans";
  font-size: 30px;
  color: #ffffcc;
  font-weight: 300;
}

.caption-small-14 {
  font-family: "Open Sans";
  font-size: 14px;
  color: #999;
  font-weight: 300;
}

.caption-small-lightyellow-14 {
  font-family: "Open Sans";
  font-size: 14px;
  color: #ffffcc;
  font-weight: 300;
}

.caption-lightyellow-80 {
  font-family: "proxima_novaextrabold";
  font-size: 80px;
  color: #c1bf94;
  text-transform: uppercase;
}

.caption-lightyellow-23 {
  font-family: "Open Sans";
  font-size: 22px;
  color: #c1bf94;
}

.caption-white-hugeslim {
  font-family: "proxima_novalight";
  font-size: 127px;
  color: #fff;
  font-weight: normal;
}

.caption-white-24slim {
  font-family: "proxima_novalight";
  font-size: 24px;
  color: #ff6633;
  font-weight: normal;
}

.tp-caption.whitedivider2px {
  background-color: #c1bf94;
  border-color: #c1bf94;
  border-style: none;
  border-width: 0;
  color: #000000;
  font-size: 0;
  line-height: 0;
  min-height: 2px;
  min-width: 360px;
  text-decoration: none;
  text-shadow: none;
}

.caption-orange-24 {
  font-family: "Open Sans";
  font-size: 24px;
  color: #ff9966;
  font-weight: 800;
  text-transform: uppercase;
}



#woodsection {
  background: url('../images/section_bg_1.jpg') #666 50% 0px repeat-y fixed;
  background-size: cover;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.0);
}

#wallsection {
  background: url('../images/bg2.jpg') #666 50% 0px repeat-y fixed;
  background-size: cover;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.0);
}

#woodsection-c {
  background: #222222;
  height: 500px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  overflow: hidden;

  .container {
    padding-top: 50px;
    padding-bottom: 80px;
    position: relative;
    z-index: 100;
  }

  .prlx-woodsection {
    background: url('../images/section_bg_1b.jpg') #666 50% 0px no-repeat;
    height: 1050px;
    width: 100%;
    position: absolute;
    top: 0px;
    background-size: cover;
  }
}




.wleft {
  float: left;
  margin-top: 10px;
  margin-left: 20px;
}

.wright {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.pfover img {
  width: 100%;
  margin-left: 0%;
  margin-top: 0px;
  @include transition(.5s);
}

.pfover:hover img {
  width: 110%;
  margin-left: -5%;
  margin-top: -5%;
  opacity: 0.8;
}

.pfover {
  overflow: hidden;
  position: relative;
}

a.circlebig2 {
  width: 68px;
  height: 68px;
  background: #333;
  color: #fff;
  border-radius: 100%;
  border: 0px solid #ccc;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.0);
  padding: 21px;
  font-size: 16px;
  position: absolute;
  opacity: 0;
  z-index: 100;
}

a.circlebig2:hover {
  background: $maincolor;
  color: #fff;
  opacity: 1 !important;
}

// Animation if Javascript is disabled
// a.circlebig2{width:29px; height:29px; background:#333; color:#fff; border-radius:100%; border:0px solid #ccc; box-shadow: 0px 0px 3px rgba(0,0,0,0.0); font-size:16px; padding:4px; margin:0 auto; position:absolute; left:0px; right:0px; top:90px; opacity:0; z-index:1000; }
// .pfover:hover a.circlebig2{background:#333; color:#fff; opacity:0.8; width:68px; height:68px; padding:23px; top:71px; }

/* Themify icon containers for shorcodes page*/
.shorcodesicons {
  font-size: 18px;
  max-width: 100%;
  margin: 30px auto 0;

  h3 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.icon-section {
  margin: 0 0 3em;
  clear: both;
  overflow: hidden;
}

.icon-container {
  width: 33%;
  padding: .7em 0;
  float: left;
  position: relative;
  text-align: left;
}

.icon-container [class^="ti-"],
.icon-container [class*=" ti-"] {
  color: #000;
  position: absolute;
  margin-top: 3px;
  transition: .3s;
}

.icon-container:hover [class^="ti-"],
.icon-container:hover [class*=" ti-"] {
  font-size: 2.2em;
  margin-top: -5px;
}

.icon-container:hover .icon-name {
  color: #000;
}

.icon-name {
  color: #aaa;
  margin-left: 35px;
  font-size: .8em;
  transition: .3s;
}

.icon-container:hover .icon-name {
  margin-left: 45px;
}


/* TEAM PARALLAX */
#teamparallax {
  background: url('../images/team.jpg') #666 50% 0px no-repeat fixed;
  background-size: cover;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.0);
  height: 480px;
}

/* FACTS SECTION */
#sectionfacts {
  background: url('../images/bg1.jpg') #1c1211 50% 0px repeat-y fixed;
  background-size: cover;
  min-height: 480px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.0);
}

#sectionfacts-c {
  background: #222222;
  margin: 0 auto;
  width: 100%;
  height: 50%;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.0);
  position: relative;
  z-index: 99;

  .container {
    position: relative;
    z-index: 100;
  }

  .prlx-sectionfacts {
    background: url('../images/bg1.jpg') #1c1211 50% -300px no-repeat;
    height: 1200px;
    width: 100%;
    position: absolute;
    top: 0px;
    background-size: cover;
  }
}



.factscircle {
  width: 90px;
  height: 90px;
  background: #fff;
  border-radius: 100%;
  padding-top: 28px;
}

.factscircle i {
  font-size: 24px;
}

.factscircle2 {
  width: 90px;
  height: 90px;
  background: transparent;
  border: 3px solid white;
  border-radius: 100%;
  padding-top: 28px;
}

.factscircle2 i {
  font-size: 24px;
  color: white;
}


#sectionaddress {
  background: url('../images/section_bg_3.jpg') #222222 50% 0px repeat-y fixed;
  background-size: cover;
  height: 490px;
  margin: 0 auto;
  width: 100%;
  color: #fff;
}

#sectionaddress-c {
  background: #222222;
  height: 490px;
  margin: 0 auto;
  width: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.prlx-address {
  background: url('../images/section_bg_3.jpg') #222222 100% 0px no-repeat;
  height: 763px;
  width: 100%;
  position: absolute;
  top: 0px;
  background-size: cover;
}


#teamparallax-c {
  // background:#222222; 
  height: 490px;
  margin: 0 auto;
  width: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.prlx-team {
  // background: url('../images/team.jpg') #222222 50% 0px no-repeat; 
  background: url('../images/team.jpg') top center;
  background-size: cover;
  height: 1246px;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
}


.sectionaddress2 {
  background: url('../images/section_bg_3.jpg') #222222 50% 0px no-repeat;
  background-size: cover;
  height: 600px;
  margin: 0 auto;
  width: 100%;
  color: #fff;
}



/* FOOTER */
.footer {
  background: #222;
  color: #999999;

  p {
    color: #999999;
  }

  h5 {
    color: #fff
  }

  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style-type: none;
      border-bottom: 1px solid rgba(white, 0.05);
      padding: 10px 0px;

      a {
        color: #999999;

        &:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }

  input.newsletter {
    height: 42px;
    width: 95%;
    border: 0px;
    border-radius: 0px;
  }

  button.newsletterbtn {
    height: 42px;
    background-color: #999;
    color: #fff;
    border-radius: 0px;
    border: 0px;
    position: relative;
    top: -42px;
    float: right;
    font-size: 16px;
    @include transition(.2s);
  }

  button.newsletterbtn:hover {
    background-color: $maincolor;
  }

  .col-md-3 {
    padding-top: 50px;
  }

  @media (min-width: $screen-md-min) {
    .col-md-3 {
      padding-top: 0px;
    }
  }
}

.footer.white {
  background: #fff;
  color: #999999;

  p {
    color: #999999;
  }

  h5 {
    color: #222
  }

  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style-type: none;
      border-bottom: 1px solid rgba(black, 0.05);
      padding: 10px 0px;

      a {
        color: #999999;

        &:hover {
          color: $maincolor;
          text-decoration: none;
        }
      }
    }
  }

  div.separator100dark {
    background: #efefef;
  }

  input.newsletter {
    height: 42px;
    border-radius: 0px;
    border: 0px;
    background: #efefef;
  }

  button.newsletterbtn {
    height: 42px;
    background-color: $maincolor;
    color: #fff;
    border-radius: 0px;
    border: 0px;
    position: elative;
    top: -42px;
    float: right;
    font-size: 16px;
  }
}

.footer.fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
}

/* FOOTER TYPE 2 WHITE*/
.footerwhite {
  background: white;
  color: #666;

  .container {

    h3 {
      color: #666;
      font-size: 16px;
      text-transform: uppercase;
      @include margin(left, 20px);
    }

    @media (min-width: 768px) {
      h3 {
        @include margin(left, 0px);
      }
    }

    div {
      margin-top: 20px;
    }

    div.line {
      @include border(left, 1px, #efefef);
      padding-left: 70px;
    }

    @media (max-width: 992px) {
      div.line {
        @include border(left, 0px, #efefef);
        padding-left: 0px;
      }
    }

    ul li {
      @include margin(top-bottom, 10px);
      @include margin(left-right, 20px);
      @include border(bottom, 1px, #efefef);

      @media (min-width: 768px) {
        @include border(bottom, 0px, #efefef);
        @include margin(left-right, 0px);
      }

      a {
        color: $linkcolor;
        @include transition(.2s);

        &:hover {
          color: $linkhovercolor;
        }
      }
    }

    p.copyr {
      color: #ccc;
      margin-top: 30px;
      text-align: center;
    }

    a {
      color: #999;
      @include transition(.2s);

      &:hover {
        color: #f96;
      }
    }
  }
}

/* FOOTER TYPE 2 DARK*/
.footerdark {
  background: #000;
  color: #666;

  .container {
    h3 {
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      @include margin(left-right, 20px);
    }

    @media (min-width: 768px) {
      h3 {
        @include margin(left-right, 0px);
      }
    }

    div {
      margin-top: 20px;
    }

    div.line {
      @include border(left, 1px, #222);
      padding-left: 70px;
    }

    @media (max-width: 992px) {
      div.line {
        @include border(left, 0px, #efefef);
        padding-left: 0px;
      }
    }

    ul li {
      @include margin(top-bottom, 10px);
      @include margin(left-right, 20px);
      @include border(bottom, 1px, #444);

      @media (min-width: 768px) {
        @include border(bottom, 0px, #efefef);
        @include margin(left-right, 0px);
      }

      a {
        color: #999;
        @include transition(.2s);

        &:hover {
          color: #f96;
        }
      }
    }

    p.copyr {
      margin-top: 30px;
      text-align: center;
    }
  }

  a {
    color: #999;
    @include transition(.2s);

    &:hover {
      color: #f96;
    }
  }
}

.footerdark.transparent {
  background: transparent;
  color: #666;

  .container {
    h3 {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      @include margin(left-right, 20px);
    }

    @media (min-width: 768px) {
      h3 {
        @include margin(left-right, 0px);
      }
    }

    div {
      margin-top: 20px;
    }

    div.line {
      @include border(left, 0px, #444);
      padding-left: 70px;
    }

    @media (max-width: 992px) {
      div.line {
        @include border(left, 0px, #efefef);
        padding-left: 0px;
      }
    }

    ul li {
      @include margin(top-bottom, 10px);
      @include margin(left-right, 20px);
      @include border(bottom, 0px, #444);

      @media (min-width: 768px) {
        @include border(bottom, 0px, #efefef);
        @include margin(left-right, 0px);
      }

      a {
        color: #999;
        @include transition(.2s);

        &:hover {
          color: #f96;
        }
      }
    }

    p.copyr {
      margin-top: 30px;
      text-align: center;
    }
  }

  a {
    color: #999;
    @include transition(.2s);

    &:hover {
      color: #f96;
    }
  }
}

ul.footer-socials {
  li {
    width: 35px;
    height: 35px;
    background: grey;
    color: #ccc;
    font-size: 16px;
    border-radius: 50%;
    padding: 7px 6px;

    &.blue {
      @include transition(.2s);

      &:hover {
        background: #3c5a99;
      }
    }

    &.blue2 {
      @include transition(.2s);

      &:hover {
        background: #2d9ad2;
      }
    }

    &.red {
      @include transition(.2s);

      &:hover {
        background: #cc2028;
      }
    }

    &.pink {
      @include transition(.2s);

      &:hover {
        background: #cc6699;
      }
    }

    a i {
      color: #fff;
      @include transition(.2s);
    }
  }
}

.newsletter-ani {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 100000;
  overflow: hidden;

  .circle-obj {
    display: none;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 25%;
    background: black;
    background: rgba(black, 0.7);
    position: absolute;
    transform: translateY(-50%);
    -ms-transform: scale(30, 30);
    -webkit-transform: scale(30, 30);
    transform: scale(30, 30);
  }

  .circle-obj2 {
    display: block;
    opacity: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 15px 0 0 20px;
    color: white;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 30%;
    background: #1abc9c;
    position: absolute;
    transform: translateY(-50%);
    overflow: hidden;

    span {
      width: 50px;
      height: 50px;
      display: block;
      font-size: 14px;
      -ms-transform: scale(2, 2);
      -webkit-transform: scale(2, 2);
      transform: scale(2, 2);
      transform-origin: -35% -25%;
    }
  }


  .circle-obj3 {
    opacity: 0;
    display: block;
    width: 320px;
    height: 50px;
    text-align: center;
    font-size: 30px;
    color: #fff;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: 30%;
    position: absolute;
    transform: translateY(-50%);
    overflow: hidden;
  }

}


.newsblock {
  input.newsletter {
    height: 50px;
    width: 100%;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
  }

  input.newsletter:focus {
    box-shadow: none;
  }

  button.newsletterbtn {
    height: 50px;
    background-color: #999;
    color: #fff;
    border-radius: 0px;
    border: 0px;
    position: relative;
    top: -50px;
    float: right;
    font-size: 14px;
    text-transform: uppercase;
    @include transition(.2s);
  }

  button.newsletterbtn:hover {
    background-color: $maincolor;
  }
}




/* COLUMN WITH LINES */
ul.squares {
  margin: 0;
  padding: 0;

  li:first-child {
    border-left: 0px;

    @media (min-width: $screen-md-min) {
      border-left: 1px solid rgba(0, 0, 0, 0.07);
    }
  }

  li {
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);

    @media (min-width: $screen-md-min) {
      border-right: 1px solid rgba(0, 0, 0, 0.07);
      border-bottom: 0px;
    }

    @include padding(top-bottom, 60px);
    @include transition(.5s);

    i {
      color: #666;
      @include transition(.5s);
    }

    h4 {
      color: #999;
      margin-bottom: 10px;
      margin-top: 20px;
      @include transition(.5s);
    }

    a {
      color: #333;
      @include transition(.5s);

      &:focus {
        text-decoration: none;
      }
    }

    &:hover {
      // box-shadow: inset 0px 0px 35px rgba(0,0,0,0.1);
      cursor: pointer;

      i {
        color: $maincolor;
      }

      a {
        color: #999;
      }

      h4 {
        color: #333;
      }
    }
  }
}

ul.squares.dark {
  margin: 0;
  padding: 0;

  li:first-child {
    border-left: 0px;

    @media (min-width: $screen-md-min) {
      border-left: 1px solid rgba(255, 255, 255, 0.05);
    }
  }

  li {
    border-right: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    @media (min-width: $screen-md-min) {
      border-right: 1px solid rgba(255, 255, 255, 0.05);
      border-bottom: 0px;
    }

    @include padding(top-bottom, 60px);
    @include transition(.5s);

    i {
      color: #999;
      @include transition(.5s);
    }

    h4 {
      color: #999;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    a {
      color: #999;
      @include transition(.5s);

      &:focus {
        text-decoration: none;
      }
    }

    &:hover {
      // box-shadow: inset 0px 0px 35px rgba(0,0,0,0.1);
      cursor: pointer;

      i {
        color: $maincolor;
      }

      a {
        color: #999;
      }

      h4 {
        color: #fff;
      }

    }
  }
}

ul.squares.white {
  margin: 0;
  padding: 0;

  li:first-child {
    border-left: 0px;

    @media (min-width: $screen-md-min) {
      border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  li {
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    @media (min-width: $screen-md-min) {
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-bottom: 0px;
    }

    @include padding(top-bottom, 60px);
    @include transition(.5s);

    h4 {
      margin-bottom: 10px;
      margin-top: 20px;
    }

    a {
      color: #fff;
      @include transition(.5s);

      &:focus {
        text-decoration: none;
      }
    }

    &:hover {
      // box-shadow: inset 0px 0px 35px rgba(0,0,0,0.1);
      cursor: pointer;

      a {
        color: #444;
      }

    }
  }
}


/* SOCIAL ICONS */
.socialicons ul {
  margin: 0px;
  padding: 0px;
}

.socialicons ul li {
  float: left;
  list-style-type: none;
  background: #696969;
  padding: 2px 4px !important;
}

.socialicons ul li a {
  color: #fff;
  font-size: 13px;
}

.socialicons ul li.blue:hover {
  background: #3b5998;
}

.socialicons ul li.lblue:hover {
  background: #46a1dc;
}

.socialicons ul li.orange:hover {
  background: #f9bc00;
}

.socialicons ul li.darkblue2:hover {
  background: #1c1c38;
}

.socialicons ul li.pink:hover {
  background: #ea4c88;
}

.socialicons ul li.red:hover {
  background: #cc0000;
}

.socialicons ul li.purple:hover {
  background: #cc0000;
}

.socialicons ul li.dgrey {
  background: #696969;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.socialicons ul li.lgrey {
  background: #919191;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.socialicons ul li.blue:hover a {
  color: #fff;
}

.socialicons ul li.darkblue2:hover a {
  color: #fff;
}

.socialicons ul li.orange:hover a {
  color: #fff;
}

.socialicons ul li.pink:hover a {
  color: #fff;
}

.socialicons ul li.red:hover a {
  color: #fff;
}

.socialicons ul li.dgrey a {
  color: #fff;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.socialicons ul li.lgrey a {
  color: #fff;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}



/* SOCIAL ICONS WHITE */
.socialiconswhite ul {
  margin: 0px;
  padding: 0px;
}

.socialiconswhite ul li {
  float: left;
  list-style-type: none;
  background: #fff;
  padding: 3px 5px;
  border: 1px solid #e2e2e2;
  margin-left: -1px;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.socialiconswhite ul li a {
  color: #333;
  font-size: 13px;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.socialiconswhite ul li.blue:hover {
  background: #3b5998;
  border-color: #3b5998;
}

.socialiconswhite ul li.lblue:hover {
  background: #46a1dc;
  border-color: #46a1dc;
}

.socialiconswhite ul li.orange:hover {
  background: #f9bc00;
  border-color: #f9bc00;
}

.socialiconswhite ul li.pink:hover {
  background: #ea4c88;
  border-color: #ea4c88;
}

.socialiconswhite ul li.red:hover {
  background: #cc0000;
  border-color: #cc0000;
}


.socialiconswhite ul li.blue:hover a {
  color: #fff;
}

.socialiconswhite ul li.lblue:hover a {
  color: #fff;
}

.socialiconswhite ul li.orange:hover a {
  color: #fff;
}

.socialiconswhite ul li.pink:hover a {
  color: #fff;
}

.socialiconswhite ul li.red:hover a {
  color: #fff;
}


/* SOCIAL ICONS DARK */
.socialiconsdark ul {
  margin: 0px;
  padding: 0px;
}

.socialiconsdark ul li {
  float: left;
  list-style-type: none;
  background: #333;
  padding: 3px 5px;
  border: 1px solid #222;
  margin-left: -1px;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.socialiconsdark ul li a {
  color: #fff;
  font-size: 13px;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.socialiconsdark ul li.blue:hover {
  background: #3b5998;
  border-color: #3b5998;
}

.socialiconsdark ul li.lblue:hover {
  background: #46a1dc;
  border-color: #46a1dc;
}

.socialiconsdark ul li.orange:hover {
  background: #f9bc00;
  border-color: #f9bc00;
}

.socialiconsdark ul li.pink:hover {
  background: #ea4c88;
  border-color: #ea4c88;
}

.socialiconsdark ul li.red:hover {
  background: #cc0000;
  border-color: #cc0000;
}

.socialiconsdark ul li.blue:hover a {
  color: #fff;
}

.socialiconsdark ul li.lblue:hover a {
  color: #fff;
}

.socialiconsdark ul li.orange:hover a {
  color: #fff;
}

.socialiconsdark ul li.pink:hover a {
  color: #fff;
}

.socialiconsdark ul li.red:hover a {
  color: #fff;
}


/* TEAM MOUSE OVER ANIMATIONS */
.player {
  margin-top: 20px;
  background: #fff;
  border: 1px solid #e2e2e2;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.player:hover {
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

@media (min-width: $screen-md-min) {
  .player {
    margin-top: 0px;
  }
}

.playerdark {
  margin-top: 20px;
  background: #222;
  border: 1px solid #333;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.playerdark:hover {
  border: 1px solid #444;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

@media (min-width: $screen-md-min) {
  .playerdark {
    margin-top: 0px;
  }
}

.teampicture {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.teampicture p {
  width: 100%;
  position: absolute;
  bottom: -50px;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.teamover {
  background: #000;
  opacity: 0.15;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
}

.circlesmall {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  padding: 5px 5px;
  font-size: 16px
}

.circlesmall.dark {
  border: 1px solid #444;
}

.circlesmall.white {
  border: 1px solid darken($secondcolor, 10%);
}

.circlesmall.light {
  border: 1px solid #fff;
}

.circlesmall-maincolor {
  background: $maincolor;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  padding: 5px 5px;
  font-size: 16px
}

.circlesmall-secondcolor {
  background: $secondcolor;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  padding: 5px 5px;
  font-size: 16px
}

.circlesmalldark {
  background: #c1bf94;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid #c1bf94;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.0);
  padding: 5px 5px;
  font-size: 16px
}

.circlebig {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  padding: 16px 5px;
  font-size: 16px
}

.circlebigwhite {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.0);
  padding: 16px 5px;
  font-size: 16px
}

.circlebigdark {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 1px solid #666;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
  padding: 16px 5px;
  font-size: 16px
}


.ctnr {
  margin-left: 50px;
}

ul.aboutteamlist {
  margin: 0px;
  padding: 0px;
}

ul.aboutteamlist li {
  list-style-type: none;
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  ul.aboutteamlist li {
    list-style-type: none;
    width: 50%;
    float: left;
    margin-bottom: 40px;
  }
}


/*ABOUT PAGE*/
.progress-about {
  height: 35px;
  margin-bottom: 0px;
  overflow: hidden;
  background-color: #f1f1f1;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-about.dark {
  background-color: #333;
}

.progress-about.slim,
.progress-about.flat {
  background-color: #f1f1f1;
  box-shadow: none;
}

.progress-about.thin {
  height: 2px;
}


.progress-bar {}

.progress-bar-maincolor {
  background-color: $maincolor;
  font-weight: 700;
}

.progress-bar-orange {
  background-color: #ff9966;
  font-weight: 700;
}

.progress-bar-green {
  background-color: #95ce92;
  font-weight: 700;
}

.progress-bar-blue {
  background-color: #76bbdf;
  font-weight: 700;
}

.progress-bar-red {
  background-color: #cc6666;
  font-weight: 700;
}

.pbar1,
.pbar2,
.pbar3,
.pbar4 {
  text-align: left;
  overflow: hidden;
  padding: 9px 0px;
  white-space: nowrap;

  -moz-transition-property: none;
  -webkit-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}

.pbar {
  text-align: left;
  overflow: hidden;
  padding: 9px 0px;
  white-space: nowrap;

  -moz-transition-property: none;
  -webkit-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}

.margbar {
  margin-bottom: 20px;
}

.margbar2 {
  margin-bottom: 1px !important;
}

.nocorners {
  border-radius: 0;
}

.progress.flat {
  box-shadow: none;
}

/*Radius*/
.radius1 {
  border-radius: 1px;
}

.radius2 {
  border-radius: 2px;
}

.radius3 {
  border-radius: 3px;
}

.radius4 {
  border-radius: 4px;
}

.radius5 {
  border-radius: 5px;
}

/*COLLAPSE STYLE DARK*/
.panel.dark {
  background-color: #333;
  border: 0px solid #666;
  border-radius: 0px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.0);
  margin-bottom: 20px;
}

.panel-default.dark>.panel-heading.dark {
  background-color: #333;
  border-color: #666;
  color: #999;
}

.panel-default.dark>.panel-heading.dark+.panel-collapse.dark .panel-body {
  border-top-color: #333;
  margin-top: 3px;
}

.panel-heading.dark a {
  color: #fff;
}

.panel-heading.dark span {
  width: 7px;
  height: 9px;
  background: url('../images/arrow2.png');
  display: block;
  float: right;
  margin-top: -12px;
  transform: rotate(0deg);
}

.panel-body.dark span {
  width: 7px;
  height: 9px;
  background: url('../images/arrow2.png');
  display: block;
  float: right;
  margin-top: -42px;
  margin-right: 1px;
  transform: rotate(180deg);
}




/*COLLAPSE STYLE*/
.panel-group .panel {
  border-radius: 0px;
  margin-bottom: 0;
  overflow: visible;
}

.panel-group .panel-heading {
  border: 1px solid #CCCCCC;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.18);
}

.panel {
  background-color: transparent;
  border: 0px solid #CCC;
  border-radius: 0px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.0);
  margin-bottom: 20px;
}

.panel-default {
  border-color: #CCCCCC;
}

.panel-title {
  font-weight: bold;
}

.panel-title a {
  text-decoration: none;
  display: block;
}

.panel-default>.panel-heading {
  background-color: #FFFFFF;
  color: #333333;
}

.panel-heading {
  border-bottom: 0px solid CCC;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 10px 15px;
}

.panel-heading span {
  width: 7px;
  height: 9px;
  background: url('../images/arrow.png');
  display: block;
  float: right;
  margin-top: -12px;
  transform: rotate(0deg);
}

.panel-body span {
  width: 7px;
  height: 9px;
  background: url('../images/arrow.png');
  display: block;
  float: right;
  margin-top: -42px;
  margin-right: 1px;
  transform: rotate(180deg);
}

.panel-default>.panel-heading+.panel-collapse .panel-body {
  border-top-color: transparent;
  margin-top: 3px;
}



/*INPUTS*/

.formlarge {
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 0px;
}

.formlarge2 {
  color: #666;
  height: 55px;
  display: inline-block;
  border: 1px solid #363636;
  border-radius: 0px;
  background: transparent;

  &:focus {
    color: #fff;
  }
}

.formlarge3 {
  height: 44px;
  border: 1px solid #ccc;
  border-radius: 0px;
}

.halfsize {
  width: calc(50% - 10px);
}

.formstyle {
  border: 1px solid #ccc;
  border-radius: 0px;
}

.formstyle2 {
  color: #666;
  border: 1px solid #363636;
  border-radius: 0px;
  background: transparent;

  &:focus {
    color: #fff;
  }
}

.formtransparent {
  border: 0px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.1);
}

.formtransparent:focus {
  border: 0px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.075) inset;
}

.formtransparent::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}

.formtransparent:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}

.formtransparent::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}

.formtransparent:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

.comstyle {
  background: #efefef;
}

.comstyle:focus {
  box-shadow: none;
  background: #ddd;
}



/*FULL WIDTH SEARCH*/
.escape {
  font-size: 12px;
  color: #CCC;
  text-align: right;
  float: right;
  margin-right: 70px;
  margin-top: 20px;
}

.fwsearch {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  display: none;
  position: fixed;
  z-index: 10000;
  opacity: 0;
}

.csearch {}

a.closesearch {
  width: 60px;
  height: 60px;
  transform: rotate(45deg);
  display: block;
  color: #ccc;
  position: absolute;
  float: right;
  top: 20px;
  right: 10px;
  -webkit-transition: .8s;
  -moz-transition: .8s;
  transition: .8s;
}

a.closesearch:hover {
  color: #666;
}

input.fwsearchfield {
  border: 0px;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 2px solid #ccc;
  font-family: proxima_novalight;
  font-size: 30px;

  @media (min-width:$screen-md-min) {
    font-size: 40px;
  }

  @media (min-width:$screen-lg-min) {
    font-size: 60px;
  }

  height:100px;
  width:100%;
  color:#999;
  -webkit-transition:.4s;
  -moz-transition:.4s;
  transition:.4s;

}

input.fwsearchfield:hover {
  color: #000;
  border: 0px;
  box-shadow: none;
  border-bottom: 2px solid #333;
}

input.fwsearchfield:focus {
  color: #000;
  border: 0px;
  box-shadow: none;
  border-bottom: 2px solid #333;
}

button.fwsearchfieldbtn {
  display: block;
  height: 100px;
  background-color: transparent;
  color: #999;
  border-radius: 0px;
  border: 0px;
  position: relative;
  top: -100px;
  float: right;
  font-size: 30px;

  @media (min-width:$screen-md-min) {
    font-size: 40px;
  }

  @media (min-width:$screen-lg-min) {
    font-size: 50px;
  }

  -webkit-transition:.8s;
  -moz-transition:.8s;
  transition:.8s;
}

button.fwsearchfieldbtn:hover {
  color: #333;
}



/*Faq's search*/
input.fqsearch {
  display: block;
  height: 50px;
  width: 100%;
  background: #fff;
  box-shadow: none;
  border: 0px;
  border-radius: 30px;
  font-size: 18px;
  font-family: proxima_novalight;
  color: #999;
  float: left;
  padding-left: 30px;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
}

input.fqsearch:hover,
input.fqsearch:focus {
  color: #000;
  border: 0px;
  box-shadow: none;
}

input.fqsearch.white {
  background: #f5f5f5;
  border: 0px;
}

input.fqsearch.white:hover,
input.fqsearch.white:focus {
  background: #efefef;
  border: 0px;
}

button.fqsearchbtn {
  display: block;
  height: 40px;
  padding: 0 20px;
  background-color: $maincolor;
  font-size: 18px;

  span {
    font-size: 16px;
    position: relative;
    top: -2px;
  }

  color:darken($maincolor, 15%);
  border:1px solid darken($maincolor, 5%);
  border-radius:30px;
  float:right;
  position:absolute;
  top:5px;
  right:5px;
  @include transition(.3s);

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  }
}




/* CAROUSEL BOOLETS */
div.carouselnav ol.carousel-indicators {
  bottom: 0px !important;
}

div.carouselnav ol.carousel-indicators li {
  border: 1px solid #fff !important;
  padding: 0 !important;
}

div.carouselnav ol.carousel-indicators li.active {
  background: #fff !important;
}

div.carouselnav .glyphicon-chevron-left:before {
  content: "";
  font-size: 12px;
  top: -5px !important;
  position: relative;
}

div.carouselnav .glyphicon-chevron-right:before {
  content: "";
  font-size: 12px;
  top: -5px !important;
  position: relative;
}




/*DARK SECTION*/
.darksection {
  background: #222;
  color: #fff;
}

.darksection input {
  background: #333;
  border: 1px solid #333;
}

.darksection textarea {
  background: #333;
  border: 1px solid #333;
}


//Table padding
table.padding td {
  padding: 20px !important;
}

/*
################
PRICELIST TABLE
################
*/
.h9 {
  font-size: 28px;
  line-height: 22px;
}

.smallp {
  font-size: 12px;
  position: relative;
  top: -10px;
}


.pricelist-wrapper.col5 ul li {
  width: 20%;
}

.pricelist-wrapper.col4 ul li {
  width: 25%;
}

.pricelist-wrapper.col3 ul li {
  width: 33%;
}

.pricelist-wrapper.col2 ul li {
  width: 50%;
}

.pricelist-wrapper.col1 ul li {
  width: 100%;
}

.pricelist-wrapper ul {
  height: 380px;
  padding: 0px;
  list-style: none;
}

.pricelist-wrapper ul li.leftround {
  border-radius: 50px 0 0px 50px;
}

.pricelist-wrapper ul li.leftround:hover {
  border-radius: 50px 0 0px 50px;
}

.pricelist-wrapper ul li.rightround {
  border-radius: 0 50px 50px 0;
}

.pricelist-wrapper ul li.rightround:hover {
  border-radius: 0 50px 50px 0;
}



.pricelist-wrapper li {
  float: left;
  border: 1px solid #e7e7e7;
  margin-top: 0px;
  padding-top: 0px;
  background: #fff;
  margin-left: -1px;
  position: relative;
  z-index: 5;
  transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
  -moz-transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
  -webkit-transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
  -o-transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
}

.pricelist-wrapper li:hover {
  border: 1px solid #f25d3c;
  margin-top: -10px;
  padding-top: 20px;
  background: #f25d3c;
  z-index: 10;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -khtml-border-radius: 0px;
}

.pricelist-wrapper .plistrow0 {
  padding: 15px 0 15px 0;
  border-bottom: 0px solid #e7e7e7;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.pricelist-wrapper li:hover .plistrow0 {
  border-bottom: 0px solid #e75e30;
  color: #fff;
}

.pricelist-wrapper .plistrow1 {
  padding: 14px 0 15px 0;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.pricelist-wrapper li:hover .plistrow1 {
  border-bottom: 1px solid #e75e30;
  color: #fff;
}

.pricelist-wrapper .plistrow2 {
  height: 80px;
  padding: 25px 0 25px 0;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.pricelist-wrapper li:hover .plistrow2 {
  color: #fff;
}

.pricelist-wrapper .plistrowbg {
  padding: 12px 0 12px 0;
  text-align: center;
  color: #666;
  font-size: 13px;
  background: #f7f7f7;
}

.pricelist-wrapper li:hover .plistrowbg {
  color: #fff;
  background: #e65839;
}

.pricelist-wrapper .plistrow {
  padding: 12px 0 12px 0;
  text-align: center;
  color: #666;
  font-size: 13px;
}

.pricelist-wrapper li:hover .plistrow {
  color: #fff;
}

/*icons*/
.pricelist-wrapper .plistprice img {
  width: 27px;
  height: 20px;
  background: url('../images/sprite.png') no-repeat scroll -188px -113px transparent;
}

.pricelist-wrapper li:hover .plistprice img {
  width: 27px;
  height: 20px;
  background: url('../images/sprite.png') no-repeat scroll -216px -113px transparent;
}

.pricelist-wrapper .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll 0 -151px transparent;
}

.pricelist-wrapper li:hover .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll -14px -151px transparent;
}

.pricelist-wrapper .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll 0 -166px transparent;
}

.pricelist-wrapper li:hover .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll -14px -166px transparent;
}

.pricelist-wrapper .button_ok {
  font-size: 12px;
}

.pricelist-wrapper .button_ok a {
  background: $maincolor;
  color: #fff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.pricelist-wrapper .button_ok a:hover {
  background: $maincolor;
  color: #fff;
}

.pricelist-wrapper li:hover .button_ok a {
  background: $maincolor;
  color: #fff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.pricelist-wrapper li:hover .button_ok a:hover {
  background: $maincolor;
  color: #666;
}

/*Mouseover*/

/*Selected*/
.pricelist-wrapper li.plistselected {
  margin-top: -10px;
  border: 1px solid #f25d3c;
  background: #f25d3c;
  z-index: 10;
  padding-top: 20px;
}

.pricelist-wrapper li.plistselected div {
  color: #fff !important;
}

.pricelist-wrapper li.plistselected .plistrow1 {
  border-bottom: 1px solid #e75e30;
  color: #fff;
}

.pricelist-wrapper li.plistselected .plistrowbg {
  color: #fff;
  background: #e65839;
}

.pricelist-wrapper li.plistselected .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll -14px -151px transparent;
}

.pricelist-wrapper li.plistselected .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll -14px -166px transparent;
}

.pricelist-wrapper li.plistselected .button_ok a {
  background: $maincolor;
  color: #ffffff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

/*No selections*/
.pricelist-wrapper li.noselections:hover {
  z-index: 1;
  background: #fff;
  border: 1px solid #e7e7e7;
  margin-top: 0px;
  padding-top: 0px;
}

.pricelist-wrapper li.noselections:hover .plistprice img {
  width: 27px;
  height: 20px;
  background: url('../images/sprite.png') no-repeat scroll -188px -113px transparent;
}

.pricelist-wrapper li.noselections:hover .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll 0 -151px transparent;
}

.pricelist-wrapper li.noselections:hover .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite.png') no-repeat scroll 0 -166px transparent;
}

.pricelist-wrapper li.noselections div {
  color: #333 !important;
}

.pricelist-wrapper li.noselections .plistrow1 {
  border-bottom: 1px solid #e7e7e7;
  color: #fff;
}

.pricelist-wrapper li.noselections .plistrowbg {
  color: #fff;
  background: #f7f7f7;
}

.pricelist-wrapper li.noselections .plistrow {
  color: #fff;
  background: #fff;
}

.pricelist-wrapper li.noselections .button_ok a {
  background: $maincolor;
  color: #fff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.pricelist-wrapper li.noselections:hover .button_ok a:hover {
  background: $maincolor;
  color: #666;
}

/*Mouseover*/



/*COLOR DARK*/
.pricelist-wrapper.noborders {
  li {
    border: 0px !important;
    box-shadow: 0 1px 2px rgba(black, 0.1)
  }

  div.plistrow1 {
    border-bottom: 1px solid rgba(white, 0.2) !important;
  }

  li.plistselected {
    border: 0px !important;
  }

  li.plistselected .plistrow1 {
    border: 0px !important;
  }
}

.pricelist-wrapper.dark li {
  float: left;
  border: 1px solid #e7e7e7;
  margin-top: 0px;
  padding-top: 0px;
  background: #fff;
  margin-left: -1px;
  position: relative;
  z-index: 5;
  transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
  -moz-transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
  -webkit-transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
  -o-transition: float 0.2s, width 0.2s, height 0.2s, border 0.2s, margin-top 0.2s, padding-top 0.2s;
}

.pricelist-wrapper.dark li:hover {
  border: 1px solid #efefef;
  margin-top: -10px;
  padding-top: 20px;
  background: #efefef;
  z-index: 10;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -khtml-border-radius: 0px;
}

.pricelist-wrapper.dark .plistrow0 {
  padding: 15px 0 15px 0;
  border-bottom: 0px solid #e7e7e7;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.pricelist-wrapper.dark li:hover .plistrow0 {
  border-bottom: 0px solid #ddd;
  color: #222;
}

.pricelist-wrapper.dark .plistrow1 {
  padding: 14px 0 15px 0;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.pricelist-wrapper.dark li:hover .plistrow1 {
  border-bottom: 1px solid #ddd;
  color: #222;
}

.pricelist-wrapper.dark .plistrow2 {
  height: 80px;
  padding: 25px 0 25px 0;
  text-align: center;
  color: #333;
  font-size: 16px;
}

.pricelist-wrapper.dark li:hover .plistrow2 {
  color: #222;
}

.pricelist-wrapper.dark .plistrowbg {
  padding: 12px 0 12px 0;
  text-align: center;
  color: #666;
  font-size: 13px;
  background: #f7f7f7;
}

.pricelist-wrapper.dark li:hover .plistrowbg {
  color: #222;
  background: #ddd;
}

.pricelist-wrapper.dark .plistrow {
  padding: 12px 0 12px 0;
  text-align: center;
  color: #666;
  font-size: 13px;
}

.pricelist-wrapper.dark li:hover .plistrow {
  color: #222;
}

/*icons*/
.pricelist-wrapper.dark .plistprice img {
  width: 27px;
  height: 20px;
  background: url('../images/sprite2.png') no-repeat scroll -188px -113px transparent;
}

.pricelist-wrapper.dark li:hover .plistprice img {
  width: 27px;
  height: 20px;
  background: url('../images/sprite2.png') no-repeat scroll -216px -113px transparent;
}

.pricelist-wrapper.dark .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll 0 -151px transparent;
}

.pricelist-wrapper.dark li:hover .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll -14px -151px transparent;
}

.pricelist-wrapper.dark .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll 0 -166px transparent;
}

.pricelist-wrapper.dark li:hover .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll -14px -166px transparent;
}

.pricelist-wrapper.dark .button_ok {
  font-size: 12px;
}

.pricelist-wrapper.dark .button_ok a {
  background: $maincolor;
  color: #fff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.pricelist-wrapper.dark .button_ok a:hover {
  background: $maincolor;
  color: #fff;
}

.pricelist-wrapper.dark li:hover .button_ok a {
  background: $maincolor;
  color: #fff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.pricelist-wrapper.dark li:hover .button_ok a:hover {
  background: $maincolor;
  color: #666;
}

/*Mouseover*/

/*Selected*/
.pricelist-wrapper.dark li.plistselected {
  margin-top: -10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  z-index: 10;
  padding-top: 20px;
}

.pricelist-wrapper.dark li.plistselected div {
  color: #222 !important;
}

.pricelist-wrapper.dark li.plistselected .plistrow1 {
  border-bottom: 1px solid #e7e7e7;
  color: #fff;
}

.pricelist-wrapper.dark li.plistselected .plistrowbg {
  color: #fff;
  background: #efefef;
}

.pricelist-wrapper.dark li.plistselected .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll -14px -151px transparent;
}

.pricelist-wrapper.dark li.plistselected .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll -14px -166px transparent;
}

.pricelist-wrapper.dark li.plistselected .button_ok a {
  background: $maincolor;
  color: #ffffff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

/*No selections*/
.pricelist-wrapper.dark li.noselections:hover {
  z-index: 1;
  background: #fff;
  border: 1px solid #e7e7e7;
  margin-top: 0px;
  padding-top: 0px;
}

.pricelist-wrapper.dark li.noselections:hover .plistprice img {
  width: 27px;
  height: 20px;
  background: url('../images/sprite2.png') no-repeat scroll -188px -113px transparent;
}

.pricelist-wrapper.dark li.noselections:hover .pchecked img {
  width: 14px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll 0 -151px transparent;
}

.pricelist-wrapper.dark li.noselections:hover .punvailable img {
  width: 11px;
  height: 11px;
  background: url('../images/sprite2.png') no-repeat scroll 0 -166px transparent;
}

.pricelist-wrapper.dark li.noselections div {
  color: #333 !important;
}

.pricelist-wrapper.dark li.noselections .plistrow1 {
  border-bottom: 1px solid #e7e7e7;
  color: #fff;
}

.pricelist-wrapper.dark li.noselections .plistrowbg {
  color: #fff;
  background: #f7f7f7;
}

.pricelist-wrapper.dark li.noselections .plistrow {
  color: #fff;
  background: #fff;
}

.pricelist-wrapper.dark li.noselections .button_ok a {
  background: $maincolor;
  color: #fff;
  padding: 5px 16px 5px 16px;
  min-width: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.pricelist-wrapper.dark li.noselections:hover .button_ok a:hover {
  background: $maincolor;
  color: #666;
}

/*Mouseover*/







/*TABS*/
.fb-tabs li span {
  display: inline-block;
  padding: 5px 0px;
  text-indent: -1000px;
}

@media (min-width:$screen-xs-min) {
  .fb-tabs li span {
    display: inline-block;
    padding: 5px 0px;
    text-indent: 0px;
  }
}

@media (min-width:$screen-md-min) {
  .fb-tabs li span {
    display: inline-block;
    padding: 0px 0px;
    text-indent: 0px;
  }
}

.fb-tabs li a {
  color: #ccc;
  font-weight: bold;
  border-radius: 0;
  border: 1px solid #efefef;
  margin: 0px 0px 0px -1px;
}

.fb-tabs li a:hover {
  border: 1px solid #efefef;
  background: #fcfcfc;
}

.fb-tabs li.active a {
  color: #333;
  border-left: 1px solid #efefef !important;
  border-top: 1px solid #efefef !important;
}


.fb-tabs2 li {}

.fb-tabs2 li a {
  color: #ccc;
  font-weight: bold;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  margin: 0px 0px 0px -1px;
  padding: 15px;
  white-space: nowrap;
}

.fb-tabs2 li a:hover {
  border-bottom: 2px solid #999;
  background: transparent;
}

.fb-tabs2 li.active a {
  border-bottom: 2px solid #333 !important;
  background: transparent;
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  color: #333;
}

.fb-tabs2 li.active a:focus {
  background: transparent;
}

.fb-tabs2 li.active a:hover {
  background: transparent;
  border-bottom: 2px solid #333;
  color: #333;
}

.tab-content.border {
  border: 1px solid #efefef;
  margin-left: -1px;
  margin-top: -1px;
}


/*THEMES PREVIEW*/
.tm-prev {
  display: block;
  background: transparent;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin: 0;
  padding: 0;
  z-index: 1000;
  overflow: hidden;
}

a.tm-open,
a.tm-close {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  text-align: center;
  display: block;
  background: #111;
  color: #fff;
  font-size: 22px;
  padding: 12px 0;
  z-index: 100;
  cursor: pointer;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

a:hover.tm-open,
a:hover.tm-close {
  position: absolute;
  bottom: 0px;
  right: 0px;
  text-align: center;
  display: block;
  background: #000;
  color: #999;
  width: 50px;
  height: 50px;
  padding: 12px 0;
  z-index: 100;
  cursor: pointer;
}

a.tm-close p.deg45 {
  font-size: 24px;
  margin-top: -10px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

span.tm-prev-ico {
  display: block;
  width: 26px;
  height: 20px;
  background: url('../images/tmprev/icon-tm-prew.png') no-repeat 0 0;
  margin: -5px 0px 0px 12px;
}

.tm-content {
  display: block;
  width: 225px;
  height: 396px;
  overflow-y: scroll;
  background: #111;
  position: relative;
  border-top: 5px solid #111;
}

.tm-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tm-content ul li {
  display: block;
  float: left;
  margin-left: 5px;
  margin-bottom: 5px;
  position: relative;
  z-index: 100;
}

.tm-content ul li img {
  opacity: 1;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
  position: relative;
  z-index: 100;
}

.tm-content ul li img:hover {
  opacity: 1;
}

.tm-over {
  width: 150px;
  height: 100%;
  background: #111;
  background: rgba(0, 0, 0, 0.7);
  padding: 27px 20px;
  color: #fff;
  font-weight: bold;
  opacity: 0;
  position: absolute;
  top: 0px;
  z-index: 1000;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

.tm-over:hover {
  opacity: 1;
}

.tm-over p {
  font-family: "proxima_novalight";
  color: $maincolor;
  font-size: 12px;
  margin-top: 0px;
  margin-left: 27px;
  text-transform: uppercase;
}

#Style {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style2 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style3 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style4 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style5 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style6 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style7 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style8 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style9 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style10 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style11 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style12 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style13 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style14 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style15 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style16 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style17 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style18 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style19 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style20 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style21 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style22 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style23 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

#Style24 {
  position: fixed;
  bottom: 230px;
  right: 160px;
  visibility: hidden;
  background: #111;
  padding: 0px 0px 5px 0px;
  z-index: 1000;
}

/*BACK TO TOP */
#back-top {
  position: fixed;
  bottom: -7px;
  right: 0px;
  z-index: 100000;

}

#back-top a {
  width: 50px;
  display: block;
  text-align: center;
  font: 11px/100% Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
}

#back-top a:hover {
  color: #999;
}

/* arrow icon (span tag) */
#back-top span {
  width: 50px;
  height: 50px;
  display: block;
  background: #121212;
  border-radius: 0px 0px 0px 10px;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s;
  padding-top: 10px;
  color: #999;
  font-size: 16px;
}

#back-top a:hover span {
  background-color: #000;
  color: #fff;
}

/*BACK TO TOP 2*/
#back-top2 {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  z-index: 100000;

}

#back-top2 a {
  width: 45px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  transition: 1s;
}

#back-top2 a:hover {
  color: #999;
}

/* arrow icon (span tag) */
#back-top2 span {
  width: 45px;
  height: 45px;
  display: block;
  padding-top: 10px;
  border-radius: 50px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  transition: 1s;
  border: 1px solid #fcfcfc;

  i {
    color: #ccc;
    @include transition(.2s);
  }
}

#back-top2 a:hover span {
  background-color: transparent;
  border: 1px solid #efefef;

  i {
    color: #333;
  }
}

/* PORTFOLIO GALLERY */
// Responsive
ul.pgal {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.pgal li {
  width: 50%;
  float: left;
  position: relative;
  overflow: hidden;
}

ul.pgal li.times2 {
  width: 50%;
}

ul.pgal li.pgalhide {
  display: block;
}

ul.pgal li.pgalhide2 {
  display: block;
}

@media only screen and (min-width: 800px) and (max-width: 1069px) {
  ul.pgal li {
    width: 25%;
    float: left;
  }

  ul.pgal li.pgalhide {
    display: none;
  }

  ul.pgal li.pgalhide2 {
    display: none;
  }

  ul.pgal li.times2 {
    width: 50%;
  }
}

@media only screen and (min-width: 1070px) and (max-width: 1365px) {
  ul.pgal li {
    width: 20%;
    float: left;
  }

  ul.pgal li.pgalhide {
    display: none;
  }

  ul.pgal li.pgalhide2 {
    display: block;
  }

  ul.pgal li.times2 {
    width: 40%;
  }
}

@media (min-width: 1366px) {
  ul.pgal li {
    width: 16.66%;
    float: left;
  }

  ul.pgal li.pgalhide {
    display: block;
  }

  ul.pgal li.pgalhide2 {
    display: block;
  }

  ul.pgal li.times2 {
    width: 33.32%;
  }
}

// Hover animation
ul.pgal li img.zoom {
  width: 100%;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

ul.pgal li:hover img.zoom {
  width: 110%;
  margin-left: -5%;
  margin-top: -5%;
}

ul.pgal li div.pgal-hover {
  display: block;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  -webkit-transition: opacity .5s ease-in;
  -moz-transition: opacity .5s ease-in;
  transition: opacity .5s ease-in;
}

ul.pgal li:hover div.pgal-hover {
  opacity: 1;
}

ul.pgal li div.pgal-hover h4 {
  color: #fff;
  margin-top: 30%;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

ul.pgal li div.pgal-hover span {
  width: 45px;
  height: 2px;
  background: #fff;
  display: block;
  margin: 10px auto 0 auto;
  padding: 0;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

ul.pgal li div.pgal-hover p {
  color: #fff;
  margin-top: 10px;
}

ul.pgal li div.pgal-hover a.btnmore {
  color: #fff;
  font-size: 9px;
  width: 45px;
  height: 45px;
  display: block;
  border: 2px solid #fff;
  margin: 0 auto 0 auto;
  padding: 14px 0 0 1px;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
}

ul.pgal li div.pgal-hover a.btnmore:hover {
  color: $secondcolor;
  border: 2px solid $secondcolor;
}



ul.pgal li:hover div.pgal-hover h4 {
  margin-top: 28%;
}

ul.pgal li:hover div.pgal-hover span {
  margin: 20px auto 0 auto;
}

ul.pgal li:hover div.pgal-hover a.btnmore {
  margin: 20px auto 0 auto;
}

//Portfolio details page
select.sharetofb {
  height: 25px;
  width: auto;
  padding: 0;
  margin-left: 20px;
  color: #666;
  line-height: 30px;
  text-transform: uppercase;
  font-size: 11px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;

  &:hover {
    border: 1px solid #ccc;
    outline: 0;
  }

  option {
    color: #333;
  }
}


/* END OF PORTFOLIO GALLERY */


/* SERVICES HOVER */
ul.services {
  li {
    min-height: 240px;
    padding: 30px;

    i {
      color: #666 !important;
      @include transition(.2s);
    }

    h4 {
      color: #999;
      @include transition(.2s);
    }

    a {
      color: #333;
    }

    &:hover {
      i {
        color: $maincolor !important;
      }

      h4 {
        color: #333;
      }

      a {
        color: #999;
        cursor: pointer;
      }
    }

    border-bottom:1px solid #efefef;

    &:nth-child(6) {
      border-bottom: none;
    }
  }

  @media (min-width:$screen-xs-min) {
    li {
      border-right: none;
      border-bottom: 1px solid #efefef;
    }

    li:nth-child(6) {
      border-bottom: none;
    }
  }

  @media (min-width:$screen-sm-min) {

    li:nth-child(1),
    li:nth-child(3),
    li:nth-child(5) {
      border-right: 1px solid #efefef;
    }

    li:nth-child(5),
    li:nth-child(6) {
      border-bottom: none;
    }
  }

  @media (min-width:$screen-md-min) {
    li:nth-child(2) {
      border-right: 1px solid #efefef;
    }

    li:nth-child(3) {
      border-right: none;
    }

    li:nth-child(4) {
      border-right: 1px solid #efefef;
      border-bottom: none;
    }

  }
}

/* END OF SERVICES HOVER */


//ANIMATE BORDER CSS
$bdr-size:50px;

.leftline {
  display: block;
  width: 0px;
  height: $bdr-size;
  position: absolute;
  top: 0px;
  z-index: 100;
  background: white;
}

.bottomline {
  display: block;
  width: $bdr-size;
  height: 0px;
  position: absolute;
  bottom: 0px;
  z-index: 100;
  background: white;
}

.rightline {
  display: block;
  width: 0px;
  height: $bdr-size;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 100;
  background: white;
}

.topline {
  display: block;
  width: $bdr-size;
  height: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  background: white;
}





/* Theme thumbs */
.tmthumb {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0px;

  img {
    width: 100%;
    height: auto;
    opacity: 1;
    @include transition(.5s);

    &:hover {
      opacity: 0.5;
    }
  }

  span {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    background: #76bbdf;
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 100px;

    i {
      font-size: 16px;
      font-style: normal;
    }
  }

  h1 {
    padding: 15px 15px 0 15px;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  p {
    padding: 0px 15px 5px 15px;
    font-size: 12px;
    color: #999;
    line-height: 14px;
    margin-top: 3px;
  }
}


//used on services page
.accsquare {
  background: white;
  @include transition (.2s);
}

.accsquare:hover {
  background: $maincolor;
  color: white;
}


/*###############*/
/* PORTFOLIO PAGE*/
/*###############*/

//Mixitup styles
#mixItUp .mix {
  display: none;
}

.mixpercent {
  width: 95%;
}

@media (min-width: $screen-lg-min) {
  .mixpercent {
    width: 80%;
  }
}

.mix,
.mix.col1,
.mix.col2,
.mix.col3,
.mix.col4 {
  width: 100%;
  float: left;
  position: relative;
}

@media (min-width: $screen-sm-min) {
  .mix {
    width: 50%;
  }

  .mix.col1 {
    width: 100%;
  }

  .mix.col2 {
    width: 50%;
  }

  .mix.col3 {
    width: 50%;
  }

  .mix.col4 {
    width: 50%;
  }
}

@media (min-width: $screen-md-min) {
  .mix {
    width: 33.33%;
  }

  .mix.col1 {
    width: 100%;
  }

  .mix.col2 {
    width: 50%;
  }

  .mix.col3 {
    width: 33.33%;
  }

  .mix.col4 {
    width: 25%;
  }
}

.mix:after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

/* Other aspect ratios:
 * 56.25% = 16:9
 * 75% = 4:3
 * 66.66% = 3:2
 * 62.5% = 8:5
 */
.mainwrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* fill parent */
  background-color: #fff;
  /* let's see it! */
  color: white;
  margin: 10px;
  overflow: hidden;
}

.mainwrap img {
  opacity: 0.95;
}

.mixhover {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  h1 {
    font-family: proxima_nova_rgbold;
    font-size: 24px;
    text-transform: uppercase;
    color: #fff;
  }

  p {
    color: #fff;
  }
}

.mainwrap:hover .mixhover {
  opacity: 1;
  @include transition(.4s);
}

.mixhover.red {
  background: rgba(#ff6666, 0.9);
}

.mixhover.blue {
  background: rgba(#13acd8, 0.9);
}

.mixhover.lime {
  background: rgba(#c1d88a, 0.9);
}

.mixhover.orange {
  background: rgba(#ff9966, 0.9);
}

.mixhover.cealk {
  background: rgba(#c1bf94, 0.9);
}

.mixhover.turquoise {
  background: rgba(#1abc9c, 0.9);
}

.mixhover.purple {
  background: rgba(#704b8c, 0.9);
}

.mixhover.black {
  background: rgba(#222, 0.9);
}

.mixbtn {
  background: transparent;
  border: 0;
  font-family: 'proxima_novalight';
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  margin: 0 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid transparent;

  &:hover,
  &:focus {
    outline: none;
    border-bottom: 3px solid $maincolor;
  }

  &.active {
    color: #000;
    border-bottom: 3px solid $maincolor;
  }
}

//END OF PORTFOLIO PAGE




/*############*/
/* SHOP PAGES */
/*############*/

/* Begining of Card hover effect */
.view-code {
  color: #48cfad;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  position: absolute;
  top: 640px;
  left: 50%;
  margin-left: -35px;
}

.view-code:hover {
  color: #34c29e;
}

/* --- Product Card ---- */
.make-3D-space {
  perspective: 800px;
  width: 100%;
  height: 500px;
  transform-style: preserve-3d;
  transition: transform 5s;
  position: relative;
  left: -7px;
}

.product-front,
.product-back {
  width: 100%;
  height: 500px;
  background: #fff;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}


.product-back {
  display: none;
  transform: rotateY(180deg);
  left: 5px;
}

.product-card.animate .product-back,
.product-card.animate .product-front {
  top: 0px;
  left: 0px;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.product-card {
  width: 100%;
  height: 490px;
  position: absolute;
  top: 10px;
  left: 10px;
  overflow: hidden;
  transform-style: preserve-3d;
  -webkit-transition: 100ms ease-out;
  -moz-transition: 100ms ease-out;
  -o-transition: 100ms ease-out;
  transition: 100ms ease-out;
}

div.product-card.flip-10 {
  -webkit-transform: rotateY(-10deg);
  -moz-transform: rotateY(-10deg);
  -o-transform: rotateY(-10deg);
  transform: rotateY(-10deg);
  transition: 50ms ease-out;
}

div.product-card.flip90 {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
  transition: 100ms ease-in;
}

div.product-card.flip190 {
  -webkit-transform: rotateY(190deg);
  -moz-transform: rotateY(190deg);
  -o-transform: rotateY(190deg);
  transform: rotateY(190deg);
  transition: 100ms ease-out;
}

div.product-card.flip180 {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  transition: 150ms ease-out;
}

.product-card.animate {
  top: 5px;
  left: 5px;
  width: 100%;
  height: 500px;
  box-shadow: 0px 13px 21px -5px rgba(0, 0, 0, 0.3);
  -webkit-transition: 100ms ease-out;
  -moz-transition: 100ms ease-out;
  -o-transition: 100ms ease-out;
  transition: 100ms ease-out;
}

.stats-container {
  background: #fff;
  position: absolute;
  top: 386px;
  left: 0;
  width: 100%;
  height: 300px;
  padding: 27px 35px 35px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.product-card.animate .stats-container {
  top: 272px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.stats-container .product_name {
  font-size: 16px;
  color: #393c45;
  font-family: proxima_nova_rgbold;
  text-transform: uppercase;
}

.stats-container p {
  font-size: 16px;
  color: #b1b1b3;
  padding: 2px 0 20px 0;
}

.stats-container .product_price {
  float: right;
  color: #48cfad;
  font-size: 22px;
  font-weight: 600;
}

.image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f56060;
  opacity: 0;
}

.product-card.animate .image_overlay {
  opacity: 0.7;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.product-options {
  padding: 2px 0 0;
}

.product-options strong {
  font-weight: 700;
  color: #393c45;
  font-size: 14px;
}

.product-options span {
  color: #969699;
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
}

.view_details,
.view_details2 {
  position: absolute;
  top: 112px;
  left: 50%;
  margin-left: -85px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 19px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0;
  width: 172px;
  opacity: 0;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.view_details:hover,
.view_details2:hover {
  background: #fff;
  color: #f56060;
  cursor: pointer;

}

.product-card.animate .view_details,
.product-card.animate .view_details2 {
  opacity: 1;
  width: 152px;
  font-size: 15px;
  margin-left: -75px;
  top: 115px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

.viewleft,
.viewright {
  width: 120px;
}

.viewleft {
  margin-left: 10px;
}

.viewright {
  margin-left: -130px;
}

.product-card.animate .viewleft,
.product-card.animate .viewright {
  width: 100px;
}

.product-card.animate .viewleft {
  margin-left: 2px;
}

.product-card.animate .viewright {
  margin-left: -102px;
}

.savesale {
  width: 72px;
  height: 72px;
  background: $maincolor;
  padding-top: 25px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  bottom: 100px;
  right: 0px;
  @include transition(.2s);
}

.product-card.animate .savesale {
  display: block;
  right: 20px;
  bottom: 80px;
  background: #efefef;
}

.product-card.flip90.flip180 .savesale {
  display: none;
}

div.colors div {
  margin-top: 3px;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  float: left;
}

div.colors div span {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
}

div.colors div span:hover {
  width: 17px;
  height: 17px;
  margin: -1px 0 0 -1px;
}

div.c-blue span {
  background: #6e8cd5;
}

div.c-red span {
  background: #f56060;
}

div.c-green span {
  background: #44c28d;
}

div.c-white span {
  background: #fff;
  width: 14px;
  height: 14px;
  border: 1px solid #e8e9eb;
}

div.shadow {
  width: 100%;
  height: 520px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
}

.product-back div.shadow {
  z-index: 10;
  opacity: 1;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
  background: -o-linear-gradient(right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
  background: -moz-linear-gradient(right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1));
}

.flip-back {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.cx,
.cy {
  background: #d2d5dc;
  position: absolute;
  width: 0px;
  top: 15px;
  right: 15px;
  height: 3px;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.flip-back:hover .cx,
.flip-back:hover .cy {
  background: #979ca7;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.cx.s1,
.cy.s1 {
  right: 0;
  width: 30px;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.cy.s2 {
  -ms-transform: rotate(50deg);
  -webkit-transform: rotate(50deg);
  transform: rotate(50deg);
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.cy.s3 {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.cx.s1 {
  right: 0;
  width: 30px;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.cx.s2 {
  -ms-transform: rotate(140deg);
  -webkit-transform: rotate(140deg);
  transform: rotate(140deg);
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.cx.s3 {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition: all 100ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.carousel {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;

}

.carousel ul {
  position: relative;
  top: 0;
  left: 0;
}

.carousel li {
  width: 335px;
  height: 500px;
  float: left;
  overflow: hidden;
}

.arrows-perspective {
  width: 100%;
  height: 55px;
  position: absolute;
  top: 218px;
  transform-style: preserve-3d;
  transition: transform 5s;
  perspective: 100%;
}

.carouselPrev,
.carouselNext {
  width: 50px;
  height: 55px;
  background: #ccc;
  position: absolute;
  top: 0;
  transition: all 200ms ease-out;
  opacity: 0.9;
  cursor: pointer;
}

.carouselNext {
  top: 0;
  right: -26px;
  -webkit-transform: rotateY(-117deg);
  -moz-transform: rotateY(-117deg);
  -o-transform: rotateY(-117deg);
  transform: rotateY(-117deg);
  transition: all 200ms ease-out;

}

.carouselNext.visible {
  right: 0;
  opacity: 0.8;
  background: #efefef;
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  transition: all 200ms ease-out;
}

.carouselPrev {
  left: -26px;
  top: 0;
  -webkit-transform: rotateY(117deg);
  -moz-transform: rotateY(117deg);
  -o-transform: rotateY(117deg);
  transform: rotateY(117deg);
  transition: all 200ms ease-out;

}

.carouselPrev.visible {
  left: 0;
  opacity: 0.8;
  background: #eee;
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  transition: all 200ms ease-out;
}

.carousel .x,
.carousel .y {
  height: 2px;
  width: 15px;
  background: #48cfad;
  position: absolute;
  top: 31px;
  left: 17px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.carousel .x {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 21px;
}

.carousel .carouselNext .x {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.carousel .carouselNext .y {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* End of Card hover effect */

/* BEGINING OF PRODUCT PAGE ZOOM */
.zoom p {
  position: absolute;
  top: 3px;
  right: 28px;
  color: #555;
  font: bold 13px/1 sans-serif;
}

/* these styles are for the demo, but are not required for the plugin */
.zoom {
  display: inline-block;
  position: relative;
  background: #333;
}

/* magnifying glass icon */
.zoom:after {
  content: '';
  display: block;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/shop/icon.png);
}

.zoom img {
  display: block;
  opacity: 1;
}

.zoom img::selection {
  background-color: transparent;
}

#ex2 img:hover {
  cursor: url(../images/shop/grab.cur), default;
}

#ex2 img:active {
  cursor: url(../images/shop/grabbed.cur), default;
}

ul.zoomthumbs {
  li {
    background: #333;
    margin-bottom: 20px;
    margin-right: 20px;
    border: 0px solid #fff;

    img {
      width: 100%;
      opacity: 1;
      @include transition(.2s);
    }

    @include transition(.2s);

    &:hover {
      background: #333;
      border: 0px solid #666;
      cursor: pointer;

      img {
        opacity: 0.9;
      }
    }
  }
}

/* END OF ZOOM */
#ex1 {
  img {
    width: 100%;
    height: auto;
  }
}

/* 160*4+(3*20) */
.coll1 {
  width: 21%;
  float: left;
  position: relative;
}

.coll2 {
  width: 79%;
  float: left;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid #f5f5f5;
}

.coll3 {
  width: 100%;
  float: static;
  height: 540px;
  float: left;
  position: relative;
}


@media (min-width: $screen-sm-min) {
  #ex1 {
    img.test {
      width: auto;
      height: 678px;
    }
  }

  .coll1 {
    width: auto;
    float: left;
  }

  .coll2 {
    width: auto;
    float: left;
  }

  .coll3 {
    width: 100%;
    float: left;
    height: 540px;
    padding-left: 0px;
  }

  ul.zoomthumbs li img {
    width: auto;
    height: 155px;
  }
}

@media (min-width: $screen-md-min) {
  #ex1 {
    img.test {
      width: auto;
      height: 540px;
    }
  }

  .coll1 {
    width: auto;
    float: left;
  }

  .coll2 {
    width: auto;
    float: left;
  }

  .coll3 {
    width: 350px;
    float: left;
    height: 540px;
  }

  ul.zoomthumbs li img {
    width: auto;
    height: 120px;
  }
}

@media (min-width: $screen-lg-min) {
  #ex1 {
    img.test {
      width: auto;
      height: 700px;
    }
  }

  .coll1 {
    width: auto;
    float: left;
  }

  .coll2 {
    width: auto;
    float: left;
  }

  .coll3 {
    width: 395px;
    float: left;
    height: 700px;
  }

  ul.zoomthumbs li img {
    width: auto;
    height: 160px;
  }
}

/* PRODUCT PAGE DARK SIDEBOX */
.product-sidebox {
  background: #222;
  height: 100%;
  color: #666;
  margin-left: 0px;

  @media (min-width: $screen-md-min) {
    margin-left: 20px;
  }

  @media (min-width: $screen-lg-min) {
    margin-left: 20px;
  }

  div.topbar {
    display: block;
    background: $maincolor;
    width: 100%;
    padding: 10px 15px;

    select.shareto {
      height: 25px;
      width: auto;
      padding: 0;
      color: #fff;
      line-height: 30px;
      text-transform: uppercase;
      font-size: 11px;
      background: transparent;
      border: 1px solid lighten($maincolor, 10%);
      border-radius: 0px;

      &:hover {
        border: 1px solid lighten($maincolor, 50%);
        outline: 0;
      }

      option {
        color: #333;
      }
    }

  }

  h1 {
    font-size: 22px;
    text-transform: uppercase;
    color: #fff;
  }

  div.id {
    text-transform: uppercase;
    color: #666;
  }

  div.rating {
    color: $maincolor;
  }

  div.line {
    height: 1px;
    width: 100%;
    background: #444;
  }

  .price {
    font-size: 30px;
    color: $maincolor;
  }

  .savesale2 {
    display: block;
    width: 72px;
    height: 72px;
    background: $maincolor;
    padding-top: 25px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 13px;
    border-radius: 50%;
    z-index: 20;
    position: absolute;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    @include transition(.2s);
  }

  .bordertop {
    border-top: 1px solid #444;
  }

  .borderbottom {
    border-bottom: 1px solid #444;
  }

  .brdleft {
    border-left: 1px solid #444;
  }

  .colorsquares {
    li {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #333;
      border: 1px solid #444;
      @include transition(.2s);

      &.active,
      &:hover,
      &:focus {
        border: 1px solid #fff;
        cursor: pointer;
      }
    }

    li.white {
      background: #fff;
    }

    li.black {
      background: #000;
    }
  }

  .sizesquares {
    li {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid #444;
      color: #fff;
      text-transform: uppercase;
      padding-top: 5px;
      text-align: center;
      @include transition(.2s);

      &.active,
      &:hover,
      &:focus {
        border: 1px solid #fff;
        cursor: pointer;
      }
    }
  }

  .input-group {
    input.input-number {
      background: transparent;
      border: 1px solid #444;
      color: #fff;

      &:focus {
        box-shadow: none;
        border: 1px solid $maincolor;
      }

      height:40px;
    }

    button.btn-number {
      background: transparent;
      border: 1px solid #444;
      color: #fff;
      height: 40px;
      border-radius: 0;
      @include transition(.2s);

      &:hover,
      &:focus {
        background: #444;
        outline: 0;
      }
    }

    button.btn-number[disabled="disabled"] {
      background: #333;
      color: #999;
    }
  }

  .sidebox-btns {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 50px);
    margin-left: 15px;

    button.btnaddtocart {
      display: block;
      width: 100%;
      border: 1px solid #444;
      background: transparent;
      border-radius: 0;
      color: #666;
      // padding:10px;
      text-transform: uppercase;
      @include transition(.2s);

      &:hover,
      &:focus {
        border: 1px solid #fff;
        color: #fff;
      }

      span {
        font-size: 26px;
        line-height: 33px;
      }

      i {
        font-style: normal;
        position: relative;
        top: -5px;
      }
    }

    button.btngift {
      display: block;
      float: left;
      width: 50%;
      border: 1px solid #444;
      background: transparent;
      border-radius: 0;
      color: #666;
      // padding:10px;
      text-transform: uppercase;
      @include transition(.2s);

      &:hover,
      &:focus {
        border: 1px solid #fff;
        color: #fff;
      }

      span {
        font-size: 26px;
        line-height: 33px;
      }

      i {
        font-style: normal;
        position: relative;
        top: -5px;
      }
    }

    button.btnfav {
      display: block;
      float: right;
      width: 50%;
      border: 1px solid #444;
      background: transparent;
      border-radius: 0;
      color: #666;
      // padding:10px;
      text-transform: uppercase;
      @include transition(.2s);

      &:hover,
      &:focus {
        border: 1px solid #fff;
        color: #fff;
      }

      span {
        font-size: 26px;
        line-height: 33px;
      }

      i {
        font-style: normal;
        position: relative;
        top: -5px;
      }
    }
  }
}

/* END OF PRODUCT PAGE DARK SIDEBOX */



//Tabs for shopping page
.av-tabs li span {
  display: inline-block;
  padding: 5px 0px;
  text-indent: -1000px;
}

@media (min-width:$screen-xs-min) {
  .av-tabs li span {
    display: inline-block;
    padding: 5px 0px;
    text-indent: 0px;
  }
}

@media (min-width:$screen-md-min) {
  .av-tabs li span {
    display: inline-block;
    padding: 0px 0px;
    text-indent: 0px;
  }
}

.av-tabs li a {
  background: #fcfcfc;
  color: #ccc;
  font-weight: bold;
  text-transform: uppercase;
  font-family: proxima_nova_rgbold;
  border-radius: 0;
  border: 1px solid #efefef;
  margin: 0px 0px 0px -1px;
}

.av-tabs li a:hover {
  border: 1px solid #efefef;
  background: #fcfcfc;
}

.av-tabs li.active a {
  background: #fff !important;
  color: #333;
  border-left: 1px solid #efefef !important;
  border-top: 1px solid #efefef !important;
}

.avtabbg {
  background: #fff;
}

//Comments
.comspacer {
  display: block;
  width: 20px;
  height: 20px;
  border-left: 1px solid #ddd;
  margin: 10px 0 10px 35px;
}

.comspacertop {
  display: block;
  width: 20px;
  height: 20px;
  border-left: 1px solid #ddd;
  margin: 10px 0 0 35px;
}

.comspacerbtm {
  display: block;
  width: 20px;
  height: 20px;
  border-left: 1px solid #ddd;
  margin: 0px 0 10px 35px;
}

.innerlines {
  position: relative;
  width: 80px;
  height: 80px;
  float: left;

  .vline {
    width: 1px;
    height: 100%;
    background: #ddd;

    margin-left: 35px;
  }

  .hline {
    width: 35px;
    height: 1px;
    background: #ddd;
    margin-left: 35px;
    position: absolute;
    top: 50%;
  }
}

.compull {
  position: relative;
  float: left;
  left: 0px;
  top: 0px;
  width: calc(100% - 90px);

}

.secondcomment {
  position: relative;
  float: left;
  width: calc(100% - 80px);
}

//End of Comments

.qtyheight {
  height: 47px;
}

.color1 {
  background: #f0e9cf;
}

.color2 {
  background: #af9c7c;
}

.color3 {
  background: #f18082;
}

.color4 {
  background: #424b82;
}

.savesale3 {
  display: block;
  width: 72px;
  height: 72px;
  background: #fff;
  padding-top: 25px;
  text-transform: uppercase;
  text-align: center;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  line-height: 13px;
  border-radius: 50%;
  z-index: 20;
  position: absolute;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  border: 1px solid #ddd;
  @include transition(.2s);
}

button.btnaddtocart {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  background: transparent;
  border-radius: 0;
  color: #666;
  text-transform: uppercase;
  @include transition(.2s);

  &:hover,
  &:focus {
    background: $maincolor;
    border: 1px solid $maincolor;
    color: #fff;
  }

  span {
    font-size: 22px;
    line-height: 33px;
  }

  i {
    font-style: normal;
    position: relative;
    top: -5px;
  }
}

//END OF SHOP PAGES


/*BLOG PAGES*/
.tags {
  span {
    display: inline-block;
    padding: 3px 5px;
    margin: 4px 0 0 0;
    background: #ccc;
    border-radius: 3px;
    color: white;
    font-size: 12px;
    @include transition(.2s);

    &:hover {
      background: $maincolor;
      cursor: pointer;
    }
  }
}

.blogdate {
  background: white;
  position: absolute;
  bottom: 0;
  right: 0px;
  padding: 7px 15px 0px 15px;
  text-align: center;
}

.blogdate.top {
  bottom: auto;
  top: 0;
}

.blogdate.left {
  right: auto;
  left: 0;
}

.sidethumb {
  width: 80px;
  height: 60px;
  background: #efefef;
  float: left;
  margin-right: 20px;
  border-radius: 2px;
  overflow: hidden;
}

.sidethumb+a {
  padding: 2px 0;

  &:hover {
    color: #333;
  }
}

.sidethumb+a+p {
  color: #ccc;
  font-size: 12px;
}

.sidethumb.right {
  float: right;
  margin-right: auto;
  margin-left: 20px;
}

//END OF BLOG



/*Maintenance mode*/

.bgmiddle {
  height: calc(100% - 130px);
}

.bgmiddle2 {
  height: calc(100% - 80px);
}

.bgmainop90 {
  background: $maincolor;
  background: rgba($maincolor, 0.9);
}

ul.maintenancesocial {
  li {
    display: inline-block;

    a {
      span {
        display: block;
        width: 27px;
        height: 27px;
        background: #999;
        color: #fff;
        padding: 4px 7px;
        border-radius: 50%;
        @include transition (.2s);

        &:hover {
          background: #efefef;
          color: #666;
        }
      }


    }
  }
}



//Newsletter
input.newscomingsoon {
  display: block;
  height: 50px;
  width: 100%;
  background: #fff;
  background: rgba(black, 0.10);
  box-shadow: none;
  border: 2px solid white;
  border: 2px solid rgba(white, 0.50);
  border-radius: 30px;
  font-size: 18px;
  font-family: proxima_novalight;
  color: #999;
  float: left;
  padding-left: 30px;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
}

input.newscomingsoon::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}

input.newscomingsoon:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

input.newscomingsoon::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}

input.newscomingsoon:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

input.newscomingsoon:hover,
input.newscomingsoon:focus {
  color: #fff;
  border: 2px solid white;
  box-shadow: none;
}

button.newscomingsoonbtn {
  display: block;
  height: 40px;
  padding: 0 20px;
  background-color: $maincolor;
  font-size: 18px;

  span {
    font-size: 16px;
    position: relative;
    top: -2px;
  }

  color:darken($maincolor, 15%);
  border:1px solid darken($maincolor, 5%);
  border-radius:30px;
  float:right;
  position:absolute;
  top:5px;
  right:5px;
  @include transition(.3s);

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  }
}


//Countdown
.is-countdown {
  // border: 1px solid #ccc;
  // background-color: #eee;
}

.countdown-rtl {
  direction: rtl;
}

.countdown-holding span {
  color: #888;
}

.countdown-row {
  clear: both;
  width: 100%;
  padding: 0px 2px;
  text-align: center;
}

.countdown-show1 .countdown-section {
  width: 98%;
}

.countdown-show2 .countdown-section {
  width: 48%;
}

.countdown-show3 .countdown-section {
  width: 32.5%;
}

.countdown-show4 .countdown-section {
  width: 24.5%;
}

.countdown-show5 .countdown-section {
  width: 19.5%;
}

.countdown-show6 .countdown-section {
  width: 16.25%;
}

.countdown-show7 .countdown-section {
  width: 14%;
}

.countdown-section {
  display: block;
  float: left;
  font-size: 100%;

  @media (min-width: $screen-xs-min) {
    font-size: 130%;
  }

  @media (min-width: $screen-md-min) {
    font-size: 175%;
  }

  text-align: center;
}

.countdown-amount {
  font-size: 200%;
}

// .countdown-section:nth-child(1) .countdown-amount:after {  content:':'; position:relative; left:30px;}
.countdown-section:nth-child(2) .countdown-amount:after {
  content: ':';
  position: relative;
  left: 40px;
}

.countdown-section:nth-child(3) .countdown-amount:after {
  content: ':';
  position: relative;
  left: 40px;
}

.countdown-period {
  font-size: 60%;
  display: block;
}

.countdown-descr {
  display: block;
  width: 100%;
}

.newscmscontainer {
  max-width: 400px;
}

.timercontainer {
  max-width: 600px;
}

.middletimer {
  text-align: center;
  position: absolute;
  top: 5%;
  transform: none;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;

  // background:red;
  @media (min-width: $screen-md-min) {
    top: 50%;
    transform: translateY(-50%);
  }
}

/*End of Maintenance mode*/

/*Search page*/
ul.searchresults {
  list-style-type: circle;
  list-style-position: inside;
  font-size: 25px;
  color: #999;

  li {
    margin-bottom: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #efefef;

    h3 {
      font-family: 'proxima_nova_rgregular';
      margin-bottom: 0px;
      font-size: 22px;
      color: #333;
    }

    div {
      display: block;
      font-size: 12px;
      color: #999;
      margin-bottom: 15px;
      padding-left: 25px;
      white-space: nowrap;
    }

    p {
      font-size: 14px;
      padding-left: 25px;
      color: #333;
    }
  }
}

ul.searchresults.nobullets {
  list-style-type: none;

  div,
  p {
    padding-left: 0px;
  }
}

/*End of Search page*/