// Sliders
.home_banner {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: url("../images/slider/home.jpg") no-repeat center center;
        background-size: cover;
        position: absolute;
        filter: blur(11px);
        z-index: 1;

    }

    .tp-banner {
        background: rgba(0, 0, 0, .4);
        z-index: 2;
        position: relative;
    }
}

.feature.slidersubtitle {
    font-size: 25px !important;
    font-weight: 900 !important;
    text-transform: capitalize;
    min-width: 391px !important;
    width: max-content !important;
    text-align: center !important;
    background-color: #fff;
    color: #000;

}

.rev_slider .fixedBG {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:after {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        /* black overlay with 50% transparency */
        background: #000;
        opacity: .7;
    }
}

.tp-loader[class*=spinner] {
    display: none;
    visibility: hidden;
}

.mouseScroll {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 37px;
    text-align: center;
    z-index: 999;
    animation: mouseMoving .7s alternate infinite;

    @keyframes mouseMoving {
        from {
            bottom: 37px;
        }

        to {
            bottom: 20px;
        }

    }

    @media (max-width:1000px) {
        @keyframes mouseMoving {
            0% {
                bottom: -2px;
            }

            100% {
                bottom: 9px;
            }
        }
    }
}


@media(min-width:1050px) {
    .slidertitle {
        font-size: 44px !important;
    }
}

@media(max-width:1040px) {
    .slidertitle {
        font-size: 40px !important;
    }

    .slidersubtitle,
    .feature.slidersubtitle {
        font-size: 24px !important;
    }
}

@media(max-width:440px) {
    .slidertitle {
        font-size: 30px !important;
        line-height: 30px !important;

    }

    .slidersubtitle,
    .feature.slidersubtitle {
        font-size: 20px !important;
    }
}