// ------------------------
//Base
// ------------------------
body {
    .pt0 {
        padding-top: 0 !important;
    }

    .pb0 {
        padding-bottom: 0 !important;
    }

    .modal-title,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: capitalize;
    }

    .btn {
        width: fit-content;
    }



    // loader
    #status {
        background-image: url("../images/preloaders/square_7.gif");
    }

    @media (max-width:778px) {
        & {
            .text-sm-center {
                text-align: center !important;
            }

            .pr100 {
                padding-right: 10px;
            }

            .pl70 {
                padding-left: 10px;
            }

            .p100 {
                padding: 100px 5px;
            }
        }
    }

    @media (max-width:1040px) {
        & {
            .container {
                width: 90%;
            }

            .h700 {
                min-height: 700px;
                height: fit-content !important;
            }
        }
    }

}

main {
    min-height: 1500px;
}

// maintenance
.maintenance {
    &:before {
        @extend %overlay_dark;
    }

    >div {
        z-index: 2;

        .bgmainop90 {
            background: rgba($maincolor, 0.7);
            z-index: 2;
        }
    }
}

// Section Half image
.bgHalfImage {
    .aboutteamlist {
        li {
            height: 140px;

            .circlesmall {
                text-align: center;
            }
        }
    }
}

.twitter-text a:hover,
h1 a:hover {
    text-decoration: none;
    color: var(--color-main);
}

// socialLinks
.socialLinks {
    li {
        display: inline-block;
        font-size: 14px;

    }
}


// image as icon 
.img_icon {
    padding-top: 26px;

    img {
        width: 40px;
        height: 40px;

    }
}

// squareDesc
.accsquare {
    height: 421px;

}