// =================
// Logo Text
// =================

.logo_text {

    font-size: 22px;
    text-align: left;
    color: #333;
    font-family: proxima_nova_rgbold;
    margin: 0;
    line-height: 1;

    i {
        margin-right: 10px;
    }

    footer & {
        color: #fff !important;
        padding: 5px 0 20px;
    }

    header & {
        padding: 6px 0px;
        color: #fff;

    }

    .navbg & {
        color: #333;
        padding: 9px 15px;
        font-size: 21px;
    }

    .dark & {
        color: #333;
    }

    @media (max-width: 770px) {
        header & {
            color: #333;
        }
    }

    @media (max-width: 755px) {
        header & {
            text-align: center;
        }
    }

}

// =================
// Sections
// =================

// Transparent Section 
.transparent-section {
    background: #222222;
    min-height: 490px;
    height: fit-content;
    margin: 0 auto;
    width: 100%;
    color: #fff;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &:before {
        @extend %overlay_dark;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    [class*=prlx] {
        background-color: #222;
        background-position: 100% 0px;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 120%;
        position: absolute;
    }
}

// =================
// text color( main colors)
// =================

.color {
    color: var(--color-main);
}

// =================
// flex
// =================

.flex-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

// =================
// Hidden
// =================

.overflow-hidden {
    overflow: hidden;
}

// =================
// Overlay
// =================

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000005c;
    display: flex;
    justify-content: center;
    align-items: center;
}

// =================
// Margins && paddings
// =================
@for $i from 0 to 50 {

    // margins
    .m#{$i} {
        margin: #{$i};
    }

    .mt#{$i} {
        margin-top:#{$i}px;
    }

    .mb#{$i} {
        margin-bottom:#{$i}px;
    }

    .ml#{$i} {
        margin-left:#{$i}px;
    }

    .mr#{$i} {
        margin-right:#{$i}px;
    }

    // Padding
    .p#{$i} {
        padding: #{$i};
    }

    .pb#{$i} {
        padding-bottom:#{$i}px;
    }

    .pt#{$i} {
        padding-top:#{$i}px;
    }

    .pr#{$i} {
        padding-right:#{$i}px;
    }

    .ptl#{$i} {
        padding-left:#{$i}px;
    }
}

// =================
// Display
// =================
.d-none {
    display: none;
}

// =================
// Lists
// =================
.list-styled {
    li {
        list-style: inside;

    }
}