header {
    a {
        text-transform: capitalize;
    }

    .mainmenu {
        float: right;
        border-top: 0px;
        background: transparent;
        padding-right: 45px;
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
        & {
            .mainmenu {
                padding-right: 0;

            }
        }
    }
}