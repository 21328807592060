.video_play {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &.transparent-section {
        &::before {
            opacity: .7;
            background: #4a4a4a;

        }
    }
}


video#video_iframe {
    display: block;

    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, .25),
        0 10px 5px 0 rgba(0, 0, 0, .22);
    // max-height: 336px;
    // max-width: 100%;
    // margin: auto; 
    // width: auto;
    // height: 100%;
    width: 100%;
    height: auto;


    // .modal & {
    //     max-height: 100%;
    //     max-width: 100%;
    // }

    // @media (max-width:1024px) {
    //     & {
    //         max-height: 190px;
    //     }
    // }

    // @media (max-width:450px) {

    //     &,
    //     .modal & {

    //         max-height: 162px;
    //     }
    // }


}

.video_cover {
    position: relative;
    height: 500px;
    cursor: pointer;

    &:focus {
        .cover {
            display: none;
        }
    }

    .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.play {
    color: white;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    font-size: 30px;
    cursor: pointer;


    &:hover {
        transform: scale(1.1);

    }
}

#video_iframe+.overlay {
    background: transparent;
}