#partners {
    ul {
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }

    img {
        width: auto;
        height: 75px;
        display: block;
        margin: auto;
        filter: grayscale(100%);
        transition: all .5s ease;

        &:hover {
            filter: grayscale(0);

        }
    }
}