// ------------------------
// Fonts Files
// ------------------------
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Anonymous+Pro:400,700");

// ------------------------
// Common Css Files 
// ------------------------
/* clean-css ignore:start */
// Theme Style
@import 'theme/scss/main';
// FONTELLO
@import url("../fonts/fontello/css/fontello.css");
@import url("../fonts/fontello/css/animation.css");
// DRIPICONS
@import url("../fonts/dripicons/webfont.css");
// SIMPLE LINE ICONS
@import url("../fonts/simple-line-icons/simple-line-icons.css");
// THEMIFY ICONS
@import url("../fonts/themify-icons/themify-icons.css");
// FONTASTIC ICONS
@import url("../fonts/fontastic/styles.css");
/* clean-css ignore:end */

//  SLIDER REVOLUTION 4.x CSS SETTINGS 
@import "./theme/scss/extralayers";

//  CarouFredSel 
@import "./theme/scss/caroufredsel";
//  WOW.JS REVEAL ANIMATIONS 
@import "./theme/scss/animate";
//  PYE CHART 
@import "./theme/scss/jquery.easy-pie-chart";

// Hovereffect
@import url("https://fonts.googleapis.com/css?family=Raleway:400,800,300");
@import "./theme/scss/HoverEffectIdeas/demo";
@import "./theme/scss/HoverEffectIdeas/set1";
// lightcase
@import url("../plugins/lightcase/css/lightcase.css");

// ------------------------
// Schoolia Style 
// ------------------------
/*! purgecss start ignore */
@import 'schoolia/schoolia';

// Colors
$maincolor:#fecd06;
$secondcolor:#4b4b4b;

:root {
    --color-main: #{$maincolor};
    --color-secondary: #{$secondcolor};
}

/*! purgecss end ignore */