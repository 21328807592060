.aboutSmall_section {
    .join_box {
        figure.effect-bubba2 {
            background-color: #000;

            h2 {
                padding-top: 56%;
            }

            &:hover h2,
            &:hover p {
                transform: translate3d(0, -65px, 0);
            }
        }
    }

    .fb-tabs li a {
        color: #000;
    }

    #aboutSmallTabContent {
        ul {
            list-style-type: disc;
            margin-left: 30px;


        }
    }

    // ------- heights ------- 
    // Tabs
    #aboutSmallTabContent {
        height: 424px;
    }

    // Join Us Box
    .join_box {
        figure {
            height: 218px;
        }

        .content {
            height: 257px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;

        }
    }

    // Side List 
    #aboutSmallTab {
        height: 476px;
        background: #222;
    }

    @media (max-width:450px) {
        & {
            .row>div {

                float: none;
                clear: both;



            }

            #aboutSmallTabContent {
                height: fit-content;
            }

            .fb-tabs li span {
                display: inline-block;

            }

        }
    }
}