/*###########*/ 
/*  PAGE 16  */
/*###########*/ 

.sidetransition{
  width:50%;
  height:100%;
  background:#fff;
  display:none;
  position:fixed;
  right:0px;
  z-index:10000;

  ul{
   font-size:30px;
   text-transform:uppercase;
   font-family:"proxima_novalight";
    li a{
      display:block;
      width:100%;
      padding:20px;
      background:#fff;
      color:#999;
      &:hover{
        color:#222;
        background:#fcfcfc;
      }
    }
  }
}
.openmenu{
  background:transparent;
}
.grid-height{
  background:#000;
  overflow:hidden;
  @include transition(.2s);
  img{
    opacity:0.2;
    @include filtergray(100%);
    @include transition(.2s);
    -ms-transform: scale(1,1); 
    -webkit-transform: scale(1,1); 
    transform: scale(1,1); 
  }
  

  h1{/*font-family:'Anonymous Pro';*/ font-size:30px; background:#55c7f6; float:left; clear:left; position:absolute; bottom:-100px; z-index:100; padding:5px 20px; color:#fff; @include transition(.6s);}
  p {/*font-family:'Anonymous Pro';*/ font-size:14px; background:#55c7f6; float:left; clear:left; position:absolute; bottom:-100px; z-index:100; padding:5px 20px; color:#fff; @include transition(.2s);}
  span{width:100%; height:100%; background:#000; opacity:0; display:block; position:absolute; z-index:100;}
  &:hover{
    h1{bottom:50px;}
    p{bottom:10px;}
    span{opacity:0.5;}
    img{
      opacity:1;
      @include filtergray(0%);
      -ms-transform: scale(1.1,1.1); 
      -webkit-transform: scale(1.1,1.1); 
      transform: scale(1.1,1.1); 
    }
  }
  
}

#googleMap iframe {
   width: 100%;
}
#googleMap {
   height: 100%;
}
#googleMap img { max-width: none; }
.gmnoprint{display:none;}

ul.roundsocial{
  li {
    display:inline-block;
    padding:0 10px;
    a{
      display:block;
      width:50px;
      height:50px;
      border:1px solid #fff;
      border-radius:100px;
      padding-top:10px;
      padding-left:2px;
      font-size:20px;
      color:white;
      &:hover{
        background:#55c7f6;
        border:1px solid #55c7f6;
      }
    }
    a.gp:hover{ background:#f69560; border:1px solid #f69560;}
    a.tw:hover{ background:#55c7f6; border:1px solid #55c7f6;}
    a.fb:hover{ background:#2e63a4; border:1px solid #2e63a4;}
  }

}




//##############
// SQUARE FLIP
//##############
.square-flip{
-webkit-perspective: 1000;
-moz-perspective: 1000;
-ms-perspective: 1000;
perspective: 1000;

-webkit-transform: perspective(1000px);
-moz-transform: perspective(1000px);
-ms-transform: perspective(1000px);
transform: perspective(1000px);

-webkit-transform-style: preserve-3d; 
-moz-transform-style: preserve-3d; 
-ms-transform-style: preserve-3d; 
transform-style: preserve-3d; 

border:0px solid #efefef;

}
.square-flip,.square{
  width:100%;
  height:100%;
}
.square{
  background:white;
}
.square-flip .square{
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);

  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}
.square-flip.active .square{
  -webkit-transform: rotateX(-10deg);
  -moz-transform: rotateX(-10deg);
  -o-transform: rotateX(-10deg);
  -ms-transform: rotateX(-10deg);
  transform: rotateX(-10deg);
  margin-top:-100px;
}
/* end of flip */

.heartpos{
  position:fixed; bottom:50px; left:50px; font-size:30px; z-index:1000;
}
.effecttrigger i{ display:block; font-size:18px; text-align:center;  @include transition(.2s);}
.effecttrigger:hover i{font-size:24px; margin-left:-3px; margin-bottom:-3px;}





// End of page 16