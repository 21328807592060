// form
form {
    .btn.disabled {
        display: block !important;
    }

    .form-control-feedback {

        color: transparent;
    }

    .form-group[class*=col-] {
        .form-control-feedback {
            right: 17px;
        }
    }

    .alert {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

    }
}

.g-recaptcha {
    width: fit-content;
    margin: auto;
    display: none;
}