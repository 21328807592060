// Mixins 
@mixin transition($time) {
  -webkit-transition: $time;
  -moz-transition: $time;
  -ms-transition: $time;
  -o-transition: $time;
  transition: $time;
}


//padding
@mixin padding ($position,$value){
  @if $position == all{
    padding-top:$value;
    padding-bottom:$value;
    padding-left:$value;
    padding-right:$value;
  }
  @else if $position == left{
    padding-left:$value;
  }
  @else if $position == right{
    padding-right:$value;
  }
  @else if $position == bottom{
    padding-bottom:$value;
  }
  @else if $position == top{
    padding-top:$value;
  }
  @else if $position == top-bottom{
    padding-top:$value;
    padding-bottom:$value;
  }
  @else if $position == left-right{
    padding-left:$value;
    padding-right:$value;
  }
}


//margin
@mixin margin ($position,$value){
  @if $position == all{
    margin-top:$value;
    margin-bottom:$value;
    margin-left:$value;
    margin-right:$value;
  }
  @else if $position == left{
    margin-left:$value;
  }
  @else if $position == right{
    margin-right:$value;
  }
  @else if $position == bottom{
    margin-bottom:$value;
  }
  @else if $position == top{
    margin-top:$value;
  }
  @else if $position == top-bottom{
    margin-top:$value;
    margin-bottom:$value;
  }
  @else if $position == left-right{
    margin-left:$value;
    margin-right:$value;
  }
}



//margin
@mixin border ($position,$size,$color){
  @if $position == all{
    border-top:$size solid $color;
    border-bottom:$size solid $color;
    border-left:$size solid $color;
    border-right:$size solid $color;
  }
  @else if $position == left{
    border-left:$size solid $color;
  }
  @else if $position == right{
    border-right:$size solid $color;
  }
  @else if $position == top{
    border-top:$size solid $color;
  }
  @else if $position == bottom{
    border-bottom:$size solid $color;
  }
  @else if $position == top-bottom{
    border-top:$size solid $color;
    border-bottom:$size solid $color;
  }
  @else if $position == left-right{
    border-left:$size solid $color;
    border-right:$size solid $color;
  }
}




//Rotate

@mixin rotate ($deg){
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
  transform-origin: 50% 50%;
}


@mixin filtergray ($percent){
  -webkit-filter: grayscale($percent); 
  -moz-filter: grayscale($percent); 
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: grayscale($percent);
}