ul {
    &.squares {
        .clearfix {
            border: 0;
            display: block;

        }

        $border-value: 1px solid #efefef;

        li {
            height: 290px;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            >a {
                display: block;
            }

            p {
                min-height: 76px;


            }



            &.col-lg-4 {

                // First Col in the row
                &:nth-of-type(3n-2) {
                    border-left: 0;
                    border-right: 0;

                }

                // Middle Col in the row
                &:nth-of-type(3n-1) {


                    border-left: $border-value;
                    border-right: $border-value;
                }

                // Third|last Col in the row
                &:nth-of-type(3n-3) {
                    border-left: 0;


                }

                // Last Row 
                &:nth-last-child(-n+3) {
                    border-bottom: 0;

                }

                // Not Last row
                &:not(:nth-last-child(-n+3)) {
                    border-bottom: $border-value;
                }

            }

            &.col-lg-4,
            &.col-sm-6 {
                @media(max-width:1024px)and (min-width:450px) {


                    & {
                        &:nth-child(odd) {
                            border-right: $border-value;
                            border-left: 0;
                        }

                        &:nth-child(even) {
                            border-right: 0;
                            border-left: 0;
                        }

                        // Last Row
                        &:nth-last-child(-n+2) {
                            border-bottom: 0;

                        }

                        // Not Last row
                        &:not(:nth-last-child(-n+2)) {
                            border-bottom: $border-value;
                        }

                    }

                }

                @media(max-width:449px) {
                    & {
                        border-left: 0;
                        border-right: 0;

                        // Last Row 
                        &:last-child {
                            border-bottom: 0;

                        }

                        // Not Last row
                        &:not(:last-child) {
                            border-bottom: $border-value;
                        }

                    }

                }
            }
        }
    }
}