// ------------------------
// Main Colors
// ------------------------
$maincolor:#fecd06;
$secondcolor:#4b4b4b;

//link colors
$linkcolor:$maincolor;
$linkhovercolor:$secondcolor;

//NAVIGATION
$fontmenu:"Open Sans";
$menusize:16px;
$menuweight:"";
$textcase:"";
$Hpadding:15px;
$Vpadding:15px;

//FONT
$mainfont:"sans-serif";
$fontsize:14px;
$fontcolor:#222;

//TITLE FONT
$titlefont:"proxima_novalight";

//SECTION SPACING
$sectionspacing:100px;

// Here are some colors to choose from
// cealk: #c1bf94
// turquoise: #669999
// turquoise2: #99cccc
// lightturquoise: #1fc4d3
// lightgreen: #76f099
// green: #00cc66
// green2: #93c359
// green3: #95ce92
// blue: #13acd8
// blue2: #0099cc
// blue3: #72b9ea
// blue4: #76bbdf
// blue dark: #233e83
// orange: #ff9933
// orange2: #ff9966
// orange3: #ff6633

%overlay_dark {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .6;
    z-index: 1;
}