.opacitymd{opacity:0.9;}
.cd-dropdown {
    a,li,
     {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
    }
}

.megadrop {
    height: 100px;
    background: #ffffff;
}
.mdlogo{
    display: inline-block;
    position: relative;
    height: 40px;
    margin: 30px 0 0 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform:uppercase;
    font-size:24px;
}
.mdbag{
    display: inline-block;
    position: relative;
    height: 40px;
    margin: 30px 5% 0 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform:uppercase;
    font-size:24px;
    float:right;
    span{ color:#333; }
}
.cd-dropdown-wrapper {
    display: inline-block;
    position: relative;
    height: 40px;
    margin: 30px 0 0 5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


}
.cd-dropdown-trigger {
    display: block;
    position: relative;
    padding: 0 36px 0 10px;
    line-height: 40px;
    background-color: #fff;
    color: #333;
    border-radius: 3px;
}
.no-touch .cd-dropdown-trigger:hover {
    color: #333;
    background-color: #fff;
}
// .cd-dropdown-trigger::before, .cd-dropdown-trigger::after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     margin-top: -1px;
//     height: 2px;
//     width: 9px;
//     background: #333;
//     -webkit-backface-visibility: hidden;
//     backface-visibility: hidden;
//     -webkit-transition: width 0.3s, -webkit-transform 0.3s;
//     -moz-transition: width 0.3s, -moz-transform 0.3s;
//     transition: width 0.3s, transform 0.3s;
// }
// .cd-dropdown-trigger::before {
//     right: 22px;
//     -webkit-transform: rotate(45deg);
//     -moz-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     -o-transform: rotate(45deg);
//     transform: rotate(45deg);
// }
// .cd-dropdown-trigger::after {
//     right: 17px;
//     -webkit-transform: rotate(-45deg);
//     -moz-transform: rotate(-45deg);
//     -ms-transform: rotate(-45deg);
//     -o-transform: rotate(-45deg);
//     transform: rotate(-45deg);
// }
// @media only screen and (min-width: 1024px) {
//     .cd-dropdown-trigger {
//     font-size: 1.5rem;
// }
// .cd-dropdown-trigger.dropdown-is-active {
//     background-color: #efefef;
// }
// .no-touch .cd-dropdown-trigger.dropdown-is-active:hover {
//     background-color: #fcfcfc;
// }
// .cd-dropdown-trigger.dropdown-is-active::before, .cd-dropdown-trigger.dropdown-is-active::after {
//     width: 14px;
// }
// .cd-dropdown-trigger.dropdown-is-active::before {
//     -webkit-transform: translateX(5px) rotate(-45deg);
//     -moz-transform: translateX(5px) rotate(-45deg);
//     -ms-transform: translateX(5px) rotate(-45deg);
//     -o-transform: translateX(5px) rotate(-45deg);
//     transform: translateX(5px) rotate(-45deg);
// }
// .cd-dropdown-trigger.dropdown-is-active::after {
//     -webkit-transform: rotate(45deg);
//     -moz-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     -o-transform: rotate(45deg);
//     transform: rotate(45deg);
// }
//}
.cd-dropdown h2, .cd-dropdown-content a, .cd-dropdown-content ul a {
    height: 50px;
    line-height: 50px;
}
@media only screen and (min-width: 768px) {
    .cd-dropdown h2, .cd-dropdown-content a, .cd-dropdown-content ul a {
    height: 60px;
    line-height: 60px;
}
}
@media only screen and (min-width: 1024px) {
    .cd-dropdown h2, .cd-dropdown-content a, .cd-dropdown-content ul a {
    height: 50px;
    line-height: 50px;
}
}
.cd-dropdown h2, .cd-dropdown-content a, .cd-dropdown-content ul a, .cd-dropdown-content .cd-divider {
    padding: 0 20px;
}
.cd-dropdown {
    position: fixed;
    z-index: 10000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    color: #ffffff;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0.5s;
    -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0.5s;
    transition: transform 0.5s 0s, visibility 0s 0.5s;
}
.cd-dropdown h2 {
    position: relative;
    z-index: 1;
    color: #444;
    font-size:18px;

    text-transform:uppercase;
    background-color: #333;
    border-bottom: 1px solid #444;
}
.cd-dropdown .cd-close {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    height: 50px;
    width: 50px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: transparent;
}
.cd-dropdown .cd-close::after, .cd-dropdown .cd-close::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    height: 20px;
    width: 2px;
    background-color: #ffffff;
}
.cd-dropdown .cd-close::after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.cd-dropdown .cd-close::before {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
.cd-dropdown.dropdown-is-active {
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0s;
    -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0s;
    transition: transform 0.5s 0s, visibility 0s 0s;
}
@media only screen and (min-width: 768px) {
}
@media only screen and (min-width: 1024px) {
    .cd-dropdown {
    position: absolute;
    top: calc(100% - 2px);
    height: auto;
    width: auto;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    background-color: #ffffff;
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
}
.open-to-left .cd-dropdown {
    right: 0;
    left: auto;
}
.cd-dropdown h2, .cd-dropdown .cd-close {
    display: none;
}
.cd-dropdown.dropdown-is-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity 0.3s 0s, visibility 0.3s 0s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0.3s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0.3s 0s, transform 0.3s 0s;
}
}.cd-dropdown-content, .cd-dropdown-content ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
    padding-top: 50px;
}
.cd-dropdown-content a, .cd-dropdown-content ul a {
    display: block;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top-width: 1px;
    border-color: #444;
    border-style: solid;
}
.cd-dropdown-content li:first-of-type>a, .cd-dropdown-content ul li:first-of-type>a {
    border-top-width: 0;
}
.cd-dropdown-content li:last-of-type>a, .cd-dropdown-content ul li:last-of-type>a {
    border-bottom-width: 1px;
}
.cd-dropdown-content .cd-divider, .cd-dropdown-content ul .cd-divider {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #222;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: #555;
    text-transform: uppercase;
}
.cd-dropdown-content .cd-divider+li>a, .cd-dropdown-content ul .cd-divider+li>a {
    border-top-width: 0;
}
.cd-dropdown-content a, .cd-dropdown-content .cd-search, .cd-dropdown-content .cd-divider, .cd-dropdown-content ul a, .cd-dropdown-content ul .cd-search, .cd-dropdown-content ul .cd-divider {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
}
.cd-dropdown-content.is-hidden, .cd-dropdown-content ul.is-hidden {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}
.cd-dropdown-content.is-hidden>li>a, .cd-dropdown-content.is-hidden>li>.cd-search, .cd-dropdown-content.is-hidden>.cd-divider, .cd-dropdown-content.move-out>li>a, .cd-dropdown-content.move-out>li>.cd-search, .cd-dropdown-content.move-out>.cd-divider, .cd-dropdown-content ul.is-hidden>li>a, .cd-dropdown-content ul.is-hidden>li>.cd-search, .cd-dropdown-content ul.is-hidden>.cd-divider, .cd-dropdown-content ul.move-out>li>a, .cd-dropdown-content ul.move-out>li>.cd-search, .cd-dropdown-content ul.move-out>.cd-divider {
    opacity: 0;
}
.cd-dropdown-content.move-out>li>a, .cd-dropdown-content.move-out>li>.cd-search, .cd-dropdown-content.move-out>.cd-divider, .cd-dropdown-content ul.move-out>li>a, .cd-dropdown-content ul.move-out>li>.cd-search, .cd-dropdown-content ul.move-out>.cd-divider {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}
.dropdown-is-active .cd-dropdown-content, .dropdown-is-active .cd-dropdown-content ul {
    -webkit-overflow-scrolling: touch;
}
@media only screen and (min-width: 768px) {
    .cd-dropdown-content, .cd-dropdown-content ul {
    padding-top: 60px;
}
.cd-dropdown-content a, .cd-dropdown-content ul a {
    font-size: 1.8rem;
}
}@media only screen and (min-width: 1024px) {
    .cd-dropdown-content, .cd-dropdown-content ul {
    padding-top: 0;
    overflow: visible;
}
.cd-dropdown-content a, .cd-dropdown-content ul a {
    color: #333;
    height: 50px;
    line-height: 50px;
    font-size: 1.5rem;
    border-color: #ebebeb;
}
.cd-dropdown-content .cd-divider, .cd-dropdown-content ul .cd-divider {
    background-color: transparent;
    color: #b3b3b3;
    border-top: 1px solid #ebebeb;
}
.cd-dropdown-content .cd-divider+li>a, .cd-dropdown-content ul .cd-divider+li>a {
    border-top-width: 1px;
}
.cd-dropdown-content.is-hidden>li>a, .cd-dropdown-content.is-hidden>li>.cd-search, .cd-dropdown-content.is-hidden>.cd-divider, .cd-dropdown-content.move-out>li>a, .cd-dropdown-content.move-out>li>.cd-search, .cd-dropdown-content.move-out>.cd-divider, .cd-dropdown-content ul.is-hidden>li>a, .cd-dropdown-content ul.is-hidden>li>.cd-search, .cd-dropdown-content ul.is-hidden>.cd-divider, .cd-dropdown-content ul.move-out>li>a, .cd-dropdown-content ul.move-out>li>.cd-search, .cd-dropdown-content ul.move-out>.cd-divider {
    opacity: 1;
}
}.cd-dropdown-content .see-all a {
    color: #3f8654;
}
.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item, .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
    height: 80px;
    line-height: 80px;
}
.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item h3, .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size:14px;
}
.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
    padding-left: 90px;
}
.cd-dropdown-content .cd-dropdown-gallery img {
    position: absolute;
    display: block;
    height: 40px;
    width: auto;
    left: 20px;
    top: 50%;
    margin-top: -20px;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
    padding-left: 75px;
    position: relative;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
    color: #333;
    font-size: 1.3rem;
    display: none;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px 40px;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-1::before {
    background-image: url('../images/tm22/nucleo-icon-1.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-2::before {
    background-image: url('../images/tm22/nucleo-icon-2.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-3::before {
    background-image: url('../images/tm22/nucleo-icon-3.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-4::before {
    background-image: url('../images/tm22/nucleo-icon-4.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-5::before {
    background-image: url('../images/tm22/nucleo-icon-5.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-6::before {
    background-image: url('../images/tm22/nucleo-icon-6.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-7::before {
    background-image: url('../images/tm22/nucleo-icon-7.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-8::before {
    background-image: url('../images/tm22/nucleo-icon-8.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-9::before {
    background-image: url('../images/tm22/nucleo-icon-9.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-10::before {
    background-image: url('../images/tm22/nucleo-icon-10.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-11::before {
    background-image: url('../images/tm22/nucleo-icon-11.svg');
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-12::before {
    background-image: url('../images/tm22/nucleo-icon-12.svg');
}
@media only screen and (min-width: 1024px) {
    .cd-dropdown-content {
    position: static;
    height: auto;
    width: 280px;
}
.cd-dropdown-content>li:last-of-type a {
    border-bottom: none;
}
.no-touch .cd-dropdown-content>li:not(.has-children) a:hover {
    color: #3f8654;
}
.cd-dropdown-content.move-out>li>a, .cd-dropdown-content.move-out>li>.cd-search, .cd-dropdown-content.move-out>.cd-divider {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
.cd-dropdown-content .cd-secondary-dropdown, .cd-dropdown-content .cd-dropdown-gallery, .cd-dropdown-content .cd-dropdown-icons {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    left: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
}
.cd-dropdown-content .cd-secondary-dropdown:after, .cd-dropdown-content .cd-dropdown-gallery:after, .cd-dropdown-content .cd-dropdown-icons:after {
    content: "";
    display: table;
    clear: both;
}
.open-to-left .cd-dropdown-content .cd-secondary-dropdown, .open-to-left .cd-dropdown-content .cd-dropdown-gallery, .open-to-left .cd-dropdown-content .cd-dropdown-icons {
    left: auto;
    right: 100%}
.cd-dropdown-content .cd-secondary-dropdown.is-hidden, .cd-dropdown-content .cd-dropdown-gallery.is-hidden, .cd-dropdown-content .cd-dropdown-icons.is-hidden {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
.cd-dropdown-content .cd-secondary-dropdown.fade-in, .cd-dropdown-content .cd-dropdown-gallery.fade-in, .cd-dropdown-content .cd-dropdown-icons.fade-in {
    -webkit-animation: cd-fade-in 0.2s;
    -moz-animation: cd-fade-in 0.2s;
    animation: cd-fade-in 0.2s;
}
.cd-dropdown-content .cd-secondary-dropdown.fade-out, .cd-dropdown-content .cd-dropdown-gallery.fade-out, .cd-dropdown-content .cd-dropdown-icons.fade-out {
    -webkit-animation: cd-fade-out 0.2s;
    -moz-animation: cd-fade-out 0.2s;
    animation: cd-fade-out 0.2s;
}
.cd-dropdown-content .cd-secondary-dropdown>.go-back, .cd-dropdown-content .cd-dropdown-gallery>.go-back, .cd-dropdown-content .cd-dropdown-icons>.go-back {
    display: none;
}
.cd-dropdown-content .cd-secondary-dropdown>.see-all, .cd-dropdown-content .cd-dropdown-gallery>.see-all, .cd-dropdown-content .cd-dropdown-icons>.see-all {
    position: absolute;
    bottom: 20px;
    height: 45px;
    text-align: center;
}
.cd-dropdown-content .cd-secondary-dropdown>.see-all a, .cd-dropdown-content .cd-dropdown-gallery>.see-all a, .cd-dropdown-content .cd-dropdown-icons>.see-all a {
    margin: 0;
    height: 100%;
    line-height: 45px;
    background: #ebebeb;
    pointer-events: auto;
    -webkit-transition: color 0.2s, background-color 0.2s;
    -moz-transition: color 0.2s, background-color 0.2s;
    transition: color 0.2s, background-color 0.2s;
}
.no-touch .cd-dropdown-content .cd-secondary-dropdown>.see-all a:hover, .no-touch .cd-dropdown-content .cd-dropdown-gallery>.see-all a:hover, .no-touch .cd-dropdown-content .cd-dropdown-icons>.see-all a:hover {
    color: #ffffff;
    background-color: #333;
}
.cd-dropdown-content .cd-secondary-dropdown .cd-dropdown-item, .cd-dropdown-content .cd-secondary-dropdown a, .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item, .cd-dropdown-content .cd-dropdown-gallery a, .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item, .cd-dropdown-content .cd-dropdown-icons a {
    border: none;
}
.cd-dropdown-content .cd-dropdown-gallery, .cd-dropdown-content .cd-dropdown-icons {
    padding: 20px 30px 100px;
}
.cd-dropdown-content .cd-dropdown-gallery>.see-all, .cd-dropdown-content .cd-dropdown-icons>.see-all {
    width: calc(100% - 60px);
}
.cd-dropdown-content .cd-dropdown-icons>li, .cd-dropdown-content .cd-secondary-dropdown>li {
    width: 50%;
    float: left;
}
.cd-dropdown-content .cd-secondary-dropdown {
    overflow: hidden;
    width: 550px;
    padding-bottom: 65px;
}
.cd-dropdown-content .cd-secondary-dropdown::before {
    position: absolute;
    content: '';
    top: 290px;
    left: 15px;
    height: 1px;
    width: 520px;
    background-color: #ebebeb;
}
.cd-dropdown-content .cd-secondary-dropdown>li>a {
    color: #3f8654;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 30px;
    height: 30px;
    pointer-events: none;
}
.cd-dropdown-content .cd-secondary-dropdown>li>a::after, .cd-dropdown-content .cd-secondary-dropdown>li>a::before {
    display: none;
}
.cd-dropdown-content .cd-secondary-dropdown.move-out>li>a {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
.cd-dropdown-content .cd-secondary-dropdown>li {
    margin: 20px 0;
    border-right-width: 1px;
    border-color: #ebebeb;
    border-style: solid;
    padding: 0 30px;
    height: 250px;
}
.cd-dropdown-content .cd-secondary-dropdown>li:nth-of-type(2n) {
    border-right-width: 0;
}
.cd-dropdown-content .cd-secondary-dropdown>li>ul {
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    position: relative;
    height: auto;
}
.cd-dropdown-content .cd-secondary-dropdown>li>ul>.go-back {
    display: none;
}
.cd-dropdown-content .cd-secondary-dropdown a {
    line-height: 25px;
    height: 25px;
    font-size: 1.3rem;
    padding-left: 0;
}
.no-touch .cd-dropdown-content .cd-secondary-dropdown a:hover {
    color: #3f8654;
}
.cd-dropdown-content .cd-secondary-dropdown ul {
    padding-bottom: 25px;
    overflow: hidden;
    height: auto;
}
.cd-dropdown-content .cd-secondary-dropdown .go-back a {
    padding-left: 20px;
    color: transparent;
}
.no-touch .cd-dropdown-content .cd-secondary-dropdown .go-back a:hover {
    color: transparent;
}
.cd-dropdown-content .cd-secondary-dropdown .go-back a::before, .cd-dropdown-content .cd-secondary-dropdown .go-back a::after {
    left: 0;
}
.cd-dropdown-content .cd-secondary-dropdown .see-all {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%}
.cd-dropdown-content .cd-dropdown-gallery {
    width: 600px;
    padding-bottom: 100px;
}
.cd-dropdown-content .cd-dropdown-gallery>li {
    width: 48%;
    float: left;
    margin-right: 4%}
.cd-dropdown-content .cd-dropdown-gallery>li:nth-of-type(2n) {
    margin-right: 0;
}
.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
    padding: 0;
    height: auto;
    line-height: normal;
    color: #3f8654;
    margin-bottom: 2em;
}
.cd-dropdown-content .cd-dropdown-gallery>li:nth-last-of-type(2) a, .cd-dropdown-content .cd-dropdown-gallery>li:last-of-type a {
    margin-bottom: 0;
}
.cd-dropdown-content .cd-dropdown-gallery img {
    position: static;
    height: auto;
    width: 100%;
    margin: 0 0 0.6em;
}
.cd-dropdown-content .cd-dropdown-icons {
    width: 600px;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
    height: 80px;
    line-height: 1.2;
    padding: 24px 0 0 85px;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item:hover {
    background: #ebebeb;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item h3 {
    color: #3f8654;
    font-weight: bold;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
    display: block;
    font-size: 1.2rem;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
    left: 25px;
}
.cd-dropdown-content>.has-children>ul {
    visibility: hidden;
}
.cd-dropdown-content>.has-children>ul.is-active {
    visibility: visible;
}
.cd-dropdown-content>.has-children>.cd-secondary-dropdown.is-active>li>ul {
    visibility: visible;
}
.cd-dropdown-content>.has-children>a.is-active {
    box-shadow: inset 2px 0 0 #3f8654;
    color: #3f8654;
}
.cd-dropdown-content>.has-children>a.is-active::before, .cd-dropdown-content>.has-children>a.is-active::after {
    background: #3f8654;
}
.open-to-left .cd-dropdown-content>.has-children>a.is-active {
    box-shadow: inset -2px 0 0 #3f8654;
}
}@-webkit-keyframes cd-fade-in {
    0% {
    opacity: 0;
    visibility: visible;
}
100% {
    opacity: 1;
    visibility: visible;
}
}@-moz-keyframes cd-fade-in {
    0% {
    opacity: 0;
    visibility: visible;
}
100% {
    opacity: 1;
    visibility: visible;
}
}@keyframes cd-fade-in {
    0% {
    opacity: 0;
    visibility: visible;
}
100% {
    opacity: 1;
    visibility: visible;
}
}@-webkit-keyframes cd-fade-out {
    0% {
    opacity: 1;
    visibility: visible;
}
100% {
    opacity: 0;
    visibility: visible;
}
}@-moz-keyframes cd-fade-out {
    0% {
    opacity: 1;
    visibility: visible;
}
100% {
    opacity: 0;
    visibility: visible;
}
}@keyframes cd-fade-out {
    0% {
    opacity: 1;
    visibility: visible;
}
100% {
    opacity: 0;
    visibility: visible;
}
}.cd-search input[type="search"] {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: #444;
    color: #ffffff;
    border: none;
    border-radius: 0;
}
.cd-search input[type="search"]::-webkit-input-placeholder {
    color: #ffffff;
}
.cd-search input[type="search"]::-moz-placeholder {
    color: #ffffff;
}
.cd-search input[type="search"]:-moz-placeholder {
    color: #ffffff;
}
.cd-search input[type="search"]:-ms-input-placeholder {
    color: #ffffff;
}
.cd-search input[type="search"]:focus {
    background: #ffffff;
    color: #333;
    outline: none;
}
.cd-search input[type="search"]:focus::-webkit-input-placeholder {
    color: rgba(17, 20, 51, 0.4);
}
.cd-search input[type="search"]:focus::-moz-placeholder {
    color: rgba(17, 20, 51, 0.4);
}
.cd-search input[type="search"]:focus:-moz-placeholder {
    color: rgba(17, 20, 51, 0.4);
}
.cd-search input[type="search"]:focus:-ms-input-placeholder {
    color: rgba(17, 20, 51, 0.4);
}
@media only screen and (min-width: 1024px) {
    .cd-search input[type="search"] {
    background-color: #ebebeb;
}
.cd-search input[type="search"]::-webkit-input-placeholder {
    color: #b3b3b3;
}
.cd-search input[type="search"]::-moz-placeholder {
    color: #b3b3b3;
}
.cd-search input[type="search"]:-moz-placeholder {
    color: #b3b3b3;
}
.cd-search input[type="search"]:-ms-input-placeholder {
    color: #b3b3b3;
}
}.has-children>a, .go-back a {
    position: relative;
}
.has-children>a::before, .has-children>a::after, .go-back a::before, .go-back a::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    background: #ccc;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.has-children>a::before, .go-back a::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.has-children>a::after, .go-back a::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
@media only screen and (min-width: 1024px) {
}
.has-children>a {
    padding-right: 40px;
}
.has-children>a::before, .has-children>a::after {
    right: 20px;
    -webkit-transform-origin: 9px 50%;
    -moz-transform-origin: 9px 50%;
    -ms-transform-origin: 9px 50%;
    -o-transform-origin: 9px 50%;
    transform-origin: 9px 50%}
@media only screen and (min-width: 1024px) {
}
.cd-dropdown-content .go-back a {
    padding-left: 40px;
}
.cd-dropdown-content .go-back a::before, .cd-dropdown-content .go-back a::after {
    left: 20px;
    -webkit-transform-origin: 1px 50%;
    -moz-transform-origin: 1px 50%;
    -ms-transform-origin: 1px 50%;
    -o-transform-origin: 1px 50%;
    transform-origin: 1px 50%}

.cd-main-content {
    background-color: #efefef;
    // min-height: calc(100vh - 100px);
    // padding: 2em 5%;
    // line-height: 2;
}
.no-js .cd-dropdown-wrapper:hover .cd-dropdown {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.no-js .cd-dropdown-wrapper .cd-close {
    display: none;
}













.sidecart2{
  display:block;
  width:300px;
  height:100%;
  
  background:url('../images/tm22/cart.jpg')  center center no-repeat;
  background-color:#333;

  position:fixed;
  z-index:1000;
  right:0px;
  top:0px;
  font-family: $fancy-font;
  
  span.shaddow{
    height:60px;
    // background:#fcfcfc;
    display:block;
  }
  a.cart-btn{
    display:block;
    width: calc(100% - 100px);
    background:#444;
    padding:20px;
    color:#fff;
    text-align:center;
    text-transform:uppercase;
    position:absolute;
    bottom:50px;
    &:hover{
      background:#666;
    }
  }

  h3{
    text-transform:uppercase;
    text-align:center;
    font-size:18px;
    color:#ccc;
  }
  h4{
    text-transform:uppercase;
    text-align:center;
    font-size:12px;
    color:#999999;
    padding-top:50px;
  }

}