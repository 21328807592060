pre {
	padding: 0 3px 2px;
	font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
	color: #333;
	display: block;
	padding: 9.5px;
	margin: 0 0 30px 0 !important;
	font-size: 13px;
	line-height: 20px;
	word-break: break-all;
	word-wrap: break-word;
	white-space: pre;
	white-space: pre-wrap;
	border: 0px solid #ccc!important;
	border: 0px solid rgba(0, 0, 0, 0.15)!important;
	-webkit-border-radius: 4px;
	        border-radius: 4px;
}
pre.prettyprint {
	margin-bottom: 20px;
	background:#f7f7f9;
}
.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}
.com {
	color: #999;
}
.lit {
	color: #195f91;
}
.pun, .opn, .clo {
	color: #93a1a1;
}
.fun {
	color: #dc322f;
}
.str, .atv {
	color: #D44950;
}
.kwd, .prettyprint .tag {
	color: #2F6F9F;
}
.typ, .atn, .dec, .var {
	/*color: teal;*/
	color: #4F9FCF;
}
.pln {
	color: #333;
}
.prettyprint {
	padding: 10px 15px;
	background-color: #FFF;
	border: 1px solid #e1e1e8;
}
.prettyprint.linenums {
	-webkit-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #fbfbfc;
	        box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #fbfbfc;
}
/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
	margin: 0 0 0 -12px;
}
ol.linenums li {
	padding-left: 12px;
	color: #bebec5;
	line-height: 20px;
	list-style: decimal;
}


/* Echo out a label for the example */
.bs-docs-example 
{
    position: relative;
    margin: 15px 0;
    padding: 39px 19px 14px;
    background-color: white;
    border: 1px solid #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
 
.bs-docs-example::after
{
    content: "Example";
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    background-color: whiteSmoke;
    // border: 1px solid #DDD;
    color: #9DA0A4;
    -webkit-border-radius: 4px 0 4px 0;
    -moz-border-radius: 4px 0 4px 0;
    border-radius: 4px 0 4px 0;
    // text-transform:uppercase;
}


.prettyprint + .bs-docs-example, .bs-docs-example + .prettyprint {
margin-top: -20px!important;
padding-top: 15px;
border-top-right-radius: 0;
border-top-left-radius: 0;
}


.prettyprint.linenums + div.zero-clipboard{
height:26px;
width:120px;
background:#f7f7f9;
float:right;
margin-top:-56px;
margin-bottom:56px;
text-align:center;
padding:5px 15px;
border-radius: 4px 0 4px 0;
border-top: 1px solid #eee;
border-left: 1px solid #eee;
color:#DDD;
font-size:10px;
text-transform:uppercase;
cursor:pointer;
@include transition(.2s);
	&:hover{
		background:#fff;
		color:#666;		
	}
}

.ex-toggle{
	display:block;
	background:#fcfcfc;
	border:1px solid #efefef;
	float:right;
	margin-top:0px;
	padding:5px 15px;
	text-transform:uppercase;
	font-size:10px;
	color:#999;
	@include transition(.2s);
	&:hover{
		background:$maincolor;
		border:1px solid $maincolor;
		color:#fff;	
		cursor:pointer;
	}
	&.active{
		background:$maincolor;
		border:1px solid $maincolor;
		color:#fff;	
		cursor:pointer;
	}
}

//zeroclipboard hover
.clip_button.zeroclipboard-is-hover {
	color:#222!important;
    background-color: #fff!important;
}
.clip_button.zeroclipboard-is-active {
	color:#222!important;
    background-color: #fff!important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) inset !important;
}