body.font-georgia{
	font-family:Georgia;
	.mainmenu{font-family:Georgia;}
}
.font-anonymous{font-family:'Anonymous Pro';}

#photography{
	overflow:hidden;
	width:100%;
	input{
		color:#999; background:transparent; border:0px; box-shadow:none; border-bottom:1px solid #666;
		&:focus{color:#dbb788;}

	}
	
	textarea{
		color:#999; background:transparent; border:0px; box-shadow:none; border-bottom:1px solid #666;
		&:focus{color:#dbb788;}
	}
	
}

.coverphotography{
  width:100%;
  position:absolute;
  top:0px;
  img{
    width:100%;
  }
}
.txt-size{
	font-size:24px;
	@media (min-width: 992px){ font-size:16px; }
	@media (min-width: 1400px){ font-size:20px; }
	@media (min-width: 1600px){ font-size:22px; }
	@media (min-width: 1800px){ font-size:24px; }
}
.pdd-size{
	padding-left:70px; padding-right:70px;
	@media (min-width: $screen-xs-min){ padding-left:70px; padding-right:70px;}
	@media (min-width: $screen-sm-min){ padding-left:70px; padding-right:70px;}
	@media (min-width: 992px){ padding-left:20px; padding-right:20px;}
	@media (min-width: 1400px){ padding-left:50px; padding-right:50px;}
	@media (min-width: 1600px){ padding-left:70px; padding-right:50px;}
	@media (min-width: 1800px){ padding-left:70px; padding-right:70px;}
}

.fixedtop{
	position:fixed;
	top:0px;
	padding:50px;	
}

.photocover1{background:url('../images/covers/photo01.jpg') center center; background-size:cover;}
.photocover2{background:url('../images/covers/photo02.jpg') center center; background-size:cover;}
.photocover3{background:url('../images/covers/photo03.jpg') center center; background-size:cover;}
.photocover4{background:url('../images/covers/photo04.jpg') center center; background-size:cover;}
.photocover5{background:url('../images/covers/photo05.jpg') center center; background-size:cover;}

.photo-hover{
	@include transition(.2s);
	&:hover{
		-ms-transform: scale(0.9,0.9); 
		-webkit-transform: scale(0.9,0.9); 
		transform: scale(0.9,0.9);
	}
}

.photographer-name{
	width:100%;
	position:absolute; 
	bottom:0;
	text-align:center;
	font-family:'Anonymous Pro';
	padding-bottom:60px;
	p:first-child{
		font-size:100px;
	}
	p:nth-child(2){
		font-size:24px;
		color:#dbb787;
		text-transform:uppercase;
		letter-spacing:20px;
	}
}
.pcolor{color:#dbb788;}
.photobg01{background:#dbb788;}
.photobg02{background:#bf847a;}

.bg-fb{background:#4d6c7e;}
.bg-tw{background:#84bfc6;}
.bg-gp{background:#bf847a;}

.progress-about.slim2 {
	height:3px;
	background-color: #666!important;
	.progress-bar{
		background-color:#d7b385;
	}
}




// CREATE LIGHTBOX
.createlightbox{
	background:#000;
	background:rgba(black,0.8);
	width:100%;
	height:920px;
	display:none;
	position:fixed;
	z-index:10000;
	opacity:0;
}

img.max-wh-90{   max-width:90%; max-height:90%;  }

