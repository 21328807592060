footer,
.footer {
    .logo {
        display: block;
        float: none;
        margin-left: 0;
        position: relative;

    }

    input.newsletter {
        width: 100%;

    }
}

@media(max-width:778px) {
    .subfooter {

        ul,
        .right {
            width: fit-content;
            margin: auto;
        }


        .right {
            float: none;

        }

        .copyright {
            p {
                margin: 15px 0;
            }
        }
    }
}