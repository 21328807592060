#products {
    >.product {
        &:nth-child(even) {
            .row {
                display: flex;
                flex-wrap: wrap;
                flex-flow: row-reverse;

                @media (max-width:1024px) {
                    & {
                        flex-flow: column;
                    }
                }
            }
        }

        figure.effect-sadie2 {
            h2 {
                top: 80%;
            }

            figcaption::before {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 65%);
                opacity: 1;

            }
        }
    }
}