.leftsidemenu{
	position:relative;
	height:600px;
	@media (min-width: $screen-md-min){
		position:fixed;
		height:100%;
	}

	ul.menu{
		li{
			font-size:24px;
			font-family:$titlefont;
			line-height:35px;
			text-transform:uppercase;
			@include transition(.5s);
			a{  
				color:#999;
				&:hover,&:focus{
					text-decoration:none;
					p.rotate{
					  width:20px;
					  height:20px;
					  position:relative;
					  float:right;
					  right:30px;
					  bottom:-5px;
					  margin-left:-50px;
					  padding-left:4px;
					  line-height:20x;
					  @include rotate(45deg);
					}
					p.arrow{
						width: 0; 
						height: 0; 
						border-top: 3px solid transparent;
						border-bottom: 3px solid transparent;
						border-left: 3px solid #222;
						position:relative;
						float:right;
						right:37px;
						bottom:-15px;
					}
				}
				p.rotate{
				  width:20px;
				  height:20px;
				  position:relative;
				  float:right;
				  right:30px;
				  bottom:-5px;
				  margin-left:-50px;
				  padding-left:4px;
				  line-height:20px;
				  @include rotate(0deg);
				  @include transition(.2s);
				}
				p.arrow{
					width: 0; 
					height: 0; 
					border-top: 3px solid transparent;
					border-bottom: 3px solid transparent;
					border-left: 3px solid #ccc;
					position:relative;
					float:right;
					right:37px;
					bottom:-15px;
				}
			}
			a.active{color:#222;}
			&:hover{
				a{color:#222;}
			}
		}
	}

	/*Side dropdownmenu*/
	ul.menu li ul.side li a{ 
		display:block; 
		font-family:'Open Sans'; 
		font-size:14px; 
		line-height:15px;
		color:#999;
		text-transform:capitalize; 
		background:#fff;
		padding:10px 100px; 
		white-space: 
		nowrap;
		&:hover{
			color:#222;
		}
	}
	ul.menu li ul.side{
		display:none;
		position:relative;
		left: auto;
		margin-top:0px;
		@include transition(.5s);
		overflow:hidden;
		text-align:center;
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
	}
	ul.menu li:hover ul.side{
		display:block;
		position:relative;
	}
	ul.menu li:hover ul.side li a{ color:#999; &:hover{color:#222;}}

	@media (min-width: $screen-md-min){
		ul.menu li ul.side li a{ 
			display:block; 
			font-family:'Open Sans'; 
			font-size:14px; 
			line-height:15px;
			color:#999;
			text-transform:capitalize; 
			background:#fff;
			padding:10px 100px; 
			white-space: 
			nowrap;
			&:hover{
				color:#222;
			}
		}
		ul.menu li ul.side{
			display:none;
			position:absolute;
			left: calc(100% - 10px);
			margin-top:-35px;
			@include transition(.5s);
			overflow:hidden;
			text-align:left;
			box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
		}
		ul.menu li:hover ul.side li a{ color:#999; &:hover{color:#222;}}
		ul.menu li:hover ul.side{
			display:block;
			position:absolute;
		}
	}


	/*Toggle dropdownmenu*/
	ul.menu li ul.toggle li a{ 
		display:block; 
		font-family:'Open Sans'; 
		font-size:14px; 
		line-height:14px;
		color:#999;
		text-transform:capitalize; 
		background:#fff;
		padding:7px 20px; 
		white-space: 
		nowrap;
		&:hover{
			color:#222;
		}
	}
	ul.menu li ul.toggle{
		position:relative;
		@include transition(.5s);
		overflow:hidden;
		margin-bottom: 0px!important;
	}

	ul.social{
		width:87px;
		position:absolute;
		bottom:80px;
		left:0px;
		right:0px;
		margin-left:auto;
		margin-right:auto;

		li{
			float:left;
			margin-left:1.5px;
			margin-right:1.5px;
			margin-top:3px;
			a{	
				width:40px;
				height:40px;
				display:block;
				padding:9px;
				border:1px solid #efefef;
				color:#999;
				border-radius:50px;
				&:hover{
					color:#333;
				}
			}
		}
	}

	.copyr{
		width:100px;
		position:absolute;
		bottom:30px;
		left:0px;
		right:0px;
		margin-left:auto;
		margin-right:auto;
		text-align:center;
		color:#ccc;
		a{
			color:#ccc;
			&:hover{color:#666;}
		}
	}
}
